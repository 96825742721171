import { TableColumns, TableData } from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import { activityReportResponseType,activityReportResultDetailsType,activityReportResultType,activityReportTableDetailsType, packageDetails } from "./activityreport-dao.type";

class activityReportResponse {
    response : activityReportResponseType
    constructor(activityReportResult : activityReportResultType){
        const userActivityDetails  = this.generateActivityReportTableData(activityReportResult)
        if(userActivityDetails.length>0){
            this.response = {
                activityReportTableHeaders : this.generateActivityReportTableHeaders(userActivityDetails),
                activityReportData : this.generateActivityReportTableDetails(userActivityDetails),
                activityReportDataCount : userActivityDetails.length,
                error : undefined
            }
        }
        else{
            this.response = {
                activityReportTableHeaders : [],
                activityReportData : [],
                activityReportDataCount : userActivityDetails.length,
                error : "No results were returned for your query."
            }
        }
    }

    generateActivityReportTableData(data : activityReportResultType) : Array<activityReportTableDetailsType> {
        let userActivityTableDetails : Array<activityReportTableDetailsType> = [];
        (data as activityReportResultType).forEach((userActivityData: activityReportResultDetailsType) => {
            const packageData: Array<packageDetails> = userActivityData.packagesList;
            (packageData as Array<packageDetails>).forEach((packageDetail: packageDetails) => {
                userActivityTableDetails.push({
                    timestamp : {
                        label : "TIMESTAMP",
                        value : this.formatTimeStamp(userActivityData.timestamp)
                    },
                    user : {
                        label : "USER",
                        value : userActivityData.userAlias
                    },
                    location : {
                        label : "LOCATION",
                        value : (userActivityData.location === undefined) ? "" : userActivityData.location
                    },
                    package : {
                        label : "PACKAGE",
                        value : packageDetail.packageId
                    },
                    type : {
                        label : "PACKAGE TYPE",
                        value : packageDetail.type
                    },
                    isAmazon : {
                        label : "IS AMAZON? ",
                        value : packageDetail.isAmazonContainer,
                        toolTipText : 'Considered EagleEye search result "No TCDA Id found" package as non Amazon.'
                    }
                });
            });

        });
        return userActivityTableDetails;
    }
    
    generateActivityReportTableHeaders(data : Array<activityReportTableDetailsType>) : TableColumns[] {
        let tableHeaders : Array<TableColumns> = [];
        if(data.length>0){
            Object.entries(data[0]).map(([key, cellInfo]) => {
                tableHeaders.push({
                    visibleText: cellInfo.label,
                    isColumnActive: true,
                    columnKey: key,
                    toolTipText: cellInfo.toolTipText?cellInfo.toolTipText:""
                });
            });
        }
        return tableHeaders
    }

    generateActivityReportTableDetails(data : Array<activityReportTableDetailsType>): TableData[][]{
        const tableData: Array<Array<TableData>> =[];
        data.map((details)=>{
            let tableRecord: Array<TableData> = []
            Object.entries(details).map(([keys, cellInfo]) => {
                tableRecord.push({
                        value: cellInfo.value,
                        type: cellInfo.type ?? 'text', 
                        width: 300
                    })
            })
            tableData.push(tableRecord)
        })
        return tableData
    }

    formatTimeStamp(timestamp : string){
        const inputDate = timestamp.split('.')[0] + ' UTC'
        return inputDate
    }
}
export default activityReportResponse