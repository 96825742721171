import { AxiosRequestConfig } from "axios";
import { getToken } from "src/auth/cognito-auth";
import getMidwayToken from "src/common/axios/authentication/getMidwayToken";

const MIDWAY_TOKEN = "MidwayToken";

export default async function AxiosRequest(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  let midwayToken = sessionStorage.getItem(MIDWAY_TOKEN);
  if (midwayToken == null) {
    // TODO Add check for token expiry
    midwayToken = await getMidwayToken();
    sessionStorage.setItem(MIDWAY_TOKEN, midwayToken);
  }

  return {
    ...config,
    headers: {
      Authorization: getToken()
    },
  };
}
