import { ToolLinkType } from "src/components/helpers/tool-links-renderer-util/tool-links-renderer-util.type"
import {TableColumns, TableData} from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import {DetailsUtilType} from "src/components/helpers/details-renderer-util/details-renderer-util.type";
import { ToolLinksDataType } from "src/common/dao/preslam/preslam-dao.type";

export const HankDetailsInitialState: TableData[][] = []

export const HankHeadersInitialState: TableColumns[] = []

export const overviewDetailsInitialState: DetailsUtilType ={
	className: "",
	sectionHeader: "",
	details: {"": {label: '', value: ''}}
}

export const toolLinksInitialState: ToolLinksDataType = {
    atrops_order_details: [],
    atrops_troubleshooter:  [],
    atrops_logs:  [],
    f2p_visualiser:  [],
    grass:  [],
    ffrm:  [],
    csi:  [],
    hank:  [],
    box:  [],
    promise:  [],
    hero:  [],
    tt_link:  [],
	slamops: []
}

export const preSlamToolLinksTemplates : Record<string,ToolLinkType> = {
    'atrops_order_details': {
		integUrl: '',
		prodUrl: 'https://atrops-web-{0}.amazon.com/shipment/info?lookupid={1}',
		label: 'Atrops Order Details',
		args: ['realm', 'shipmentId']
	},
    'atrops_troubleshooter': {
		integUrl: '',
		prodUrl: 'https://atrops-web-{0}.amazon.com/shipment/troubleshoot?lookupid={1}',
		label: 'Atrops Troubleshooter',
		args: ['realm', 'shipmentId']
	},
    'atrops_logs': {
		integUrl: '',
		prodUrl: 'https://atrops-web-{0}.amazon.com/shipment/logs?lookupid={1}',
		label: 'AtropsLogs',
		args: ['realm', 'shipmentId']
	},
    'f2p_visualiser': {
		integUrl: '',
		prodUrl: 'https://f2p-visualizer.corp.amazon.com/plan/{0}',
		label: 'F2P Visualizer',
		args: ['orderId']
	},
    'grass': {
		integUrl: '',
		prodUrl: 'https://grass-{0}.aka.amazon.com/?prod=on&realm=DETECT&id={1}',
		label: 'Grass UI',
		args: ['realm', 'orderId']
	},
    'ffrm': {
		integUrl: '',
		prodUrl: 'https://ffrm-troubleshooting-{0}.amazon.com/events?shipmentId={1}',
		label: 'FFRM',
		args: ['realm', 'shipmentId']
	},
    'csi': {
		integUrl: '',
		prodUrl: 'https://csi.amazon.com/view?view=simple_product_data_view&item_id=&marketplace_id=&customer_id=&merchant_id=&sku=&fn_sku=&gcid=&fulfillment_channel_code=&listing_type=purchasable&submission_id=&order_id={0}&external_id=&search_string=&realm=USAmazon&stage=prod&domain_id=&keyword=&submit=Show',
		label: 'CSI',
		args: ['orderId']
	},
    'hank': {
		integUrl: '',
		prodUrl: 'https://hankui-{0}.aka.amazon.com/?id={1}&type=Order',
		label: 'Hank UI',
		args: ['realm', 'orderId']
	},
    'box': {
		integUrl: '',
		prodUrl: 'https://box-web-{0}.amazon.com/BoxRecBrowser/getBoxRecommendation?marketplaceId=&marketplace-selector=&warehouseId={1}&asin=&giftOption=NoGift&siocOverride=NONE&_packingBrands=on&_packingBrands=on&_requiresConcealment=on&postalCode=',
		label: 'Box UI',
		args: ['realm', 'fc']
	},
    'promise': {
		integUrl: '',
		prodUrl: 'https://promise.amazon.com/dcsviewer/search.jsp?searchValue={0}&realm={1}&domain=prod&Search=',
		label: 'Promise',
		args: ['orderId', 'fc']
	},
    'hero': {
		integUrl: '',
		prodUrl: 'https://hero.{0}.picking.aft.a2z.com/fc/{1}/pick-events/customer-shipment/{2}',
		label: 'HERO',
		args: ['realm', 'fc', 'shipmentId']
	},

    'tt_link': {
		integUrl: '',
		prodUrl: 'https://t.corp.amazon.com/issues?q=full_text%3A%28{0}%29',
		label: 'Ticket Search',
		args: ['shipmentId']
	},
	'slamops':{
		integUrl: '',
		prodUrl: 'https://slamops-{0}.amazon.com/logs/query?scannableId={$1}',
		label: 'SlamOps',
		args: ['region,scannable']
	}
}

export const preSlamInitialState = {
	hankDetails: HankDetailsInitialState,
	hankOverviewDetails: overviewDetailsInitialState,
	toolLinksData:toolLinksInitialState,
	hankTableHeaders: HankHeadersInitialState,
	error: undefined
}
