import { activityReportRequestType } from "./activityreport-dao.type";
import { activityReportInputFieldsType, searchType } from "../../../pages/visibility-page/components/activityreport/activityreport.component.type"
import { sanitizeInput } from "src/utils/input-santize-util";

class activityReportRequest {
    request : activityReportRequestType
    constructor(searchType: searchType,searchId: string, fromTimeStamp: number, toTimeStamp: number){
        let user:string = "";
        let location : string = "";
        searchId =sanitizeInput(searchId)
        if(searchType === "userId")
            user = searchId.toLowerCase()
        else
            location = searchId.toUpperCase()     
        this.request = {
            userId : user,
            location : location,
            startTime : fromTimeStamp,
            endTime : toTimeStamp
        }
    }
    
}

export default activityReportRequest