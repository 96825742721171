import {InputFieldDataType} from "src/components/helpers/input-field-util/input-field-util.type";

export const preslamHeaderData = {
    pageTitle: "EagleEye Visibility - Preslam ",
    myKey: "Preslam-page-header"
}

export const ShipmentIdData: InputFieldDataType = {
    label: "Shipment Id",
    type: "text",
    placeholder: "Enter shipment id",
    errorMessage: "Please provide shipment id!",
}

export const FCData: InputFieldDataType = {
    label: "FC",
    type: "text",
    placeholder: "Enter FC",
    errorMessage: "FC is an optional field",
}