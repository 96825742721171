import React, { useState, useEffect, memo } from "react"
import { useNavigate } from "react-router-dom"
import Tab, { TabGroup } from "@amzn/meridian/tab"
import Column from "@amzn/meridian/column"
import { NavTabComponentProps} from "src/components/helpers/navtab-util/navTab.util.type";
import { getLangAndRegionQueryParam } from "src/utils/utils"

export const NavTabComponent = ({ tab , NavTabItems }: NavTabComponentProps) => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("");
  useEffect(() => {
    setCurrentTab(tab);
  })

  const handleTabChange = (value: string) => {
    setCurrentTab(value);
    let foundItem = NavTabItems.find((item) => item.label === value);
    if (foundItem) {
      navigate(foundItem.path + getLangAndRegionQueryParam(window.location.search))
    }
  }

  return (
    <Column className={"navTab"}>
      <TabGroup value={currentTab} onChange={(value) => { handleTabChange(value) }} aria-labelledby="tablist-heading" type="classic">
        {NavTabItems.map((item) => {
          return (
            <Tab
              value={item.label}
              id={item.label} >
              {item.label}
            </Tab>
          )
        })
        }
      </TabGroup>
    </Column>
  )
}
