export const footerLinks = {
    Items:[
        {
            visibleText:"Report Issues",
            value:"https://sim.amazon.com/issues/create?assignedFolder=bc9d7672-7bd4-40e3-a72f-44aa99fda1d7"
        },     
        {
            visibleText:"About Us",
            value:"https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/"
        },
        {
            visibleText:"Subscribe Here For Notifications",
            value:"https://email-list.corp.amazon.com/email-list/expand-list/eagleeye-announcements"
        }
    ]
}