import { ToolLinkType } from "src/components/helpers/tool-links-renderer-util/tool-links-renderer-util.type"
import {DetailsUtilType} from "src/components/helpers/details-renderer-util/details-renderer-util.type";

export const afnToolLinksTemplates: Record<string, ToolLinkType> = {
	'grass-ui': {
		integUrl: 'https://grass.integ.amazon.com/realm=DETECT&id={1}',
		prodUrl: 'https://grass-{0}.aka.amazon.com/prod=on&realm=DETECT&id={1}',
		label: 'Grass',
		args: ['realm', 'searchId']
	},
	'f2p-visualizer': {
		integUrl: '',
		prodUrl: 'https://f2p-visualizer.corp.amazon.com/plan/{0}',
		label: 'F2P Visualizer',
		args: ['searchId']
	}, 
	'oblt': {
		integUrl: 'https://trans-app-{0}.integ.amazon.com/outboundLookup/packageLookup.cgi?data_type=TRACKING_ID;id={1}',
		prodUrl: 'https://trans-app-prod-{0}.amazon.com/outboundLookup/packageLookup.cgi?data_type=TRACKING_ID;id={1}',
		label: 'OBLT',
		args: ['region', 'trackingId']
	},
	'oblt-events': {
		integUrl: 'https://trans-app-{0}.integ.amazon.com/outboundLookup/trackingEventLookup.cgi?id={1}',
		prodUrl: 'https://trans-app-prod-{0}.amazon.com/outboundLookup/trackingEventLookup.cgi?id={1}',
		label: 'OBLT-events',
		args: ['region', 'trackingId']
	},
	'herd': {
		integUrl: 'https://herdui.integ.amazon.com/ui/work.jsp?clientId={1}&objectId={2}',
		prodUrl: 'https://herdui-{0}.amazon.com/ui/work.jsp?clientId={1}&objectId={2}',
		label: 'Hank UI',
		args: ['realm', 'herdClientId', 'searchId']
	},
	'ticket': {
		integUrl: 'https://t.corp.amazon.com/{0}',
		prodUrl: 'https://t.corp.amazon.com/{0}',
		label: 'Ticket',
		args: ['ticketId']
	},
	'eisws': {
		integUrl: 'https://eisws-console{0}.amazon.com/console/search?objects={1}&inDays=true&searchDateMode=byTimeRange&lookBack=true&timespan=120',
		prodUrl: 'https://eisws-console{0}.amazon.com/console/search?objects={1}&inDays=true&searchDateMode=byTimeRange&lookBack=true&timespan=120',
		label: 'EISWS',
		args: ['ediRegion', 'searchId']
	}, // TODO: hero and hitch may need a special case for JP region
	'hitch': {
		integUrl: '',
		prodUrl: 'https://{0}.hitch.aft.amazon.dev/warehouse/{1}/shipment/{2}',
		label: 'Hitch',
		args: ['realm', 'warehouseId', 'shipmentId']
	},
	'hero': {
		integUrl: '',
		prodUrl: 'https://hero.{0}.picking.aft.a2z.com/fc/{1}/pick-events/customer-shipment/{1}',
		label: 'Hero',
		args: ['realm', 'warehouseId', 'shipmentId']
	},
	'atrops': {
		integUrl: '',
		prodUrl: 'https://atrops-web-{0}.amazon.com/shipment/logs?lookupid={1}',
		label: 'Atrops',
		args: ['realm', 'shipmentId']
	},
	'ffrm': {
		integUrl: '',
		prodUrl: 'https://ffrm-troubleshooting-{0}.amazon.com/events?shipmentId={1}',
		label: 'FFRM',
		args: ['realm', 'shipmentId']
	},
	'tcda': {
		integUrl: '',
		prodUrl: 'https://transportation-taxonomy-{0}.aka.amazon.com/loadContainer?containerId={1}&versionNumber=',
		label: 'TCDA',
		args: ['region', 'tcdaId']
	},
	'scc_comp':{
		integUrl: '',
		prodUrl: 'https://logistics.amazon.{0}/station/dashboard/search?shareableLink=detailPage%2F{1}',
		label: 'SCC(COMP)',
		args: ['topLevelDomain','trackingId']
	},
	'comp':{
		integUrl: '',
		prodUrl: 'https://www.amazonlogistics-{0}.com/comp/shipmentDetail?id={1}&shipmentType=Delivery',
		label: 'COMP',
		args: ['compCountry','compShipmentId']
	},
	'eagleeye':{
		integUrl: 'https://eagleeye-{0}-gamma.aka.amazon.com{1}',
		prodUrl: 'https://eagleeye-{0}.amazon.com{1}',
		label: 'EagleEye',
		args: ['region','page']
	},
	'manifest-visibility':{
		integUrl: "",
		prodUrl: "",
		label: 'Manifest Visibility',
		args: ['realm','shipmentId']
	},
	'promise-analyzer':{
		integUrl:'',
		prodUrl: 'https://{0}.promise-analyzer.promise.amazon.dev/promise/orderId/{1}',
		label: 'Promise Analyzer',
		args: ['realm','orderId']
	}
}

export const countryCodeToTopLevelDomainMapping = {
	'US': "com",
	'CA': "com",
	'MX': "com",
	'IN': "in"
}
export const BULKSEARCH_LIMIT = 1000
export const afnInputFieldConditions = {
	"scannable": {
		limit: 50,
		isNodeIdRequiredForLegFormat: false,
		isNodeIdRequiredForTableFormat: true,
	},
	"shipment": {
		limit: 50,
		isNodeIdRequiredForLegFormat: false,
		isNodeIdRequiredForTableFormat: true,
	} ,
	"transshipment": {
		limit: 50,
		isNodeIdRequiredForLegFormat: false,
		isNodeIdRequiredForTableFormat: true,
	},
	"container": {
		limit: 1,
		isNodeIdRequiredForLegFormat: true,
		isNodeIdRequiredForTableFormat: true,
	},
	"pkglegdetail": {
		limit: 50,
		isNodeIdRequiredForLegFormat: false,
		isNodeIdRequiredForTableFormat: false,
	},
	"trailer": {
		limit: 10,
		isNodeIdRequiredForLegFormat: true,
		isNodeIdRequiredForTableFormat: true,
	},
	"vrid": {
		limit: 10,
		isNodeIdRequiredForLegFormat: true,
		isNodeIdRequiredForTableFormat: true,
	},
	"resourceId": {
		limit: 1,
		isNodeIdRequiredForLegFormat: true,
		isNodeIdRequiredForTableFormat: true,
	}
}

export const PackageDetailsInitialState: DetailsUtilType ={
	className: "",
	sectionHeader: "",
	details: {"": {label: '', value: ''}}
}

export const esmmInitialState = {
	label: "",
	value: "",
	isVisible: false
}
export const enrichedTrackingIdInfoInitialState = {
	label: "",
	value: "",
	isVisible: false
}

export const afnToolLinksInitialState = {
	hitch: [],
	hero: [],
	atrops: [],
	oblt: [],
	"oblt-events": [],
	"f2p-visualizer": [],
	"grass-ui": [],
	ffrm: [],
	tcda: []
}

export const toolTipDisplay = {
	manifestDetails: "Data might be delayed upto 20 min from Departure Time."
}

export const BULK_SEARCH_USER_GUIDE = {
	title: 'Bulk Search Demo',
	descriptionText: `Bulk search feature allows to search up to 1000 IDs at once. Few pointers:`,
	pointers:['Search results do not display on the screen.','Search results can be downloaded in CSV format.'],
	videoText:'Please watch this demo video before proceeding',
	videoUrl: 'https://maxis-file-service-prod-dub.dub.proxy.amazon.com/issues/bc6a864a-da6f-4c4a-9f7c-fd725ade3641/attachments/f678117992f4f4a188524c9c43e1042227461bf8fdc98cd240b3f3eec123bcf6_ffbac6a0-1054-4c4a-8002-5d1b816db0b9',
	id: 'bs-user-guide',
	showFrequencyInSeconds: 24*60*60,
	successButtonText: "Continue",
	broadCastLink:"https://broadcast.amazon.com/videos/1129285"
  };

export const GET_ALL_ITEMS_USER_GUIDE = {
	title: 'Get All Items Demo',
	descriptionText: `Get All Items feature helps to retrieve item details and associated images for the searched packages in one click.`,
	pointers: [],
	videoText: 'Please watch this demo video',
	videoUrl: 'https://maxis-file-service-prod-dub.dub.proxy.amazon.com/issues/97ccc41c-3a3d-47d8-b588-1aed802559a2/attachments/36c876d158aab2d89479223bd4b322e7964f2738784f88be50c7a7fdba01f3a7_1a625a33-67dd-4a52-8639-7b5177858daa',
	id: 'id-user-guide',
	showFrequencyInSeconds: 24*60*60,
	successButtonText: "Continue",
	broadCastLink:"https://broadcast.amazon.com/videos/1252049"
};