import { katalLoggerInfo } from "src/logger";
import { latencyMetricsPublisher } from "src/utils/metricUtils";
import { getEECSApiForRegion } from "src/utils/utils";
import { axiosPost } from "../axios/axios-api-wrapper.hook";
import { xorRedrivePayloadType } from "src/common/dao/xor-redrive/xor-redrive-dao-type";
import xorRedriveRequest from "src/common/dao/xor-redrive/xor-redrive-request-dao";

 
export const xorRedriveApi = async (payload: xorRedrivePayloadType) => {
    const xorRedriveParams = new xorRedriveRequest(payload).request
    const requestStartTime = Date.now();
    katalLoggerInfo(window.location.href, "XorRedriveApi => Request", xorRedriveParams);
    return axiosPost(
        getEECSApiForRegion(),
        `xor_redrive`,
		xorRedriveParams
    ).then((response: any) => {
        const requestTime = Date.now() - requestStartTime
        katalLoggerInfo(window.location.href, "XorRedriveApi => Response", response)
        latencyMetricsPublisher.publishTimerMonitor(`xor-redrive`, requestTime)
        return response;
    });
}