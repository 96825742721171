import React from "react";
import {
    AfnErrorResultsComponentPropsType,
} from "src/pages/visibility-page/components/afn/afn.component.type";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";
import Icon from "@amzn/meridian/icon"
import infoTokens from "@amzn/meridian-tokens/base/icon/info"
import Row from "@amzn/meridian/row"
import "../afn.component.scss"
import { getDataForRegion as t} from "src/common/constants/translations.const";

export const AfnErrorResultsComponent = (errorResults: AfnErrorResultsComponentPropsType) => {
    return (
        <React.Fragment>
            <div style={{ "marginTop": "2%", "marginBottom": "1%" }} className={"package-search-results"}>
                {errorResults?.errorResults?.errorMessage === "check Other Region" ?
                    <Box type="outline" spacingInset={"200"} backgroundColor="#f9fad2;" >
                        <Row spacing="300" alignmentHorizontal="center">
                            <Icon tokens={infoTokens} />

                            <Text>
                                {t('regionErrorText')}
                            </Text>
                        </Row>
                    </Box>
                    : <></>
                }
                <Box type="outline" spacingInset={"200"} >
                    <Theme tokens={{ boxBackgroundColorOutline: '#feb96c' }}>
                        <Box type="outline" spacingInset="200">
                            <Text type="h100" alignment="center" className={"package-header-bg-color"}>
                                {t('inputErrorText')}
                            </Text>
                        </Box>
                    </Theme>
                    <Column spacing={"none"} >
                        {(errorResults?.errorResults != undefined && errorResults.errorResults.ids.map((id) => {
                            return (
                                <Text key={id}>
                                    {id}
                                </Text>
                            )
                        })
                        )}
                    </Column>
                </Box>
            </div>
        </React.Fragment>
    )
}