import { canUserUnlockBindle } from "src/common/api/inspection.api";
import { TableData, TableColumns } from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import { katalLoggerError } from "src/logger";

export const checkIsUserAuthorized = async (bindle: string) => {
	let isUserAuthorized = false
	await canUserUnlockBindle(bindle).then((response) => {
		isUserAuthorized = response["authorized"]
	})
	return isUserAuthorized
}

export const filterPropsByPrefix = <T extends Record<string, any>>(
	props: T,
	prefixes: string[]
): Partial<T> => {
	const filteredProps: Partial<T> = {};

	Object.keys(props).forEach((name) => {
		if (prefixes.some((prefix) => name.indexOf(prefix) === 0)) {
			filteredProps[name as keyof T] = props[name];
		}
	});

	return filteredProps;
}

export const addItemToLocalStorageList = (key: string, item: string) => {
	try {
		const currentList = JSON.parse(localStorage.getItem(key) || '[]');
		if (!currentList.includes(item)) {
			currentList.push(item);
			const serializedData = JSON.stringify(currentList);
			localStorage.setItem(key, serializedData);
		}
	} catch (error) {
		katalLoggerError(window.location.href,
			`An error occurred while updating local storage list for key :${key} , value : ${item}`, error);
		// If the error is related to storage quota limit exceeded, 
		// delete keys related for table column preferences for rest of the  tables
		if (error instanceof DOMException && error.name === 'QuotaExceededError') {

			Object.keys(localStorage).forEach((storageKey) => {
				if (storageKey.startsWith("col-filter-") && storageKey != key) {
					localStorage.removeItem(storageKey);
				}
			});
		}
	}
};

export const getLocalStorageList = (key: string) => {
	const storedList = localStorage.getItem(key);

	return storedList ? JSON.parse(storedList) : [];
}

export const removeItemFromLocalStorageList = (key: string, item: string) => {
	const currentList = JSON.parse(localStorage.getItem(key) || '[]');
	const updatedList = currentList.filter((existingItem: string) => existingItem !== item);

	localStorage.setItem(key, JSON.stringify(updatedList));
}

export const getFilterKey = (searchParams?: URLSearchParams ): string => {
	searchParams = searchParams || new URLSearchParams(window.location.search);
	let path = typeof window !== 'undefined' && window.location?.pathname?.split('/').pop()?.trim() || '';
	const format = (searchParams?.get("format") || '').trim();
	const shipmentType = (searchParams?.get("shipmentType") || '').trim();
	const searchType = (searchParams?.get("searchType") || 'searchType').trim();
	path = path === 'search' ? '' : path;

	return `col-filter-${path}-${format}-${shipmentType}-${searchType}`;
};
export const capitalizeFirstChar = (input: string): string => input.charAt(0).toUpperCase() + input.slice(1);
export const getColumnsFilteredData = (data: TableData[][], tableCols: TableColumns[], filteredColumnNamesList: string[]): any => {
	let indexesToFIlter: number[] = [];
	tableCols.forEach((val, ind) => {
		filteredColumnNamesList.includes(val.visibleText) && indexesToFIlter.push(ind)
	})
	return data.map(row => row.filter((val, ind) => !indexesToFIlter.includes(ind)))
}
export const getUrlParams = () => new URLSearchParams(window.location.search);