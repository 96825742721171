import { TableColumns, TableData } from "src/components/helpers/table-renderer-util/table-renderer-util.type"
import { itemDetailsResultType, getItemDetailsResponseType, itemDetailsTableType, bulkItemDetailsResponseType } from "./afn-dao.type"
import { getValidRegion } from "src/utils/utils"

export class ItemDetailsResponse {
    response: Array<getItemDetailsResponseType> = []
    constructor(itemDetailsResponse: itemDetailsResultType) {
        Object.entries(itemDetailsResponse).forEach(([scannable_id, item_details]) => {
            if (Array.isArray(item_details)) {
                item_details.forEach((item) => {
                    let item_data = `${item.asin},${item.quantity},${item.title}`
                    let result: getItemDetailsResponseType = { itemDetails: item_data, imageURL: item.imageURL };

                    this.response.push(result)
                })
            }
        })
    }
}


export class BulkItemDetailsResponse {
    response: bulkItemDetailsResponseType
    constructor(itemDetailsResult: itemDetailsResultType, idsList: Record<string, string>) {
        const itemDetailsTableData = this.generateItemDetailsDataForTable(itemDetailsResult, idsList)
        if (itemDetailsTableData.length) {
            this.response = {
                bulkItemDetailsTableHeaders: this.generateItemDetailsTableHeaders(itemDetailsTableData),
                bulkItemDetailsData: this.generateItemDetailsTableData(itemDetailsTableData),
                error: undefined
            }
        }
        else {
            this.response = {
                bulkItemDetailsTableHeaders: [],
                bulkItemDetailsData: [],
                error: "Empty result returned for the Request"
            }
        }

    }

    generateItemDetailsDataForTable(data: itemDetailsResultType, idsList: Record<string, string>) {
        let itemDetailsTableData: Array<itemDetailsTableType> = []
        let region = getValidRegion();
        Object.entries(data).forEach(([scannable_id, item_details]) => {
            let firstItem = true
            typeof item_details != "string" && item_details.forEach((item) => {
                itemDetailsTableData.push({
                    encrypted_shipment_id: {
                        label: "SHIPMENT_ID",
                        value: scannable_id,
                        width: "15%",
                        rowSpan: firstItem ? item_details.length : 0
                    },
                    tracking_id: {
                        label: "TRACKING_ID",
                        value: idsList[scannable_id],
                        width: "15%",
                        rowSpan: firstItem ? item_details.length : 0
                    },
                    asin: {
                        label: "ASIN",
                        value: item.asin ?? "",
                        width: "10%",

                    },
                    quantity: {
                        label: "QUANTITY",
                        value: item.quantity ?? "",
                        width: "10%"

                    },
                    title: {
                        label: "DESCRIPTION",
                        value: item.title ?? "",
                        width: "30%"

                    },
                    imageURL: (region === 'NA' || region === 'EU') ? {
                        label: "IMAGE",
                        value: item.imageURL ?? null,
                        type: 'image',
                        width: "35%"
                    } : null
                })
                firstItem = false
            })
        })
        return itemDetailsTableData
    }

    generateItemDetailsTableHeaders(data: Array<itemDetailsTableType>): TableColumns[] {
        let tableHeaders: Array<TableColumns> = []
        if (data.length) {
            Object.entries(data[0]).map(([key, cellInfo]) => {
                if (cellInfo) {
                    tableHeaders.push({
                        visibleText: cellInfo.label,
                        isColumnActive: true,
                        columnKey: key,
                        width: cellInfo.width
                    })
                }
            })

        }
        return tableHeaders
    }

    generateItemDetailsTableData(data: Array<itemDetailsTableType>): TableData[][] {
        const tableData: Array<Array<TableData>> = [];
        data.map((details) => {
            let tableRecord: Array<TableData> = []
            Object.entries(details).map(([keys, cellInfo]) => {
                if (cellInfo) {
                    tableRecord.push({
                        value: cellInfo.value,
                        type: cellInfo.type ?? 'text',
                        width: cellInfo.width,
                        rowSpan: cellInfo.rowSpan ?? 1
                    })
                }
            })
            tableData.push(tableRecord)
        })
        return tableData
    }
}