import {axiosPost} from "src/common/axios/axios-api-wrapper.hook";
import ofisRequest from "../dao/ofis/ofis-request.dao";
import { ofisPayloadType } from "../dao/ofis/ofis-dao.type";
import ofisResponse from "../dao/ofis/ofis-response.dao";
import { latencyMetricsPublisher } from "src/utils/metricUtils";
import { katalLoggerInfo } from "src/logger";
import {getEECSApiForRegion} from "src/utils/utils";

// In TOV there used to be two route in Ofis page /tmppCall and /ofisCall which ultimately calls GetGMPFailures in EECS.
// Here writing the logic in such a way that it handles ofis and tmpp calls by the same flow
export const ofisVisibilityApi = async (payload: ofisPayloadType) => {
	const ofisRequestPayload = new ofisRequest(payload).request
    const requestStartTime = Date.now()
    katalLoggerInfo(window.location.href, "ofisVisibilityApi => Request", ofisRequestPayload)
	return axiosPost(
        getEECSApiForRegion(),
		payload.view,
        ofisRequestPayload
    ).then((response: any) => {
        const requestTime = Date.now() - requestStartTime
        katalLoggerInfo(window.location.href, "ofisVisibilityApi => Response", response)
        latencyMetricsPublisher.publishTimerMonitor(`${payload.view}-call`, requestTime)
        return new ofisResponse(response,payload.view);
    });
}

// API flows needed to replicate from TOV's express-app.js file
// "/ofisCall" -> mutil.f15 -> eecs.eecs_getOFISResponse -> Coral.GetGMPFailures
// "/tmppCall" -> mutil.f17 -> eecs.eecs_searchGMPWorkflows -> Coral.GetGMPFailures