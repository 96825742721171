import React, {useState} from "react"
import { InspectionInputPropsType } from "./inspections.component.type"
import Box from "@amzn/meridian/box"
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
import Button from "@amzn/meridian/button"
import Text from "@amzn/meridian/text"
import Loader from "@amzn/meridian/loader"
import DatePicker from "@amzn/meridian/date-picker"
import { RadioButtonInlineFormatUtil } from "src/components/helpers/radio-button.util/radio-button.util"
import { inspectionInputTypeData, searchIdData } from "src/common/constants/inspections.const"
import { InputFieldUtil } from "src/components/helpers/input-field-util/input-field-util"




export const InspectionInputComponent = ({inspectionForm,setInspectionForm,inspectionMissingFields,setInspectionMissingFields,isSheetOpen,setIsSheetOpen,handleSubmit} : InspectionInputPropsType) => {

    const removeField = (field: string) => {
        setInspectionMissingFields(prevMissingFields => {
            const newMissingFields = { ...prevMissingFields };
            delete newMissingFields[field];
            return newMissingFields;
        })
    }
    const updateForm = (field: string, value: string | boolean) => {
        setInspectionForm(prevForm => ({ ...prevForm, [field]: value }));
        removeField(field)
    }
    
    const [placeholder,setPlaceHolder] = useState<string>("Please enter userId")

    const isValidDate = (day : string) =>{
        //Method to disable the dates which are older than one month
        const today = new Date()
        const previousMonth = new Date(today)
        previousMonth.setDate(today.getDate() - 31)       
        return day<previousMonth.toISOString().split('T')[0] || day>today.toISOString().split('T')[0]
    }

    return(
        <Column alignmentHorizontal="center" spacing="none">
            <Box className="inspection-input-container" width="60%" type="outline" spacingInset="400">
            <div className="inspection-search-component">
                <Column spacing="300">
                <Row alignmentHorizontal="center" widths={["grid-6", "grid-1","grid-3"]} spacing="100"  wrap="down">
                    <InputFieldUtil inputFieldData={searchIdData}
                            value={inspectionForm.searchId}
                            setValue={(value: string) => {
                                updateForm("searchId", value)
                            }}
                            onBlur={() => {}}
                            mask={""}
                            error={inspectionMissingFields.hasOwnProperty("searchId")}
                            errorMessage={inspectionMissingFields["searchId"] ?? undefined}
                            placeholder= {placeholder}
                            size="medium"
                    />
                    
                        <Text type="h100" alignment="right">From</Text>
                        <div>
                            <DatePicker 
                                value={inspectionForm.startDate}
                                onChange={(value: string) => {
                                    updateForm("startDate", value)
                                }}
                                error={inspectionMissingFields.hasOwnProperty("startDate")}
                                width="170px"
                                disabledDates={isValidDate}
                                errorMessage={inspectionMissingFields["startDate"] ?? undefined}/>
                        </div>
                        
                    </Row>
                <Row alignmentHorizontal="center" widths={["grid-6", "grid-1","grid-3"]} spacing="100"  wrap="down" >
                    <RadioButtonInlineFormatUtil radioButtonData={inspectionInputTypeData}
                                customisedStyle={{ display: "grid", gridGap: "16px", gridTemplateColumns: "repeat(3,auto)" }}
                                value={inspectionForm.searchType}
                                setValue={(value: string) => {
                                    updateForm("searchType", value)
                                    setPlaceHolder(`Please enter ${value}`)
                                    removeField("searchId")

                                }}
                    />
                            <Text type="h100" alignment="right">To</Text>
                            <div >
                            <DatePicker 
                                    value={inspectionForm.endDate}
                                    onChange={(value: string) => {
                                        updateForm("endDate", value)
                                    }}
                                    placeholder="YYYY-MM-DD"
                                    error={inspectionMissingFields.hasOwnProperty("endDate")}
                                    width="170px"
                                    disabledDates={isValidDate}
                                    errorMessage={inspectionMissingFields["endDate"] ?? undefined}/>
                                    </div>
                    </Row>
                    </Column>
                    <Row alignmentHorizontal={"center"} spacingInset="300">
                <Button disabled={inspectionForm.isLoading || Object.keys(inspectionMissingFields).length !== 0}
                            size="medium"
                            onClick={() => {
                                if (Object.keys(inspectionMissingFields).length !== 0) return;
                                setInspectionForm({ ...inspectionForm, showResultsInfo: false, isLoading: true })
                                handleSubmit(inspectionForm, inspectionMissingFields, setInspectionMissingFields)
                            }}>
                            {inspectionForm.isLoading ?
                                <> Loading <pre /> <Loader type="circular" size="small" /> </> :
                                "Search"
                            }
                        </Button>
                    </Row>
            </div>
        

    </Box>

        </Column>
    
    )

}