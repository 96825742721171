import {SideNavType} from "src/components/presentational/side-menu/side-menu.component.type";
import {commonDataType} from "src/context/CommonDataContext"


export const getSideBarMenuItems = (userAuthorizationInfo: commonDataType) => {
    const SideBarMenuItems: Array<SideNavType> = [
        {
            label: "Visibility",
            path: "/",
            hasAccess: true,
            ref:"#visibility",
            childItems: [{
                label: "Home",
                path: "/search",
                hasAccess: true,
                ref:"/"
            },{
                label: "MFN",
                path: "/mfn",
                hasAccess: true,
                ref:"/mfn"
            },{
                label: "OFIS",
                path: "/ofis",
                hasAccess: true,
                ref:"/ofis"
            },{
                label: "PreSlam",
                path: "/preslam",
                hasAccess: true,
                ref:"/preslam"
            },{
                label: "Inspections",
                path: "/inspections",
                hasAccess: userAuthorizationInfo.isUserAuthorizedForInspection,
                ref: "/inspections"
            },{
                label: "Activity-Report",
                path: "/activity-report",
                hasAccess: userAuthorizationInfo.isUserAuthorizedForActivityReport,
                ref: "/activity-report"
            }]
        },
        {
            label: "Solutions",
            path: "/solutions",
            hasAccess: true,
            ref:"",
            childItems: [{
                label: "XOR Redrive",
                path: "/xor-redrive",
                hasAccess: true,
                ref:"/xor-redrive"
            },{
                label: "Stuck Shipments",
                path: "/stuck-shipments",
                hasAccess: true,
                ref:"/stuck-shipments"
            }]
        }
    ]
    return SideBarMenuItems;
}
;
