import React, { useCallback, useEffect, useState } from "react";
import Table, { TableRow, TableCell, TableActionBar } from "@amzn/meridian/table"
import Button from "@amzn/meridian/button"
import SearchField from "@amzn/meridian/search-field"
import Icon from "@amzn/meridian/icon"
import filterTokens from "@amzn/meridian-tokens/base/icon/filter"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Theme from "@amzn/meridian/theme"
import copyTokens from "@amzn/meridian-tokens/base/icon/copy"
import infoTokens from "@amzn/meridian-tokens/base/icon/info"
import Pagination from "@amzn/meridian/pagination"
import Tooltip from "@amzn/meridian/tooltip"
import { StackingFilterValue, TableColumns, TableData, TableRendererUtilPropsType } from "./table-renderer-util.type";
import { HIDABLE_ATTR_PREFIX, MAX_STR_LENGTH_FOR_CELL, ROWS_PER_PAGE } from "./table-renderer.config";
import "./table-renderer-util.scss"
import { ModalUtil } from "src/components/helpers/modal-util/modal-util";
import { copyToClipboardUtil, extractIdFromUrl, extractLinkValue, extractObjectIdFromUrl, generateTableData, generateTableHeaders, getInnerHtmlText, hasAnchorTag } from "src/utils/dao-utils";
import { infoDataType } from "src/common/dao/mfn/mfn-dao.type";
import { ToasterUtil } from "src/components/helpers/toaster-util/toaster-util";
import { StackingFilterGraph } from "src/pages/visibility-page/components/afn/results/afn-stacking-filter.component";
import Link from "@amzn/meridian/link";
import Tag from "@amzn/meridian/tag";
import DOMPurify from 'dompurify';
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Toggle from "@amzn/meridian/toggle"
import downloadSmallIcon from "@amzn/meridian-tokens/base/icon/download-small"
import FilterIcon from "@amzn/meridian-tokens/base/icon/filter"
import { addItemToLocalStorageList, capitalizeFirstChar, getColumnsFilteredData, getFilterKey, getLocalStorageList, getUrlParams, removeItemFromLocalStorageList } from "src/utils/custom-utils";
import { AFN_PATH_NAMES, DEFAULT_TABLE_COLS } from "src/common/constants/utils.const";

interface TableState {
    searchText: string,
    currentPage: number
}

export const handleDownloadFileBtnClick = (tableHeaders: any[], tableData: any[][]) => {
    let header = tableHeaders.map(headerObj => headerObj.columnKey);
    header = header.map(item => {
        if (item.startsWith(HIDABLE_ATTR_PREFIX)) {
            return item.substring(HIDABLE_ATTR_PREFIX.length);
        }
        return item;
    });
    const body = tableData.map(dataObj => dataObj.map((line: { value: any }) => formatCell(line.value)));
    const csvContent = [
        header.join(','),
        ...body.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'EagleEye-SearchResults.csv');
    link.click();
};

function isNumber(value_to_check: any): boolean {
    return !isNaN(value_to_check);
}

/**
 * Encloses a value within single quotes to prevent Excel from displaying number in exponential notation.
 * @param value_to_enclose 
 * @returns 
 */
function encloseNumber(value_to_enclose: any) {
    if (value_to_enclose === '' || !isNumber(value_to_enclose) || value_to_enclose === null) {
        return value_to_enclose;
    }

    return value_to_enclose.toString().replace(/^/, "'").replace(/$/, "'");
}

function formatCell(cellValue: any): string {
    let strValue = cellValue;
    if (cellValue !== null) {
        strValue = cellValue.toString();
        // If the cell value contains a comma or a double-quote or \n, enclose it in double quotes
        if (hasAnchorTag(strValue)) {
            const linkValue = encloseNumber(extractLinkValue(strValue))
            const objectId = extractObjectIdFromUrl(linkValue, "objectId");
            const id = extractIdFromUrl(linkValue);
            strValue = objectId != null ? objectId : linkValue
            strValue = id != null ? id : strValue
            encloseNumber(strValue)
        }
        if (strValue.includes(',') || strValue.includes('"') || strValue.includes('\n')) {
            // console.log("Cell Value is includes  ", encloseNumber(`"${strValue.replace(/"/g, '""')}"`))
            strValue = (`"${strValue.replace(/"/g, '""')}"`);
        }
    }
    return encloseNumber(strValue);
}

// This component handles both vertical tables and horizontal header tables `isRowHeader` is the prop used for deciding which type of table we are to show
//
// onTablePageChange:
// using this onTablePageChange prop to know whether we are getting data for every pagination or not
// If undefined means we are getting all the data at once, so we need to slice it
// If onTablePageChange is defined it will have the logic to fetch the data for the page
// If onTablePageChange is defined it will have the logic to fetch the data for the page
export const TableRendererUtil = ({
    tableHeaders: tableHeaders_,
    tableData: tableData_,
    onFiltersClick,
    showActionBarComponents,
    numberOfPages,
    rowsPerPage,
    onTablePageChange,
    isRowHeader
}: TableRendererUtilPropsType) => {
    const emptyTableState: TableState = {
        searchText: "",
        currentPage: 1
    }
    const SHOULD_APPLY_FILTERS = AFN_PATH_NAMES.includes(window.location.pathname)
        && getUrlParams().get("format") === "table";
    const [tableFiltersKey, setTableFiltersKey] = useState(getFilterKey());
    const [tableState, setTableState] = useState(emptyTableState);
    const [openModal, setOpenModal] = useState<Record<string, boolean>>({});
    const [toasts, setToasts] = useState<Array<{ id: string, timeout: number, message: string }>>([])
    const [tableHeaders, settableHeaders] = useState(tableHeaders_)
    const [tableData, setTableData] = useState(tableData_)
    const [columnFiltersModalOpen, setColumnFiltersModalOpen] = useState(false)
    const [colsToFilter, setColsToFilter] = useState<string[]>(getLocalStorageList(tableFiltersKey));
    const rows_per_page = rowsPerPage ? rowsPerPage : ROWS_PER_PAGE
    const firstVisibleIndex = (tableState.currentPage - 1) * rows_per_page
    const lastVisibleIndex = firstVisibleIndex + rows_per_page


    useEffect(() => {
        // When user changes column filters update data in table 
        settableHeaders(tableHeaders_.filter(val => !colsToFilter.includes(val.visibleText)))
        setTableData(getColumnsFilteredData(tableData_, tableHeaders_, colsToFilter))
        const localStorageList = getLocalStorageList(tableFiltersKey);
        if (SHOULD_APPLY_FILTERS) {
            colsToFilter.forEach(colName => {
                if (!localStorageList.includes(colName)) {
                    addItemToLocalStorageList(tableFiltersKey, colName)
                }
            })
        }

    }, [colsToFilter]);

    useEffect(() => {
        setTableFiltersKey(getFilterKey());
        setColsToFilter(getLocalStorageList(getFilterKey()))
        const filtersFromLocalStorage = getLocalStorageList(tableFiltersKey)
        let selectedDefaultCols = filtersFromLocalStorage.length != 0 ? filtersFromLocalStorage : []
        if (SHOULD_APPLY_FILTERS && selectedDefaultCols.length == 0) {
            /* Display configured default columns if user hasn't selected any column filters
               for a search type in AFN. */
            const defaultCols = DEFAULT_TABLE_COLS.map(item => item.title.toUpperCase())
            let defFilteredCols = getColNames().filter(item => !defaultCols.includes(item.toUpperCase()))
            // makes sure we dont filter all columns if there are no default cols configured for any search types 
            defFilteredCols = defFilteredCols.length === getColNames().length ? [] : defFilteredCols
            setColsToFilter(defFilteredCols)
        }
        settableHeaders(tableHeaders_.filter(val => !colsToFilter.includes(val.visibleText)))
        setTableData(getColumnsFilteredData(tableData_, tableHeaders_, colsToFilter))
    }, [tableData_, tableHeaders_]);

    const getColNames = () => {
        return tableHeaders_.filter(item => item.isColumnActive).map(val => val.visibleText);
    }
    const shouldTruncateText = (displayText: string): boolean => {
        if (!displayText.includes(' ') && displayText.length >= MAX_STR_LENGTH_FOR_CELL)
            return true
        return false
    }
    const onClose = () => setColumnFiltersModalOpen(false)

    const getTableHeaders = () => {
        return <TableRow>
            {
                tableHeaders.map((columnHeader: TableColumns, index: number) => {
                    if (columnHeader.isColumnActive) {
                        return <TableCell key={index} width={columnHeader.width}>
                            <Theme tokens={{
                                textColorSecondary: '#045C69',
                                textFontSizeH100: columnHeader.styles?.fontSize ?? '12px'
                            }}>
                                <Text type={"h100"} color={"secondary"}>{columnHeader.visibleText}{
                                    columnHeader.toolTipText ? <Tooltip position="end" title={columnHeader.toolTipText} id="descriptionTooltip">
                                        <div style={{ maxHeight: '100px', maxWidth: '100px' }} ><Icon tokens={infoTokens}></Icon></div>
                                    </Tooltip> : <></>
                                }</Text>
                            </Theme>
                        </TableCell>;
                    }
                })
            }
        </TableRow>
    }

    // Doing this rendering by setting dangerouslySetInnerHTML because the in current response some cell values are HTML elements.
    // TODO: We can modify this when we setup APIGateway and Lambdas
    const getTableCellValue = (cellData: TableData) => {
        let htmlString = cellData.value == undefined ? "-" : cellData.value.toString()
        // Sanitizing the HTML content to avoid XSS attacks as we are directly embedding HTML into DOM
        // Allowlisting the target attribute later we need to add a DOM hook which sanitizes even the target attribute and gets the rel=noreferrer noopener added
        const sanitizedHtmlString = DOMPurify.sanitize(htmlString, { ADD_ATTR: ['target'] });
        var styles: Record<string, string> = cellData.styles as Record<string, string>
        return <span dangerouslySetInnerHTML={{ __html: sanitizedHtmlString }} style={styles}></span>
    }

    const GenerateTableCell = ({ cellData }: { cellData: TableData }) => {
        let rowSpanValue = cellData.rowSpan ?? 1
        return (
            <React.Fragment >
                {(() => {
                    switch (cellData.type) {
                        case 'highLightedText': {
                            return (
                                <TableCell key={cellData.value.toString()} width={cellData.width}>
                                    <React.Fragment>
                                        <Theme tokens={{
                                            textFontWeightB100: 'Bold',
                                        }}><>
                                                <Text type={"b100"}>
                                                    <>
                                                        {cellData.value}
                                                    </>
                                                    {cellData.addCopyIcon ?

                                                        <Button type="link" onClick={() => {
                                                            copyToClipboard(cellData)
                                                        }}>
                                                            <Icon tokens={copyTokens} />
                                                        </Button> : <></>

                                                    }
                                                </Text>
                                            </>
                                        </Theme>

                                    </React.Fragment>

                                </TableCell>
                            )
                        }
                        case 'header':
                            return (
                                <TableCell key={cellData.value.toString()} width={cellData.width}>
                                    <Theme tokens={{
                                        textColorSecondary: '#045C69',
                                        textFontSizeH100: cellData.styles?.fontSize ?? '14px'
                                    }}>
                                        <Text type={"h100"} color={"secondary"}><>{cellData.value}</></Text>
                                    </Theme>
                                </TableCell>
                            )
                        case 'text': {
                            let dataToDisplay = cellData.value == undefined ? "-" : cellData.value.toString()
                            const isTextTruncated: boolean = shouldTruncateText(dataToDisplay)

                            // { rowSpan == 0 ? (
                            return (
                                <>{
                                    rowSpanValue != 0 ?
                                        <TableCell key={dataToDisplay} width={cellData.width} rowSpan={rowSpanValue}>
                                            <Text truncate={isTextTruncated && !cellData.styles?.wordWrap} type={"b100"}>
                                                {isTextTruncated && !cellData.styles?.wordWrap ?
                                                    <Tooltip position="start" title={dataToDisplay} id="myTooltip">{getTableCellValue(cellData)}</Tooltip>
                                                    : getTableCellValue(cellData)
                                                }
                                                {cellData.addCopyIcon ?
                                                    <Button type="link" onClick={() => {
                                                        copyToClipboard(cellData)
                                                    }}>
                                                        <Icon tokens={copyTokens} />
                                                    </Button> : <></>
                                                }
                                            </Text>
                                        </TableCell> : <></>}</>
                            )

                        }
                        case 'string': {
                            let dataToDisplay = cellData.value == undefined ? "---" : cellData.value.toString()
                            return (
                                <TableCell key={dataToDisplay} width={cellData.width}>
                                    <Text type={"b100"} >{dataToDisplay}</Text>
                                </TableCell>
                            )
                        }
                        case 'tableInModal': {
                            return (
                                <TableCell key={cellData.value.toString()} width={cellData.width}>
                                    <ModalUtil openModal={openModal[cellData.value.toString()]}
                                        // TODO: Make it dynamic later
                                        title={"Rate Information"}
                                        setOpenModal={(value: boolean) => { setOpenModal({ ...openModal, [cellData.value.toString()]: value }) }}
                                    >
                                        <TableRendererUtil
                                            tableHeaders={generateTableHeaders(cellData.value as Record<string, infoDataType>[])}
                                            tableData={generateTableData(cellData.value as Record<string, infoDataType>[])}
                                            onFiltersClick={() => { }}
                                            showActionBarComponents={{
                                                "showActionBar": false,
                                                "showSearchBar": false,
                                                "showFilters": false,
                                                "showDownloadButton": false,
                                                "showColumnFilters": false
                                            }}
                                            numberOfPages={-1}
                                            isRowHeader={true}
                                        />
                                    </ModalUtil>
                                    <Button size="small" type="link" onClick={useCallback(() => setOpenModal({ ...openModal, [cellData.value.toString()]: true }), [])}>View Info</Button>
                                </TableCell>
                            )
                        }
                        case 'graphInModal': {
                            let cell: StackingFilterValue = cellData.value as StackingFilterValue;
                            return (
                                <TableCell>
                                    <ModalUtil openModal={openModal[cell.nodeId.toString()]}
                                        title={"Stacking Filter Graph"}
                                        setOpenModal={(val: boolean) => { setOpenModal({ ...openModal, [cell.nodeId.toString()]: val }) }}
                                    >
                                        <StackingFilterGraph stackingFilter={cell.stackingFilter} node={cell.nodeId} ></StackingFilterGraph>
                                    </ModalUtil>
                                    <Theme tokens={{ textFontSizeB100: "12px", linkForegroundColorDefault: "#0000EE" }}>
                                        <Text type="b100" className="links" >
                                            <Link onClick={useCallback(() => setOpenModal({ ...openModal, [cell.nodeId.toString()]: true }), [])}>{cell.stackingFilter}</Link>
                                        </Text>
                                    </Theme>
                                </TableCell>
                            )
                        }
                        case 'tag': {
                            // TODO: Need to make this logic optimised by avoiding usage of Table Cell & Tag component multiple times
                            switch (cellData.value) {
                                case 'Error': {
                                    return (
                                        <TableCell key={cellData.value.toString()} width={cellData.width}>
                                            <Tag type="error">{cellData.value as string}</Tag>
                                        </TableCell>
                                    )
                                }
                                case 'Info': {
                                    return (
                                        <TableCell key={cellData.value.toString()} width={cellData.width}>
                                            <Tag type="warning">{cellData.value as string}</Tag>
                                        </TableCell>
                                    )
                                }
                                default: {
                                    return (
                                        <TableCell key={cellData.value.toString()} width={cellData.width}>
                                            <Tag type="warning">{cellData.value as string}</Tag>
                                        </TableCell>
                                    )
                                }
                            }
                        }
                        case 'tableInCell': {
                            return <TableCell key={JSON.stringify(cellData.value.toString())} width={300}>
                                {cellData.value && (Array.isArray(cellData.value) && cellData.value.length !== 0) &&
                                    <TableRendererUtil
                                        tableHeaders={generateTableHeaders(cellData.value as Record<string, infoDataType>[])}
                                        tableData={generateTableData(cellData.value as Record<string, infoDataType>[])}
                                        onFiltersClick={() => { }}
                                        showActionBarComponents={{
                                            "showActionBar": false,
                                            "showSearchBar": false,
                                            "showFilters": true,
                                            "showDownloadButton": false,
                                            "showColumnFilters": true
                                        }}
                                        numberOfPages={-1}
                                        isRowHeader={true}
                                    />
                                }
                            </TableCell>
                        }
                        case 'image': {
                            return (
                                (typeof (cellData.value) === "string") &&
                                <TableCell>
                                    <img src={cellData.value} width="200" height="200"></img>
                                </TableCell>
                            )
                        }

                    }
                })()}
            </React.Fragment>
        )
    }

    const getTableRows = () => {
        return tableData.map((tableRecord: Array<TableData>) => {
            return <TableRow>
                {tableRecord.map((cellData: TableData) => {
                    return <React.Fragment>
                        <GenerateTableCell cellData={cellData} />
                    </React.Fragment>
                })}
            </TableRow>
        })
    }

    const updateTableState = (stateKey: string, stateValue: any) => {
        setTableState({ ...tableState, [stateKey]: stateValue });
    }

    // Should we use this param in onSearchSubmit as we have this var already in state? Can we remove this param for this method
    // onChange and onSubmit are both required for SearchField component
    // Instead maybe we can use Input but that will not have search Icon
    const onSearchSubmit = () => {
    }

    const onPageChange = (page: number) => {
        updateTableState("currentPage", page);
        if (onTablePageChange !== undefined)
            onTablePageChange(page)
    }

    const copyToClipboard = (cellData: TableData) => {
        copyToClipboardUtil(getInnerHtmlText(cellData.value as string))
        setToasts([...toasts, {
            id: Math.random().toString(),
            timeout: 3000,
            message: "Successfully copied to clipboard !"
        }])
    }

    const getColumnFilterableKeys = (): JSX.Element => {
        const handleColumnToggle = (columnName: string) => {
            setColsToFilter((prevCols) => {
                if (prevCols.includes(columnName)) {
                    removeItemFromLocalStorageList(tableFiltersKey, columnName);
                    return prevCols.filter((col) => col !== columnName);
                } else {
                    addItemToLocalStorageList(tableFiltersKey, columnName);
                    return [...prevCols, columnName];
                }
            });
        };
        return (
            <ul style={{ padding: 0, listStyleType: 'none' }}>
                {getColNames().map((colName) => (
                    <li key={colName} style={{ marginBottom: '10px' }}>
                        <Row widths={['fill', 'fit']}>
                            <Text type="h100" alignment="center">
                                {capitalizeFirstChar(colName)}
                            </Text>
                            <Toggle checked={!colsToFilter.includes(colName)} onChange={() => handleColumnToggle(colName)} />
                        </Row>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <React.Fragment>
            <Modal
                title="Toggle to customize the columns visible on the screen"
                open={columnFiltersModalOpen}
                onClose={onClose}
                scrollContainer="modal"
                closeLabel="Close"
                aria-describedby="customize-table-columns-modal"
            >
                {getColumnFilterableKeys()}
                <ModalFooter>
                    <Row alignmentHorizontal="end" widths="fit">
                        <Button onClick={() => {
                            localStorage.removeItem(tableFiltersKey)
                            addItemToLocalStorageList(tableFiltersKey, "-")
                            setColsToFilter([])
                        }}
                            disabled={false}
                            type="tertiary"
                            size="small"
                        >SHOW ALL
                        </Button>
                        <Button onClick={() => {
                            getColNames().forEach(item => addItemToLocalStorageList(tableFiltersKey, item))
                            setColsToFilter(getColNames())
                        }}
                            disabled={false}
                            type="tertiary"
                            size="small"
                        >HIDE ALL
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
            {
                showActionBarComponents.showActionBar &&
                <TableActionBar>
                    <Row width="100%" alignmentHorizontal="justify">
                        <Row>
                            {
                                showActionBarComponents.showSearchBar && <SearchField
                                    value={tableState.searchText}
                                    onChange={(searchText: string) => updateTableState("searchText", searchText)}
                                    placeholder="Search for..."
                                    onSubmit={onSearchSubmit}
                                />
                            }
                        </Row>

                        <Row alignmentHorizontal="start">
                            {
                                <>
                                    {showActionBarComponents.showColumnFilters && <Tooltip position="top"
                                        open={tableHeaders.length === 0 || undefined}
                                        title="Show/Hide Columns " id="table-column-filter">
                                        <Button size="small"
                                            onClick={() => setColumnFiltersModalOpen(true)}>
                                            <Icon tokens={FilterIcon} />
                                        </Button>
                                    </Tooltip>}
                                    <Button size="small"
                                        onClick={() => {
                                            handleDownloadFileBtnClick(tableHeaders_, tableData_)
                                        }}>
                                        <Icon tokens={downloadSmallIcon} />Export to CSV
                                    </Button>
                                    {
                                        showActionBarComponents.showFilters && <Tooltip position="top"
                                            title="Filter Results " id="table-column-filter">
                                            <Button type="primary" size="small" onClick={onFiltersClick}>
                                                <Icon tokens={filterTokens} /> Filters
                                            </Button>
                                        </Tooltip>
                                    }
                                </>
                            }

                        </Row>
                    </Row>
                </TableActionBar>
            }
            <div style={{ "overflowX": "auto" }}>
                <Table
                    headerColumns={!isRowHeader ? 1 : 0}
                    headerRows={isRowHeader ? 1 : 0}
                    stickyHeaderRow={isRowHeader}
                    stickyHeaderColumn={!isRowHeader}
                    layout={isRowHeader ? "fixed" : undefined}
                    showDividers={false}
                    showStripes={false}
                    spacing={"small"}
                >
                    {getTableHeaders()}
                    {onTablePageChange !== undefined ? getTableRows() : getTableRows().slice(firstVisibleIndex, lastVisibleIndex)}
                </Table>
                {
                    (tableData.length === 0 || tableData.length >= 1 && tableData[0].length == 0) &&
                    // when user filtered all columns , show a message to user informing it instead showing empty table
                    <Text type="b300" alignment="center">
                        You have filtered all columns .Please use column filters button to hide or show columns based on your preferences
                    </Text>
                }
            </div>
            {
                (numberOfPages !== -1 && numberOfPages !== 1) && <div style={{ 'display': 'flex', "justifyContent": "end" }}>
                    <Pagination
                        numberOfPages={numberOfPages}
                        onChange={onPageChange}
                        currentPage={tableState.currentPage}
                    />
                </div>
            }
            {toasts.length > 0 && <ToasterUtil toasts={toasts}
                setToasts={setToasts}
                type={"success"}
                toasterAlignmentHorizontal={"start"}
                toasterAlignmentVertical={"bottom"} />
            }
        </React.Fragment>

    )
}