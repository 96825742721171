import {FileUploadUtilDataType} from "src/components/helpers/file-upload/file-upload.util.type";
import { InputFieldDataType } from "src/components/helpers/input-field-util/input-field-util.type";
import {RadioButtonDataType} from "src/components/helpers/radio-button.util/radio-button.util.type";

export const xorRedriveHeaderData = {
    pageTitle: "EagleEye Solutions - XOR Redrive",
    key: "xor-redrive-page-header"
}

export const XORRedriveFileUploadUtilData: FileUploadUtilDataType = {
    href : encodeURI("data:text/csv;charset=utf-8," + ["trackingId"]),
    downloadedFileName: "xor-bulk-redrive-template.csv",
    linkText: "Download Template"
}

export const trackingIdData: InputFieldDataType = {
    label: "Tracking Id",
    type: "text",
    placeholder: "Enter tracking id",
    errorMessage: "Please enter tracking id",
}



export const xorRediveType: RadioButtonDataType = {
    label: "",
    radioButtonValues: [
        {
            visibleText: "Singular Redrive",
            value: "single"
        },{
            visibleText: "Bulk Redrive",
            value: "bulk"
        }
    ]
}        