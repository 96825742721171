import React from 'react';
import AppLayoutComponent from 'src/components/layouts/app-shell-layout/app-shell.layout'
import { AuthContext, AuthenticationContext } from '../context/AuthenticationContext';
import { CommonAttributeContext } from 'src/context/CommonDataContext';
export class App extends React.Component {
    render() {
        return (
            <AuthenticationContext>
                    <CommonAttributeContext>
                        <AppLayoutComponent />
                    </CommonAttributeContext>
            </AuthenticationContext>
        );
    }
}

export default App;