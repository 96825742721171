import axios, { AxiosInstance } from "axios";
import AxiosRequest from "src/common/axios/config/api-handlers/request-handler/request.handler";
import AxiosRequestError from "src/common/axios/config/api-handlers/request-handler/request-error.handler";
import AxiosResponse from "src/common/axios/config/api-handlers/response-handler/response.handler";
import AxiosResponseError from "src/common/axios/config/api-handlers/response-handler/response-error.handler";

export default function AxiosInterceptor(): AxiosInstance {
  axios.interceptors.request.use(AxiosRequest, AxiosRequestError);
  axios.interceptors.response.use(AxiosResponse, AxiosResponseError);
  return axios;
}
