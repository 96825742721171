import React ,  { useRef, useState, useEffect } from "react"
import Menu, { MenuItem } from "@amzn/meridian/menu"
import { MenuUtilComponentProps } from "./menu-util.type" 
import caretDownTokens from "@amzn/meridian-tokens/base/icon/caret-down"
import "./menu-util.module.scss";
import Row from "@amzn/meridian/row";
import { ButtonUtilComponent } from "../button-util/button-util";
import { ButtonPropertiesType, TokenObject } from "../button-util/button-util.type";

export const MenuUtilComponent = ({menuData, value, setValue}: MenuUtilComponentProps): JSX.Element =>{
    
    const menuButtonRef = useRef();
    const [open, setOpen] = useState(false);

    const buttonProperties:ButtonPropertiesType={label:menuData.label, type: 'link', size: 'small', ref:menuButtonRef}
    const customTokens:TokenObject = {
        // need to replace all these hardcoded values with variables
                            buttonForegroundColorLinkLightBackdropDefault: "#ffffff",
                            buttonForegroundColorLinkLightBackdropPressed: "#ffffff",
                            buttonForegroundColorLinkLightBackdropHover: "#ffffff"
                        }

    const handleClick = () =>{
        {menuButtonRef.current; setOpen(true)}
    }
    
    return(
            <React.Fragment>
                <Row>
                    <ButtonUtilComponent
                        ref={menuButtonRef}
                        customTokens={customTokens}
                        buttonProperties={buttonProperties}
                        handleClick = {handleClick}
                        value={value}
                        setValue={setValue}
                    />
                </Row>
                <Menu
                    anchorNode={menuButtonRef.current}
                    id= "my-menu"
                    open={open}
                    position={menuData.position}
                    onClose={()=>setOpen(false)}
                >
                    {menuData.Items.map(item =>{
                        // near console.log need to put the reference to the function which will change 
                        // the language or act according to the selected value
                        return <MenuItem key = {item.visibleText} onClick={() => {
                            setValue(item.visibleText)
                            // Need to add it in a try catch block and check if we have the storage to save this item in cache
                            localStorage.setItem(menuData.label.slice(0, -1).toLowerCase(), item.visibleText);
                        }}>{item.visibleText}</MenuItem>
                    })}
                </Menu>
            </React.Fragment>
    )
}