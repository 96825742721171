import { facetKeyToInfoMapping, ofisAllWorkflowsTableHeaders } from "src/common/constants/ofis-all-workflows.const";
import { ofisFailedWorkflowTableHeaders } from "src/common/constants/ofis-failed-workflow.const";
import { TableColumns, TableData } from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import { MAX_STR_LENGTH_FOR_CELL } from "src/components/helpers/table-renderer-util/table-renderer.config";
import {
    bucketsType,
    dropdownFacetType,
    ofisCallTrackingEventsType, OfisResponseType,
    ofisResponseType,
    tmppCallTrackingEventsType,
    tmppCallTrackingIdResponseType
} from "./ofis-dao.type";

class ofisResponse {
    response: OfisResponseType;
    tableHeaders: TableColumns[] = [];
    key: string;

    constructor(response: ofisResponseType, view: "ofis" | "tmpp") {
        this.key = Object.keys(response)[0]
        let resultsCount: number = (view == "ofis") ? (response[this.key] as ofisCallTrackingEventsType[]).length : (response[this.key] as tmppCallTrackingIdResponseType).hits?.length
        if (resultsCount == 0 || resultsCount === undefined) {
            this.response = {
                tableHeaders: undefined,
                trackingEventsDetails: undefined,
                facetDetails: undefined,
                totalItems: undefined,
                error: true
            }
        } else {
            this.tableHeaders = this.getColumnHeaders(response)
            this.response = {
                tableHeaders: this.tableHeaders,
                trackingEventsDetails: this.getTrackingEventsData(response),
                facetDetails: this.getFacetDetails(response),
                // TODO: Check this totalItems value what it should return in case of ofis Call.
                // In TOV package this value has been always set to return 10 with offset as 0 in the request to EECS.
                totalItems: (response[this.key] as tmppCallTrackingIdResponseType).found || 10,
                error: false
            }
        }
    }

    // This is a check to HTML string such as <a> sent from response received to replace the large innerHTML of the element
    // which occupies more column space with the columnName. Later when building API gateway we can check the need of this
    // and implement in lambdas if possible.
    checkAndTruncateInnerHtml = (htmlString: string, columnValue: string): string => {
        var patternToMatchHtmlTag = /<\/?[a-z][\s\S]*>/
        var patterToMatchInnerHtml = />[a-z0-9]+<\//
        if (patternToMatchHtmlTag.test(htmlString)) {
            var match = htmlString.match(patterToMatchInnerHtml)
            console.log("Match Outer ", match);
            if (match && match[0].length > MAX_STR_LENGTH_FOR_CELL) {
                return htmlString.replace(patterToMatchInnerHtml, `>${columnValue}</`)
            }
            return htmlString
        } else {
            return htmlString
        }
    }

    getColumnHeaders(data: ofisResponseType): TableColumns[] {
        var tableHeaders: TableColumns[]
        var key: string = this.key
        if ((data[key] as tmppCallTrackingIdResponseType).hits != null) {
            var activeKeys: string[] = Object.keys((data[key] as tmppCallTrackingIdResponseType).hits[0].fields)
            tableHeaders = this.getActiveColumnHeaders(ofisAllWorkflowsTableHeaders, activeKeys)
        } else {
            var activeKeys: string[] = Object.keys((data[key] as ofisCallTrackingEventsType[])[0].fields)
            tableHeaders = this.getActiveColumnHeaders(ofisFailedWorkflowTableHeaders, activeKeys)
        }
        return tableHeaders
    }

    getActiveColumnHeaders(tableHeaders: TableColumns[], activeList: string[]): TableColumns[] {
        return tableHeaders.filter((header: TableColumns) => {
            if (activeList.indexOf(header.columnKey) !== -1) {
                return header
            }
        })
    }

    getFacetDetails(data: ofisResponseType): dropdownFacetType | undefined {
        var key: string = this.key
        var facets = (data[key] as tmppCallTrackingIdResponseType).facets
        if (facets == null) {
            return undefined
        } else {
            var dropdownFacetType: dropdownFacetType = {};
            Object.keys(facets).map((facetKey: string) => {
                if (facets[facetKey].buckets.length === 0) return;

                dropdownFacetType[facetKey] = {
                    label: facetKeyToInfoMapping[facetKey],
                    key: facetKey,
                    buckets: facets[facetKey].buckets
                }
            })
            return dropdownFacetType
        }
    }
    isHeaderNeedCopyFunctionality(header: string) {
        let headersListForCopyIcon: Array<string> = ["Tracking ID", "Herd Object", "Herd Work Item id", "Fulfillment Shipment ID", "Customer Order Id", "Manifest Herd Object Id"];
        return headersListForCopyIcon.includes(header)
    }

    getTrackingEventsInTableFormat(data: ofisCallTrackingEventsType[] | tmppCallTrackingEventsType[]): TableData[][] {
        return data.map((trackingEvent: any): TableData[] => {
            return this.tableHeaders.map((tableHeader: TableColumns): TableData => {
                return {
                    value: this.checkAndTruncateInnerHtml(trackingEvent.fields[tableHeader.columnKey], tableHeader.visibleText),
                    type: "text",
                    addCopyIcon: this.isHeaderNeedCopyFunctionality(tableHeader.visibleText) ?? false
                }
            })
        })
    }

    getTrackingEventsData(data: ofisResponseType) {
        var key: string = this.key
        if ((data[key] as tmppCallTrackingIdResponseType).hits == null) {
            return this.getTrackingEventsInTableFormat((data[key] as ofisCallTrackingEventsType[]))
        } else {
            return this.getTrackingEventsInTableFormat((data[key] as tmppCallTrackingIdResponseType).hits)
        }
    }

}

export default ofisResponse