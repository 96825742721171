import {ToolLinkType} from "src/components/helpers/tool-links-renderer-util/tool-links-renderer-util.type"
import {TableColumns, TableData} from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import {DetailsUtilType} from "src/components/helpers/details-renderer-util/details-renderer-util.type";
import {MfnToolLinksDataType} from "src/common/dao/mfn/mfn-dao.type";

export const tableHeadersInitialState: TableColumns[] = [{visibleText: '', isColumnActive: true, columnKey: ''}]

export const tableDataInitialState: TableData[][] = [
    [{value: '', type: 'text', width: 200}, {value: '', type: 'text'}]
]

export const tableInitialState ={
    data: tableDataInitialState,
    headers: tableHeadersInitialState
}

export const overviewDetailsInitialState: DetailsUtilType = {
    className: "",
    sectionHeader: "",
    details: {"": {label: '', value: ''}}
}

export const toolLinksInitialState: MfnToolLinksDataType = {
    "grass-ui": [],
    "hank-ui": [],
    "merchant-portal": [],
    "mox-console": [],
    "promise-audit-tool": [],
    ape: [],
    meta: [],
    oblt: []
}

export const mfnToolLinksTemplates: Record<string, ToolLinkType> = {
    'hank-ui': {
        integUrl: '',
        prodUrl: 'https://hankui-{0}.aka.amazon.com/?id={1}&type=Order',
        label: 'Hank UI',
        args: ['realm', 'searchId']
    },
    'ape': {
        integUrl: 'https://ape-{0}.integ.amazon.com/explorer/?marketplaceID=USAmazon&merchantID=&orderID={1}&trackingID=&postageStatus=All&shipmentStatus=All',
        prodUrl: 'https://ape-{0}.amazon.com/explorer/?marketplaceID=USAmazon&merchantID=&orderID={1}&trackingID=&postageStatus=All&shipmentStatus=All',
        label: 'APE',
        args: ['realm', 'searchId']
    },
    'promise-audit-tool': {
        integUrl: 'https://flx-ui-tool-{0}.{0}.proxy.amazon.com/promiseAudit?orderId={1}&stage=test',
        prodUrl: 'https://flx-ui-tool-{0}.{0}.proxy.amazon.com/promiseAudit?orderId={1}&stage=prod',
        label: 'Promise Tool',
        args: ['region', 'orderId']
    },
    'merchant-portal': {
        integUrl: 'https://merchant-portal-v3-{0}.integ.amazon.com/search_result?merchantPrefixOrId={1}',
        prodUrl: 'https://merchant-portal-v3-{0}.aka.amazon.com/search_result?merchantPrefixOrId={1}',
        label: 'Merchant Portal',
        args: ['realm', 'merchantId']
    },
    'meta': {
        integUrl: 'https://meta.integ.amazon.com/index?orderIds={1}',
        prodUrl: 'https://meta-{0}.{0}.proxy.amazon.com/index?orderIds={1}',
        label: 'META',
        args: ['realm', 'orderId']
    }, // TODO: Check MOX-Console these links are very different might need a special case
    'mox-console': {
        integUrl: 'https://mox-console-{0}.{0}.proxy.amazon.com/',
        prodUrl: 'https://mox-console-{0}.{0}.proxy.amazon.com/',
        label: 'MOX Console',
        args: ['region']
    },
    'oblt': {
        integUrl: 'https://trans-app-{0}.integ.amazon.com/outboundLookup/packageLookup.cgi?data_type=TRACKING_ID;id={1}',
        prodUrl: 'https://trans-app-prod-{0}.amazon.com/outboundLookup/packageLookup.cgi?data_type=TRACKING_ID;id={1}',
        label: 'OBLT',
        args: ['region', 'trackingId']
    }
}

export const mfnResultsInitialState = {
    overviewDetailsData: overviewDetailsInitialState,
    toolLinksData: toolLinksInitialState,
    packageLevelDetailsData: tableInitialState,
    shippingLabelInfoData: tableInitialState,
    error: undefined
}