import {MenuDataType} from "src/components/helpers/menu-util/menu-util.type"

export const languageMenu: MenuDataType = {
    
    label: "Language:",
    position: "bottom",
    Items: [
        {
            visibleText: "en_US",
            value: "en_US"
        },
        {
            visibleText: "sk_SK",
            value: "sk_SK"
        },
        {
            visibleText: "es_ES",
            value: "es_ES"
        },
        {
            visibleText: "it_IT",
            value: "it_IT"
        },
        {
            visibleText: "ja_JP",
            value: "ja_JP"
        },
        {
            visibleText: "fr_FR",
            value: "fr_FR"
        },
        {
            visibleText: "cz_CZ",
            value: "cz_CZ"
        },
        {
            visibleText: "fr_CA",
            value: "fr_CA"
        },
        {
            visibleText: "de_DE",
            value: "de_DE"
        }

    ]
    
}

export const regionMenu: MenuDataType = {
    label: "Region:",
    position: "bottom",
    Items: [
        {
            visibleText: "NA",
            value: "na"
        },
        {
            visibleText: "EU",
            value: "eu"
        },
        {
            visibleText: "FE",
            value: "fe"
        },
        {
            visibleText: "CN",
            value: "cn"
        }
    ]
}
