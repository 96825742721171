import React, { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@amzn/meridian/box"
import Button from "@amzn/meridian/button"
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";
import Loader from "@amzn/meridian/loader";
import { DetailsRendererUtil } from "src/components/helpers/details-renderer-util/details-renderer-util"
import { PageHeaderUtility } from "src/components/helpers/headers.util/headers.util";
import { InputFieldUtil } from "src/components/helpers/input-field-util/input-field-util";
import { TableRendererUtil } from "src/components/helpers/table-renderer-util/table-renderer-util";
import { mfnVisibilityApi } from "src/common/api/mfn.api";
import { ToolLinksRenderer } from "src/components/helpers/tool-links-renderer-util/tool-links-renderer-utl";
import { filterAndUpdateUrl } from "src/utils/utils";
import mfnSearchResponse from "src/common/dao/mfn/mfn-response.dao";
import { mfnResultsType } from "src/pages/visibility-page/components/mfn/mfn.component.type";
import { CustomError, validResponseTableDataType } from "src/common/dao/mfn/mfn-dao.type";
import {
    mfnHeaderData,
    OrderIdData
} from "src/common/constants/mfn.const"
import { mfnResultsInitialState } from "src/pages/visibility-page/components/mfn/mfn.config";
import { ROWS_PER_PAGE } from "src/components/helpers/table-renderer-util/table-renderer.config";
import "./mfn.component.scss"
import { buttonClickMetricsPublisher } from "src/utils/metricUtils";
import InputMask from "@amzn/meridian/input/mask";
import VisibilityNavTabComponent from "../visibility-navtab/visibility-navTab.component";

const MfnComponent = () => {

    const mfnFields = {
        orderId: "",
        showResultsInfo: false,
        stateLoadedWithParams: false,
        isLoading: false
    }

    const [mfnState, setMfnState] = useState(mfnFields)
    const [mfnMissingFields, setMfnMissingFields] = useState<Record<string, string>>({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [mfnResults, setMfnResults] = useState<mfnResultsType>(mfnResultsInitialState);

    const getIncompleteFields = () => {
        let ORDER_ID_PATTERN = /^\d{3}[\- ]?\d{7}[\- ]?\d{7}$/;
        if (mfnState.orderId == "") {
            setMfnMissingFields({ ...mfnMissingFields, "orderId": "Please enter Order Id" })
            return true
        } else if (!ORDER_ID_PATTERN.test(mfnState.orderId)) {
            setMfnMissingFields({ ...mfnMissingFields, "orderId": "Invalid Order Id" })
            return true
        }
        setMfnMissingFields({})
        return false
    }

    useEffect(() => {
        if (searchParams.get("orderId")) {
            setMfnState({
                ...mfnState,
                orderId: searchParams.get("orderId") || "",
                stateLoadedWithParams: true
            })
        }
    }, []);

    useEffect(() => {
        if (mfnState.stateLoadedWithParams) {
            setMfnState({ ...mfnState, isLoading: true })
            handleSubmit()
        }
    }, [mfnState.stateLoadedWithParams])

    const handleSubmit = () => {
        getIncompleteFields()
        filterAndUpdateUrl(window.location.href, { orderId: mfnState.orderId }, document.title)
        if (Object.keys(mfnMissingFields).length != 0) return
        buttonClickMetricsPublisher.publishCounterMonitor("MFN-Search", 1)
        mfnVisibilityApi(mfnState.orderId).then((resp: mfnSearchResponse) => {
            if (resp.response.error) {
                setMfnResults({
                    packageLevelDetailsData: mfnResultsInitialState.packageLevelDetailsData,
                    overviewDetailsData: mfnResultsInitialState.overviewDetailsData,
                    toolLinksData: mfnResultsInitialState.toolLinksData,
                    shippingLabelInfoData: mfnResultsInitialState.shippingLabelInfoData,
                    error: resp.response.error
                })
            } else {
                setMfnResults({
                    packageLevelDetailsData: resp.response.packageLevelDetailsData!,
                    overviewDetailsData: resp.response.overviewDetailsData!,
                    toolLinksData: resp.response.toolLinksData!,
                    shippingLabelInfoData: resp.response.shippingLabelInfoData!,
                    error: undefined
                })
            }
            setMfnState({ ...mfnState, showResultsInfo: true, isLoading: false })
        })
    }

    return (
        <React.Fragment>
            <PageHeaderUtility pageTitle={mfnHeaderData.pageTitle} myKey={mfnHeaderData.key} />
            <Column alignmentHorizontal="center" spacing="none">
                <VisibilityNavTabComponent tab="MFN" />
                <Box className={"mfn-input-container"} width="75%" type="outline" spacingInset="400">
                    <Row alignmentVertical="center" alignmentHorizontal="center">
                        <InputFieldUtil inputFieldData={OrderIdData}
                            value={mfnState.orderId}
                            setValue={(value: string) => {
                                setMfnState({ ...mfnState, orderId: value })
                                setMfnMissingFields({})
                            }}
                            onBlur={() => { getIncompleteFields() }}
                            mask={new InputMask("###-#######-#######")}
                            error={mfnMissingFields.hasOwnProperty("orderId")}
                            errorMessage={mfnMissingFields["orderId"]}
                        />
                    </Row>
                    <Row alignmentHorizontal={"center"} spacingInset="300">
                        <Button disabled={mfnState.isLoading || mfnMissingFields["orderId"] != undefined}
                            onClick={() => {
                                if (getIncompleteFields()) return
                                setMfnState({ ...mfnState, showResultsInfo: false, isLoading: true })
                                handleSubmit()
                            }}>
                            {mfnState.isLoading ?
                                <> Loading <pre /> <Loader type="circular" size="small" /> </> :
                                "Search"
                            }
                        </Button>
                    </Row>
                </Box>
            </Column>

            {mfnState.showResultsInfo && (mfnResults.error ?
                <Row alignmentHorizontal="center">
                    <Box type="outline" spacingInset={"300"}>
                        <Text>{mfnResults.error}</Text>
                    </Box>
                </Row> :
                <div className={"mfn-package-search-results"}>
                    <Column spacingInset="400">
                        <ToolLinksRenderer toolLinksDataMap={mfnResults.toolLinksData} />
                        <DetailsRendererUtil detailsRendererUtilData={mfnResults.overviewDetailsData} />
                        <Box type={"outline"} spacingInset={"400"}>
                            <Column alignmentHorizontal={"center"} spacing={"400"}>
                                <Theme tokens={{
                                    textColorSecondary: '#045C69',
                                    textFontSizeH100: '14px'
                                }}>
                                    <Text type={"h100"} color={"secondary"}>Package Level Details</Text>
                                </Theme>
                            </Column>
                            <div style={{ margin: "auto" }}>
                                {(mfnResults.packageLevelDetailsData as CustomError).error ?
                                    <Row alignmentHorizontal="center">
                                        <Box type="outline" spacingInset={"300"}>
                                            <Text>{(mfnResults.packageLevelDetailsData as CustomError).error}</Text>
                                        </Box>
                                    </Row> :
                                    <TableRendererUtil
                                        tableData={(mfnResults.packageLevelDetailsData as validResponseTableDataType).data}
                                        onFiltersClick={() => {
                                        }}
                                        tableHeaders={(mfnResults.packageLevelDetailsData as validResponseTableDataType).headers}
                                        showActionBarComponents={{
                                            "showActionBar": false,
                                            "showSearchBar": false,
                                            "showFilters": false,
                                            "showDownloadButton": false,
                                            showColumnFilters:true
                                        }}
                                        numberOfPages={Math.ceil((mfnResults.packageLevelDetailsData as validResponseTableDataType).data.length / ROWS_PER_PAGE)}
                                        isRowHeader={true}
                                    />
                                }
                            </div>
                        </Box>
                        <Box type={"outline"} spacingInset={"400"}>
                            <Column alignmentHorizontal={"center"} spacing={"400"}>
                                <Theme tokens={{
                                    textColorSecondary: '#045C69',
                                    textFontSizeH100: '14px'
                                }}>
                                    <Text type={"h100"} color={"secondary"}>Shipping Label Details</Text>
                                </Theme>
                            </Column>
                            <div style={{ margin: "auto" }}>
                                {
                                    (mfnResults.shippingLabelInfoData as CustomError).error ?
                                        <Row alignmentHorizontal="center">
                                            <Box type="outline" spacingInset={"300"}>
                                                <Text>{(mfnResults.shippingLabelInfoData as CustomError).error}</Text>
                                            </Box>
                                        </Row> : <TableRendererUtil
                                            tableData={((mfnResults.shippingLabelInfoData as validResponseTableDataType).data)}
                                            onFiltersClick={() => {
                                            }}
                                            tableHeaders={((mfnResults.shippingLabelInfoData as validResponseTableDataType).headers)}
                                            showActionBarComponents={{
                                                "showActionBar": false,
                                                "showSearchBar": false,
                                                "showFilters": false,
                                                "showDownloadButton": false,
                                                showColumnFilters:false
                                            }}
                                            numberOfPages={Math.ceil(((mfnResults.shippingLabelInfoData as validResponseTableDataType).data).length / ROWS_PER_PAGE)}
                                            isRowHeader={true}
                                        />
                                }
                            </div>
                        </Box>
                    </Column>
                </div>)
            }

        </React.Fragment>
    )
}

export default memo(MfnComponent)