import {preSlamResponseDataType, ToolLinksDataType, HankResultListType, hankTableDetailsType, preSlamResponseType} from "./preslam-dao.type"
import {DetailsUtilType} from "src/components/helpers/details-renderer-util/details-renderer-util.type";
import {TableColumns, TableData} from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import {realmToFulfillmentMap, realmToRegionMap} from "src/components/helpers/tool-links-renderer-util/tool-links-renderer.config";
import { preSlamInitialState } from "../../../pages/visibility-page/components/preslam/preslam.config";
import {addExtraSpace, getRealmFromUrl} from "src/utils/utils";
class preSlamSearchResponse {

    response: preSlamResponseType;
    FC: string;
    
    constructor(preSlamResponse: preSlamResponseDataType, fc:string){
        this.FC = fc;
        if(preSlamResponse.FFRM.Error==true && preSlamResponse.FSMS.Error==true && preSlamResponse.FFRM.Error==true){
            this.response = preSlamInitialState;
            this.response.error=`Error occured, Can be due to any of the below reasons
                             - Might be Wrong Region selected,
                             - Shipment ID not valid/available,
                             - Unhandled Exception`
        }
        else{
            this.response = {
                toolLinksData: this.getToolLinksData(preSlamResponse),
                hankDetails: this.generateHankDetailsTable(preSlamResponse),
                hankOverviewDetails: this.generateShipmentOverviewDetails(preSlamResponse),
                hankTableHeaders: this.generateHankTableHeaders(preSlamResponse),
                error: undefined
            }
        }
    }

    getToolLinksData(data: preSlamResponseDataType): ToolLinksDataType{
        //Todo: need to get the ream value from header
        const realm: string = getRealmFromUrl();
        const region: string = realmToRegionMap[realm];
        const fulfilment : string = realmToFulfillmentMap[realm]
        const shipmentId = data.FSMS.fulfillmentShipmentId;
        const orderId = data.FSMS.orderId
        let scannable = ""
        if(!data.FFRM.Error && data.FFRM.scannableIdList)
            scannable=data.FFRM.scannableIdList[0];
        else
            scannable=""
        
        let defaultToolLinksData: Array<string|number> = [realm, shipmentId]
        return {
            atrops_troubleshooter: defaultToolLinksData,
            atrops_order_details: defaultToolLinksData,
            atrops_logs: defaultToolLinksData,
            f2p_visualiser: [orderId],
            grass: [realm,orderId],
            ffrm: defaultToolLinksData,
            csi: defaultToolLinksData,
            hank: [realm,orderId],
            box: [region,this.FC],
            promise: [orderId, fulfilment],
            hero: [realm,this.FC,shipmentId],
            tt_link: [shipmentId],
            slamops: [region,scannable]
        }
    }

    generateShipmentOverviewDetails(data: preSlamResponseDataType): DetailsUtilType{
        let scannable= "",shipment_condition= "";

        if(!data.FFRM.Error && data.FFRM.condition && data.FFRM.scannableIdList){
            scannable=data.FFRM.scannableIdList[0];
            shipment_condition=data.FFRM.condition
        }

        let shipmentOverviewDetails = {
            sectionHeader:"Shipment Information",
            className: "shipment-information-details",
            details:{
                "orderingShipmentId":{
                    label: "Ordering Shipment ID",
                    value: data.FSMS.orderingShipmentId,
                    isVisible: true
                },
                "fulfillmentShipmentId":{
                    label: "FulFillment Shipment ID",
                    value: data.FSMS.fulfillmentShipmentId,
                    isVisible: true
                },
                "fulfillmentReferenceId":{
                    label: "FulFillment Reference ID",
                    value: data.FSMS.fulfillmentReferenceId,
                    isVisible: true
                },
                "orderId":{
                    label: "Order ID",
                    value: data.FSMS.orderId,
                    isVisible: true
                },
                "scannable":{
                    label: "Scannable ID",
                    value: scannable,
                    isVisible: true
                },
                "Shipment Condition":{
                    label: "Shipment Condition",
                    value: shipment_condition,
                    isVisible: true
                },
            }
        }

        return shipmentOverviewDetails;
    }

    generateHankDetailsTableData(data: preSlamResponseDataType): Array<hankTableDetailsType>{
        let HankEventDetails: Array<hankTableDetailsType> = [];
        let result={"Error":[], "Exceptions":[], "Info":[], "Warning":[], "Other":[]};
        for (let i=0;i<data.Hank.resultList.length;i++){
            let diagnostic_entry: HankResultListType =data.Hank.resultList[i];
            let cti = "",diagnostic_name="",diagnostic_team="";

            if("data" in diagnostic_entry){
                    cti=("CTI" in diagnostic_entry.data)? diagnostic_entry.data.CTI:"";
                    diagnostic_name=("diagnosticName" in diagnostic_entry.data)? diagnostic_entry.data.diagnosticName:"";
                    diagnostic_team=("diagnosticTeam" in diagnostic_entry.data)? diagnostic_entry.data.diagnosticTeam:"";
            }
            let description = ("description" in diagnostic_entry)? diagnostic_entry.description:"";
            // If the starting 35 characters doesn't contain a space then adding spaces to the string
            if (description.length >= 35 && description.slice(0, 35).indexOf(" ") === -1){
                description = addExtraSpace(description,35)
                // description = description.slice(0, 35)+ " " + description.slice(36, description.length-1)
            }
            HankEventDetails.push({
                name: {
                    label: "Name", 
                    value:("name" in diagnostic_entry)? diagnostic_entry.name:"",
                    width: 135
                },
                diagnostic_name: {
                    label: "Diagnostic Name",
                    value: diagnostic_name,
                    width: 135
                },
                diagnostic_team:{
                    label:"Diagnostic Team",
                    value: diagnostic_team,
                    width: 135
                },
                type:{
                    label:"Type",
                    value: ("type" in diagnostic_entry && diagnostic_entry.type in result)? diagnostic_entry.type:"Other",
                    width: 100,
                    type: "tag"
                },
                description:{
                    label:"Description",
                    value:description,
                    width: 350
                },
                cti:{
                    label:"CTI",
                    value:cti,
                    width: 135
                }
            })
        }
        let order = { Error: 1, Warning: 2, Exceptions: 3, Info: 4, Other: 5};
        // sorting the log lines in the above order
        //HankEventDetails.sort(function (a, b) {
        //    return order[a.type.value]  - order[b.type.value];
        //});
        return HankEventDetails;
    }

    generateHankTableHeaders(preSlamResponse:preSlamResponseDataType):TableColumns[]{
        let hankDetails: hankTableDetailsType[] = this.generateHankDetailsTableData(preSlamResponse)
        let tableHeaders: Array<TableColumns> = [];

        if(hankDetails.length>0){
            Object.entries(hankDetails[0]).map(([key, cellInfo]) => {
                tableHeaders.push({
                    visibleText: cellInfo.label,
                    isColumnActive: true,
                    columnKey: key,
                    width: cellInfo.width ?? ''
                })
            })
        }
        return tableHeaders
    }

    generateHankDetailsTable(preSlamResponse: preSlamResponseDataType): TableData[][]{
        const tableData: Array<Array<TableData>> =[];
        let hankDetails: hankTableDetailsType[] = this.generateHankDetailsTableData(preSlamResponse)
        
        hankDetails.map((details)=>{
            let tableRecord: Array<TableData> = []
            Object.entries(details).map(([keys, cellInfo]) => {
                tableRecord.push({
                        value: cellInfo.value,
                        type: cellInfo.type ?? 'text', // Need to make it generic and add the type which is provided
                        width: 300
                    })
            })
            tableData.push(tableRecord)
        })
        return tableData
    }

    getUTCDate(epoch_date: number | undefined) {
        if(epoch_date == undefined) return "Invalid date";
        let UTCDate = new Date(epoch_date*1000);
        return UTCDate.toUTCString()
    }
}

export default preSlamSearchResponse;