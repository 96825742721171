import { getDataForRegion } from "src/common/constants/translations.const";
import { infoDataType } from "src/common/dao/mfn/mfn-dao.type";
import {
  TableColumns,
  TableData,
} from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import { katalLoggerError } from "src/logger";

export const generateTableHeaders = (
  data: Array<Record<string, infoDataType>>
): TableColumns[] => {
  let tableHeaders: Array<TableColumns> = [];
  Object.entries(data[0]).map(([key, cellInfo]) => {
    tableHeaders.push({
      visibleText: cellInfo.label,
      isColumnActive: true,
      columnKey: key,
      width: cellInfo.width ?? "",
      styles: cellInfo.styles,
    });
  });
  return tableHeaders;
};

export const generateTableData = (
  data: Array<Record<string, infoDataType>>
): TableData[][] => {
  let tableData: Array<Array<TableData>> = [];
  data.map((currentPackage) => {
    let tableRecord: Array<TableData> = [];
    Object.entries(currentPackage).map(([keys, cellInfo]) => {
      tableRecord.push({
        value: cellInfo.value ?? "",
        type: cellInfo.type == undefined ? "text" : cellInfo.type,
        addCopyIcon:
          cellInfo.value != null &&
          cellInfo.value != "---" &&
          cellInfo.value.toString().trim().length !== 0 &&
          (cellInfo.addCopyIcon !== undefined ? cellInfo.addCopyIcon : false),
        styles: cellInfo.styles,
      });
    });
    tableData.push(tableRecord);
  });
  return tableData;
};

export const getInnerHtmlText = (htmlString: string): string => {
  if (!htmlString || typeof htmlString !== "string") {
    throw new Error("Invalid input: htmlString must be a non-empty string");
  }
  var patternToMatchHtmlTag = /<\/?[a-z][\s\S]*>/;
  var patterToMatchInnerHtml = />[a-z0-9]+<\//;
  if (patternToMatchHtmlTag.test(htmlString)) {
    var match = htmlString.match(patterToMatchInnerHtml);
    if (match) {
      return match[0].replace(">", "").replace("</", "");
    }
    return htmlString;
  } else {
    return htmlString;
  }
};

export const extractObjectIdFromUrl = (urlString: string, idType: string) => {
  try {
    const url = new URL(urlString);
    const searchParams = new URLSearchParams(url.search);
    const objectId = searchParams.get(idType);
    return objectId;
  } catch (error) {
    katalLoggerError(
      window.location.href,
      `Error extracting ${idType} from URL: ${urlString}`,
      error
    );
    return null;
  }
};
export const extractIdFromUrl = (urlString: string) => {
  try {
    const url = new URL(urlString);
    const searchParams = url.search.substring(1); // Remove leading '?'
    const paramsArray = searchParams.split(";");
    for (const param of paramsArray) {
      const [key, value] = param.split("=");
      if (key === "id") {
        return value;
      }
    }
    return null; // Return null if id parameter not found
  } catch (error) {
    katalLoggerError(
      window.location.href,
      `Error extracting id from Url:`,
      error
    );
    return null;
  }
};
export const extractLinkValue = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const anchorTag = doc.querySelector("a");
  console.log("anchor Tag", anchorTag);
  const linkHref = anchorTag?.getAttribute("href");
  return linkHref || "";
};

export const hasAnchorTag = (html: string): boolean => {
  const anchorTagRegex = /<a\s+[^>]*href\s*=/i;
  return anchorTagRegex.test(html);
};

export const copyToClipboardUtil = (textToCopy: string) => {
  if (!textToCopy) {
    throw new Error("Invalid input: textToCopy must be a non-empty string");
  }
  if (hasAnchorTag(textToCopy)) {
    textToCopy = extractLinkValue(textToCopy);
  }
  const objectId = extractObjectIdFromUrl(textToCopy, "objectId");
  if (objectId != null) {
    textToCopy = objectId;
  }
  navigator.clipboard.writeText(textToCopy).catch((error) => {
    console.error("Failed to copy text to clipboard:", error);
  });
};

export const translateStrings = (req: any): any => {
  let d1: any = {};
  Object.keys(req).forEach((key) => {
    const value = req[key];
    const translatedText = getDataForRegion(value.label);
    const modifiedValue = { ...value, label: translatedText };
    d1[key] = modifiedValue;
  });
  return d1;
};

export const translateStringsForRecord = (req: any): any => {
  const translatedText = getDataForRegion(req.label);
  const result = { ...req, label: translatedText };
  return result;
};

export const formatTime = (time: string | undefined): string => {
  time = time ? time : "---";
  let [datePart, timePart] = time.split(" ");
  timePart = timePart ? timePart : "";
  let [day, month, year] = datePart.split("-");
  let [hours, minutes, seconds] = timePart.split(":");

  // Check if the provided values are valid
  if (
    isNaN(parseInt(day)) ||
    isNaN(parseInt(month)) ||
    isNaN(parseInt(year)) ||
    isNaN(parseInt(hours)) ||
    isNaN(parseInt(minutes)) ||
    isNaN(parseInt(seconds))
  ) {
    return time;
  }
  // We are getting the date in DD-MM-YYYY format and the default type is MM-DD-YYYY when we are trying to parse it
  // if the date is 14-08-2023 it returns it as in invalid date hence changing the order and then parsing the date.

  const dateObject = new Date(
    parseInt(year),
    parseInt(month) - 1, // Months are 0-indexed in JavaScript (0 = January)
    parseInt(day),
    parseInt(hours),
    parseInt(minutes),
    parseInt(seconds)
  );
  if (dateObject.toString() === "Invalid Date") {
    return time;
  }

  // Commenting the logic for displaying the Day, If we want to show it in future we can uncomment it
  // const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // const dayOfWeek = daysOfWeek[dateObject.getUTCDay()];
  const formattedMonth = new Intl.DateTimeFormat("en", {
    month: "short",
  }).format(dateObject);
  // const formattedDate = `${dayOfWeek} ${dateObject.getUTCDate()}-${formattedMonth}-${dateObject.getUTCFullYear()} ${dateObject.getUTCHours()}:${dateObject.getUTCMinutes()}:${dateObject.getUTCSeconds()} UTC`;
  const formattedDate = `${day}-${formattedMonth}-${year} ${hours}:${minutes}:${seconds} UTC`;
  return formattedDate;
};
