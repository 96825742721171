import {RadioButtonDataType} from "src/components/helpers/radio-button.util/radio-button.util.type";
import {DropdownDataType} from "src/components/helpers/dropdown-util/dropdown-util.type";
import {InputFieldDataType} from "src/components/helpers/input-field-util/input-field-util.type";

export const afnHeaderData = {
    pageTitle: "EagleEye Visibility – Home",
    key: "afn-page-header"
}

export const nodeInfo: InputFieldDataType = {
    label: "",
    type: "text",
    errorMessage: "Please enter Node info",
    placeholder: "Node info"
}

export const afnShipmentTypeData: RadioButtonDataType = {
    label: "",
    radioButtonValues: [
        {
            visibleText: "Scannable",
            value: "scannable",
            toolTipValue : "TrackingID Or Bar Codes Or TCDA ID Or OrderId Or Sp00_Id"
        },{
            visibleText: "Bag",
            value: "container"
        },{
            visibleText: "Trailer Id",
            value: "trailer"
        },{
            visibleText: "Shipment Id",
            value: "shipment"
        },{
            visibleText: "VRID",
            value: "vrid"
        }, {
           visibleText: "Transshipment",
           value: "transshipment"
        }, {
            visibleText: "Package Leg Detail",
            value: "pkglegdetail"
        },{
            visibleText: "Resource Id",
            value: "resourceId"
        }
    ]
}

export const FormatRadioButton : DropdownDataType= {
    label: "",
    placeholder : "Select a format",
    errorMessage: "Please select a format type",
    dropdownOptions: [
        {
            visibleText: "Leg",
            value: "leg"
        },
        {
            visibleText: "Table",
            value: "table"
        }
    ]
}
