import MidwayTokenError from "./error";

export const MIDWAY_DOMAIN = "midway-auth.amazon.com";

type QueryParameters = { [key: string]: string };

function generateNonce() {
  let nonce = "";
  const characterSet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 64; i += 1) {
    nonce += characterSet.charAt(
      Math.floor(Math.random() * characterSet.length)
    );
  }
  return nonce;
}

function buildQuery(parameters: QueryParameters) {
  return Object.keys(parameters).reduce(
    (accumulator, key) => `${accumulator + key}=${parameters[encodeURI(key)]}&`,
    "?"
  );
}

function buildSSOUrl(): string {
  const queryParams: QueryParameters = {
    response_type: "id_token",
    client_id: encodeURIComponent(window.location.host),
    redirect_uri: encodeURIComponent(window.location.href),
    scope: "openid",
    nonce: generateNonce(),
  };
  return `https://${MIDWAY_DOMAIN}/SSO${buildQuery(queryParams)}`;
}

function buildRedirectUrl(): string {
  const queryParams: QueryParameters = {
    client_id: encodeURIComponent(window.location.host),
    redirect_uri: encodeURIComponent(window.location.href),
    response_type: "id_token",
    scope: "openid",
    nonce: generateNonce(),
  };

  return `https://${MIDWAY_DOMAIN}/login?next=/SSO/redirect${encodeURIComponent(
    buildQuery(queryParams)
  )}`;
}

const getMidwayToken = () => {
  return fetch(buildSSOUrl(), { credentials: "include" }).then(
    async (response) => {
      if (response.ok) {
        return response.text();
      }
      window.location.href = buildRedirectUrl();
      throw new MidwayTokenError();
    }
  );
};

export default getMidwayToken;
