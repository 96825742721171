import React, { memo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@amzn/meridian/box"
import Input from "@amzn/meridian/input"
import Row from "@amzn/meridian/row"
import Icon from "@amzn/meridian/icon"
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column";
import Sheet from "@amzn/meridian/sheet"
import Text from "@amzn/meridian/text"
import Loader from "@amzn/meridian/loader";
import closeSmallTokens from "@amzn/meridian-tokens/base/icon/close-small"
import { shipmentTypeDropdownData, identifierTypeDropdownData } from "src/common/constants/ofis-failed-workflow.const"
import {
    documentTypeDropdownData,
    facetKeyToInfoMapping,
    identifierTypeAllWorkflowDropdownData
} from "src/common/constants/ofis-all-workflows.const"
import {
    ofisHeaderData,
    STR_ALL_WORKFLOWS,
    STR_FAILED_WORKFLOWS,
    workflowRadioButtonData,
    ofisBetaLink
} from "src/common/constants/ofis.const"
import { PageHeaderUtility } from "src/components/helpers/headers.util/headers.util";
import { DropDownUtilComponent, DependentDropDownUtilComponent } from "src/components/helpers/dropdown-util/dropdown-util"
import { RadioButtonUtil } from "src/components/helpers/radio-button.util/radio-button.util"
import { TableRendererUtil } from "src/components/helpers/table-renderer-util/table-renderer-util";
import "./ofis.component.scss"
import { ofisVisibilityApi } from "src/common/api/ofis.api";
import { bucketsType, faceInfoType, facetFilterType, ofisPayloadType } from "src/common/dao/ofis/ofis-dao.type";
import { ROWS_PER_PAGE } from "src/components/helpers/table-renderer-util/table-renderer.config";
import { buttonClickMetricsPublisher } from "src/utils/metricUtils";
import { filterAndUpdateUrl } from "src/utils/utils";
import { CustomHyperlink } from "src/components/helpers/custom-themes-util/custom-theme-util";
import { apiFatalsMetricsPublisher } from "src/utils/metricUtils";
import VisibilityNavTabComponent from "../visibility-navtab/visibility-navTab.component";
import exportSmallTokens from "@amzn/meridian-tokens/base/icon/export-small"

const OfisComponent = () => {

    const initalOfisState: any = {
        "identifierType": "",
        "documentType": "",
        "workflow": "all-workflows",
        "searchId": "",
        "isSheetOpen": false,
        "ofisTableData": [],
        "ofisTableHeaders": [],
        "ofisFacet": facetKeyToInfoMapping,
        "showDataTable": false,
        "numberOfPages": 1,
        "stateLoadedwithParams": false,
        "isLoading": false,
        "hasError": false
    }

    const initialOfisFiltersState: any = {
        "scac_code_f": "",
        "tmpp_workitem_status_reason_f": "",
        "tmpp_workitem_status_f": "",
        "trading_partner_f": "",
        "tmpp_ticket_id_f": "",
        "sender_id": "",
        "manifest_type_f": "",
        "transport_carrier_id_f": "",
        "warehouse_id_f": "",
        "carrier_f": "",
        "carrier_id_f": "",
        "pkg_status_f": "",
        "realm_f": "",
        "destination_fc_f": "",
        "scac_f": "",
        "stateLoadedwithParams": false
    }

    const initialShowActionBarComponentsForTable: any = {
        "showActionBar": true,
        "showSearchBar": false,
        "showFilters": true,
        "showDownloadButton": false,
        "showColumnFilters": true
    }

    const initialOfisMissingFields: any = {
        "identifierType": false,
        "documentType": false,
        "searchId": false,
    }

    const urlRequiredFields = ['view', 'searchId', 'searchType', 'documentType']
    const [ofisState, setOfisState] = useState(initalOfisState)
    const [ofisFiltersState, setOfisFiltersState] = useState(initialOfisFiltersState)
    const [showActionBarComponentsForTableState, setShowActionBarComponentsForTableState] = useState(initialShowActionBarComponentsForTable)
    const [ofisMissingFieldsState, setOfisMissingFieldsState] = useState<Record<string, boolean>>(initialOfisMissingFields);
    const [searchParams, setSearchParams] = useSearchParams()

    const updateOfisState = async (field: string, value: any) => setOfisState({ ...ofisState, [field]: value })
    const updateOfisFiltersState = (field: string, value: any) => setOfisFiltersState({
        ...ofisFiltersState,
        [field]: value
    })

    const getNextPageData = (page: number) => onSearchClick(page - 1)

    // This will assign the params from the url to the respective state variables only when page loads
    useEffect(() => {
        if (!validateURLParam()) {
            setOfisMissingFieldsState({
                ...ofisMissingFieldsState,
                searchId: (searchParams.get('searchId') == ""),
                identifierType: (searchParams.get('searchType') == ""),
                documentType: (searchParams.get('documentType') == "")
            })
            deleteURLParams();
            setOfisState({
                ...ofisState,
                workflow: searchParams.get('view') === 'tmpp' ? STR_ALL_WORKFLOWS : STR_FAILED_WORKFLOWS,
            })
            return;
        }
        setOfisState({
            ...ofisState,
            searchId: searchParams.get('searchId'),
            identifierType: searchParams.get('searchType'),
            documentType: searchParams.get('documentType'),
            workflow: searchParams.get('view') === 'tmpp' ? STR_ALL_WORKFLOWS : STR_FAILED_WORKFLOWS,
            stateLoadedwithParams: true,
            isLoading: true,
        })
        setOfisFiltersState({
            ...initialOfisFiltersState,
            ...JSON.parse(searchParams.get('facetFilters') || '{}'),
            stateLoadedwithParams: true
        })
        setOfisMissingFieldsState({
            ...ofisMissingFieldsState,
            searchId: false,
            identifierType: false,
            documentType: false
        })
    }, [])

    // This will update call the backend API after the params from url are assigned to state
    useEffect(() => {
        if (ofisState.stateLoadedwithParams & ofisFiltersState.stateLoadedwithParams) {
            onSearchClick()
            setOfisState({ ...ofisState, stateLoadedwithParams: true })
        }
    }, [ofisState.stateLoadedwithParams, ofisFiltersState.stateLoadedwithParams])

    const getOfisView = (workflowType: string) => {
        if (workflowType === STR_ALL_WORKFLOWS) {
            return "tmpp"
        } else {
            return "ofis"
        }
    }

    const validateInputFields = (): boolean => {
        let isHavingInvalidInputs = false
        let tempOfisMissingFieldsState = initialOfisMissingFields;
        Object.keys(ofisMissingFieldsState).map((key: string) => {
            if (ofisState[key] == null || ofisState[key] == '') {
                tempOfisMissingFieldsState[key] = true
                isHavingInvalidInputs = true
            } else {
                tempOfisMissingFieldsState[key] = false
            }
            setOfisMissingFieldsState({ ...ofisMissingFieldsState, ...tempOfisMissingFieldsState })
        })
        return !isHavingInvalidInputs
    }

    const getNonEmptyFacets = (facets: facetFilterType | undefined): facetFilterType | undefined => {
        if (facets) {
            delete facets["stateLoadedwithParams"]
        }
        if (facets != null) {
            Object.keys(facets).map(facetKey => {
                if (facets[facetKey] == "") {
                    delete facets[facetKey]
                }
            })
        }
        return facets
    }

    const updateSearchParams = () => {
        const params = {
            searchId: ofisState.searchId,
            searchType: ofisState.identifierType,
            documentType: ofisState.documentType,
            view: getOfisView(ofisState.workflow),
            //facetFilters: getOfisView(ofisState.workflow) === 'tmpp' ? JSON.stringify(getNonEmptyFacets(ofisFiltersState)) : '',
        }
        filterAndUpdateUrl(window.location.href, params, document.title)
    }

    const validateURLParam = () => {
        const areFieldsValid = urlRequiredFields.every(field => searchParams.get(field) != null && searchParams.get(field) !== '');
        if (!areFieldsValid) {
            return false
        } else {
            const documentTypeParam = searchParams.get('documentType');
            const identifierTypeParam = searchParams.get('searchType');
            const viewParam = searchParams.get('view')
            switch (viewParam) {
                case 'tmpp':
                    // Validate for 'tmpp' view
                    return Object.values(identifierTypeAllWorkflowDropdownData.dropdownOptions).some((param) =>
                        param.some((item) => item.value === identifierTypeParam));

                case 'ofis':
                    // Validate for 'ofis' view
                    return Object.values(identifierTypeDropdownData.dropdownOptions).some((param) =>
                        param.some((item) => item.value === identifierTypeParam));
                default:
                    // Invalid viewParam
                    return false;
            }
        }
    }

    const deleteURLParams = () => {
        urlRequiredFields.forEach(field => {
            const param = searchParams.get(field);
            if (param) {
                searchParams.delete(field);
            }
        });
        setSearchParams(searchParams);
    };

    const onSearchClick = async (offset?: number) => {
        setOfisState({ ...ofisState, hasError: false, showDataTable: false, isLoading: true })
        buttonClickMetricsPublisher.publishCounterMonitor("fetch-ofis-data", 1)
        if (!validateInputFields()) return;
        buttonClickMetricsPublisher.publishCounterMonitor("OFIS-Search", 1)
        buttonClickMetricsPublisher.publishCounterMonitor(ofisState.documentType, 1)
        buttonClickMetricsPublisher.publishCounterMonitor(ofisState.identifierType, 1)
        buttonClickMetricsPublisher.publishCounterMonitor(ofisState.workflow, 1)
        updateSearchParams();
        if (ofisState.workflow === "failed-workflows") {
            setShowActionBarComponentsForTableState({ ...showActionBarComponentsForTableState, "showFilters": false })
            setOfisFiltersState(initialOfisFiltersState)
        } else {
            setShowActionBarComponentsForTableState({ ...showActionBarComponentsForTableState, "showFilters": true })
        }

        const payload: ofisPayloadType = {
            searchId: ofisState.searchId,
            searchType: ofisState.identifierType,
            documentType: ofisState.documentType,
            size: 10,
            offset: offset || 0,
            view: getOfisView(ofisState.workflow),
            facetFilters: getNonEmptyFacets(ofisFiltersState),
        }
        ofisVisibilityApi(payload).then((resp) => {
            if (resp.response.error) {
                setOfisState({
                    ...ofisState,
                    "isSheetOpen": false,
                    "showDataTable": true,
                    "isLoading": false,
                    "hasError": true
                })
                apiFatalsMetricsPublisher.publishCounterMonitor("OFIS-Failure", 1)
            } else {
                setOfisState({
                    ...ofisState,
                    "isSheetOpen": false,
                    "showDataTable": true,
                    "isLoading": false,
                    "hasError": false,
                    "ofisTableData": resp.response.trackingEventsDetails!,
                    "ofisTableHeaders": resp.response.tableHeaders!,
                    "ofisFacet": resp.response.facetDetails!,
                    "numberOfPages": Math.ceil(resp.response.totalItems / ROWS_PER_PAGE)!
                })
                apiFatalsMetricsPublisher.publishCounterMonitor("OFIS-Success", 1)
            }
        })
    }

    const getDropdownData = (facetData: faceInfoType) => {
        return {
            label: facetData.label,
            placeholder: facetData.label,
            errorMessage: "",
            dropdownOptions: facetData.buckets && facetData.buckets.map((item: bucketsType) => {
                return {
                    visibleText: item.value + " (" + item.count + ")",
                    value: item.value
                }
            })
        }
    }

    const getAllFacetsComponent = () => {
        var facets = ofisState.ofisFacet
        return facets && Object.keys(facets).map(facetKey => {
            var dropdownData = getDropdownData(facets[facetKey])
            return <DropDownUtilComponent
                key={"facet-component"}
                dropDownData={dropdownData}
                value={ofisFiltersState[facetKey]}
                setValue={(value: string) => updateOfisFiltersState(facetKey, value)}
            />
        })
    }

    const openBetaUrl = () => {
        window.open(ofisBetaLink, "_blank", "noreferrer")
    }

    const getErrorMessage = () => {
        const ofisRealm = searchParams.get("region");

        const wikiLink = ofisState.documentType.includes("STATUS ") ? 'https://w.amazon.com/bin/view/Shipment_Status_Publisher_Clients/' : 'https://w.amazon.com/bin/view/Internal_Manifest_Publisher_Clients/';
        const errorMessage = "No failures found for" + ofisState.searchId + "in last 60 days.<br>If there are no entries in " +
            "OBLT as well, it would probably mean that our upstream did not send the data to us.<br><br>" +
            "Please search for some positive samples in OBLT (" +
            "<a href=\"https://trans-app-na.integ.amazon.com\" target=\"_blank\">Beta</a>,  " +
            "<a href=\"https://trans-app-prod-" + ofisRealm + ".amazon.com\" target=\"_blank\">Prod</a>).<br>" +
            "Find the senderId for positive cases and contact them to check " +
            "why there is no data sent for <b>" + ofisState.searchId + "</b>, by following this " +
            "<a href=\"" + wikiLink + "\" target=\"_blank\">wiki</a>."
        return errorMessage;
    }

    return (
        <React.Fragment>
            <Row alignmentHorizontal="end">
                <Button onClick={openBetaUrl} type="secondary">
                    OFIS Beta
                    <Icon className="links-icon" tokens={exportSmallTokens} />
                </Button>
            </Row>
            <PageHeaderUtility pageTitle={ofisHeaderData.pageTitle} myKey={ofisHeaderData.key} documentationLink={ofisHeaderData.documentationLink} toolTipTitle={ofisHeaderData.toolTipLinkTitle} />
            <Column alignmentHorizontal="center" spacing="none">
                <VisibilityNavTabComponent tab="OFIS" />
                <Box className="ofis-input-container" width="95%" type="outline" backgroundColor="white" spacingInset="400">
                    {/*Todo: Need to remove the hardcoded error message in below 4 elements*/}
                    <div className="ofis-workflow">
                        <Column className="ofis-input">
                            <Input
                                id="my-input"
                                value={ofisState.searchId}
                                onChange={(value: string) => {
                                    setOfisMissingFieldsState({ ...ofisMissingFieldsState, searchId: false })
                                    updateOfisState("searchId", value)
                                }}
                                type="text"
                                placeholder="Provide an input that is not older than 60 days"
                                prefix=""
                                error={ofisMissingFieldsState.searchId}
                                errorMessage={ofisMissingFieldsState.searchId ? "Please enter a valid input" : null}
                                width={"100%"}
                                size="xlarge"
                            />
                        </Column>
                        <Column className="ofis-radio">
                            <RadioButtonUtil
                                radioButtonData={workflowRadioButtonData}
                                value={ofisState.workflow}
                                setValue={(value: string) => {
                                    setOfisState({
                                        ...ofisState,
                                        workflow: value,
                                        identifierType: "",
                                        documentType: "",
                                        searchId: "",
                                        showDataTable: false,
                                        isLoading: false
                                    })
                                    searchParams.delete("identifierType")
                                    searchParams.delete("documentType")
                                    searchParams.delete("searchId")
                                }
                                }
                            />
                        </Column>
                        {(ofisState.workflow === "failed-workflows") && <Column className="ofis-drop-down">
                            <DropDownUtilComponent
                                key={"failed-workflows-dropdown"}
                                dropDownData={shipmentTypeDropdownData}
                                value={ofisState.documentType}
                                setValue={(value: string) => {
                                    setOfisMissingFieldsState({ ...ofisMissingFieldsState, documentType: false })
                                    updateOfisState("documentType", value)
                                    setOfisState({
                                        ...ofisState,
                                        documentType: value,
                                        identifierType: ""
                                    })
                                }}
                                width={"100%"}
                                error={ofisMissingFieldsState.documentType}
                            />
                            <DependentDropDownUtilComponent
                                key={"failed-workflows-dependent-dropdown"}
                                dropDownData={identifierTypeDropdownData}
                                value={ofisState.identifierType}
                                setValue={(value: string) => {
                                    setOfisMissingFieldsState({ ...ofisMissingFieldsState, identifierType: false })
                                    updateOfisState("identifierType", value)
                                }}
                                width={"100%"}
                                parentDropdownValue={ofisState.documentType}
                                error={ofisMissingFieldsState.identifierType}
                            />
                        </Column>}
                        {(ofisState.workflow === "all-workflows") && <Column className="ofis-drop-down">
                            <DropDownUtilComponent
                                dropDownData={documentTypeDropdownData}
                                key={"all-workflows-dropdown"}
                                value={ofisState.documentType}
                                setValue={(value: string) => {
                                    setOfisMissingFieldsState({ ...ofisMissingFieldsState, documentType: false })
                                    updateOfisState("documentType", value)
                                    setOfisState({
                                        ...ofisState,
                                        documentType: value,
                                        identifierType: ""
                                    })
                                }}
                                error={ofisMissingFieldsState.documentType}
                                width={"100%"}
                            />
                            <DependentDropDownUtilComponent
                                dropDownData={identifierTypeAllWorkflowDropdownData}
                                key={"all-workflows-dependent-dropdown"}
                                value={ofisState.identifierType}
                                setValue={(value: string) => {
                                    setOfisMissingFieldsState({ ...ofisMissingFieldsState, identifierType: false })
                                    updateOfisState("identifierType", value)
                                }}
                                parentDropdownValue={ofisState.documentType}
                                error={ofisMissingFieldsState.identifierType}
                                width={"100%"}
                            />
                        </Column>}
                    </div>
                    <Row alignmentHorizontal={"center"} spacingInset="300">
                        <Button
                            disabled={ofisState.isLoading || ofisMissingFieldsState["searchId"] || ofisMissingFieldsState["documentType"] || ofisMissingFieldsState["identifierType"]}
                            onClick={() => {
                                if (!validateInputFields()) return
                                onSearchClick()
                            }}
                        >
                            {ofisState.isLoading ?
                                <> Loading <pre /> <Loader type="circular" size="small" /> </> :
                                "Search"
                            }
                        </Button>
                    </Row>
                </Box>
                {
                    ofisState.showDataTable && <div className="ofis-table-layout">
                        <div>
                            <Box type="outline" spacingInset={"300"} >
                                {
                                    ofisState.hasError ?
                                        <Row alignmentHorizontal="center">
                                            <Box type="outline" spacingInset={"300"}>
                                                <Text>{`No records found for searched id in last 60 days`}</Text>
                                            </Box>
                                        </Row> :
                                        <div className="table-render1">
                                            <TableRendererUtil
                                                onFiltersClick={() => updateOfisState("isSheetOpen", !ofisState.isSheetOpen)}
                                                tableData={ofisState.ofisTableData}
                                                tableHeaders={ofisState.ofisTableHeaders}
                                                showActionBarComponents={showActionBarComponentsForTableState}
                                                numberOfPages={ofisState.numberOfPages}
                                                onTablePageChange={getNextPageData}
                                                isRowHeader={true}
                                            />
                                        </div>
                                }
                            </Box>
                        </div>
                    </div>
                }
                <Sheet type="overlay" side="end" open={ofisState.isSheetOpen}
                    onClose={() => updateOfisState("isSheetOpen", !ofisState.isSheetOpen)}>
                    <Column spacing="400" height={250}>
                        <Row alignmentHorizontal="justify">
                            <Text type="h300">Filter OFIS Results</Text>
                            <Button onClick={() => updateOfisState("isSheetOpen", !ofisState.isSheetOpen)} type="icon">
                                <Icon tokens={closeSmallTokens}>Close sheet</Icon>
                            </Button>
                        </Row>
                        {getAllFacetsComponent()}
                        <Row width="100%" widths="fill" spacingInset={"300 200"}>
                            <Button type="link" onClick={() => {
                                //setOfisFiltersState(initialOfisFiltersState)
                                onSearchClick()
                                getAllFacetsComponent()
                            }
                            }>Reset Filters</Button>
                            <Button onClick={onSearchClick}>Apply Filters</Button>
                        </Row>
                    </Column>
                </Sheet>
            </Column>
        </React.Fragment>
    )
}

const OFISBannerComponent = ({ workflowType }: { workflowType: string }) => {
    return (
        //Todo: move the text to constants file
        <React.Fragment>
            {(workflowType == "all-workflows") ?
                <Text type="h100" color={"error"}>
                    <CustomHyperlink linkText={"TMPP Visibility Portal"}
                        href={"https://trans-logistics.amazon.com/tmpp/home"} />
                    <span> is being deprecated and migrated here. For more details </span>
                    <CustomHyperlink linkText={"click here"}
                        href={"https://w.amazon.com/bin/view/Transportation/GMP/tmpp_ofis_migration_user_guide/"} />
                </Text> :
                <Text type="h100" color={"error"}>
                    <span> To switch to TMPP visibility portal view and see all records, select the toggle on the left. For more details </span>
                    <CustomHyperlink linkText={"click here"}
                        href={"https://tiny.amazon.com/qbaxex7y/wamazbinviewTranGMPtmpp"} />
                </Text>
            }
        </React.Fragment>
    )
}

export default memo(OfisComponent)