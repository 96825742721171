import React, { useEffect, useState } from "react";
import { getEmployeeAlias, getToken } from "../../../../../auth/cognito-auth";
import { doAfnBulkSearchRequestApiCall } from "../../../../../common/api/afn.api";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import Loader from "@amzn/meridian/loader";
import Tooltip from "@amzn/meridian/tooltip";
import Icon from "@amzn/meridian/icon";
import checkKnockoutTokens from "@amzn/meridian-tokens/base/icon/check-knockout";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";

import Modal, { ModalFooter } from "@amzn/meridian/modal";
const unixTimeToLocalTime = (unixTime: number) => {
  const date = new Date(unixTime * 1000); // Multiply by 1000 to convert from seconds to milliseconds

  // Get individual date components
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  // Construct the readable date string
  const readableTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return readableTime;
};
const getStatusIcon = (status: string) => {
  switch (status) {
    case "COMPLETED":
      return <Icon tokens={checkKnockoutTokens} />;
    case "PROCESSING":
    case "PENDING":
      return <Loader size="small" />;
    case "FAILED":
      return (
        <Tooltip
          position="end"
          title="Bulk search request failed to get processed, 
                our Oncall engineer must be checking on it, Please use regular search till then"
          id="myTooltip"
        >
          <Text>Failed</Text>
        </Tooltip>
      );
  }
};

const getRequestId = (row: any) => {
  const body =
    typeof row.request_body === "string"
      ? JSON.parse(row.request_body)
      : row.request_body;

  const page = body?.body?.page || "";
  const searchType = body?.body?.searchType || "";
  const requestId = row.request_id.substring(0, 3) || "";
  return `${page === "afn" ? "home" : page}#${searchType}-${requestId}`;
};

export const BulkSearchResultsModalComponent = (props: any) => {
  const [bulkSearchResultsForUser, setbulkSearchResultsForUser] =
    useState(null);
  const [isBulkSearchHistoryVisible, _] = useState(props.open);
  const onClickFooterButton = () => props.setOpen(false);

  const getUserBulkSearchRequests = async () => {
    try {
      const bulkSerachRequestInput = {
        headers: {
          Authorization: getToken(),
        },
        body: {
          type: "LIST",
          userLogin: getEmployeeAlias(),
        },
      };

      doAfnBulkSearchRequestApiCall(bulkSerachRequestInput).then((resp) => {
        setbulkSearchResultsForUser(resp);
      });
    } catch (err) {
      console.error("error while retrieving bulk search requests");
      throw err;
    }
  };

  useEffect(() => {
    // Call api once first and then every n seconds to refresh
    getUserBulkSearchRequests();
    if (isBulkSearchHistoryVisible) {
      const intervalId = setInterval(async () => {
        await getUserBulkSearchRequests();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [isBulkSearchHistoryVisible]);

  return (
    <Modal
      title="Bulk Search History"
      open={isBulkSearchHistoryVisible}
      scrollContainer="modal"
      aria-describedby="modal-description"
    >
      {bulkSearchResultsForUser == null ? (
        <Text>Loading bulk search history ...</Text>
      ) : (
        <Table headerRows={1} showDividers={true}>
          {(bulkSearchResultsForUser as []).length == 0 ? (
            <p>Bulk search history is empty</p>
          ) : (
            <TableRow>
              <TableCell sortColumn="request_id">Request ID</TableCell>
              <TableCell sortColumn="status">Status</TableCell>
              <TableCell sortColumn="status">Request Time</TableCell>
            </TableRow>
          )}
          {(bulkSearchResultsForUser as []).map((row: any, index) => (
            <TableRow key={index}>
              <TableCell>
                <a href={row.s3_file_link} target="_blank">
                  {getRequestId(row)}
                </a>
              </TableCell>
              <TableCell>{getStatusIcon(row.status)}</TableCell>
              <TableCell>{unixTimeToLocalTime(row.create_timestamp)}</TableCell>
            </TableRow>
          ))}
        </Table>
      )}
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Text alignment="justify" type="b200">
            Note : Bulk Search history is automatically erased after 24 hours.
          </Text>
          <Button
            className="bulksearch_history_btn"
            type="primary"
            size="small"
            onClick={onClickFooterButton}
          >
            Close
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};
