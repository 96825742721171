import {AfnResponseType, AfnTableResultsComponentType} from "src/pages/visibility-page/components/afn/afn.component.type";
import React from "react";
import Column from "@amzn/meridian/column";
import {TableRendererUtil, handleDownloadFileBtnClick} from "src/components/helpers/table-renderer-util/table-renderer-util";
import {GetResponseTableDataType, validResponseTableDataType} from "src/common/dao/mfn/mfn-dao.type";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import "../afn.component.scss"
import Icon from "@amzn/meridian/icon";
import downloadSmallIcon from "@amzn/meridian-tokens/base/icon/download-small"
import { HIDABLE_ATTR_PREFIX } from "src/components/helpers/table-renderer-util/table-renderer.config";


const hideCsvAttributes=(afnResults:GetResponseTableDataType)=>{
    "hide attributes  from rendering in table , that dont needs to be shown in UI but needs to be there if user exports data to CSV"
    afnResults = JSON.parse(JSON.stringify(afnResults)) as typeof afnResults; // taking deep copy
    let headers =  (afnResults as validResponseTableDataType).headers;
    let data = (afnResults as validResponseTableDataType).data;

    for (let i = headers.length - 1; i >= 0; i--) {
        if (headers[i].visibleText.startsWith(HIDABLE_ATTR_PREFIX)){
            headers.splice(i, 1); 
            for (let j = data.length - 1; j >= 0;j--) {
                for (let k=data[j].length-1;k>=0;k--){
                    if (k == i){
                        data[j].splice(k,1)
                    }
                }
            }
        }
    }
    return afnResults
}

export const AfnTableResultsComponent = ({afnResults, showActionBarComponentsForTable, tableResponseForExport}: AfnTableResultsComponentType) => {
    let ROWS_PER_PAGE = 50
    const afnResultsFiltered = hideCsvAttributes(afnResults)
    return (
        <Column spacingInset={"300"} >
            <Box backgroundColor="Primary" type="outline" spacingInset="400">
                <TableRendererUtil
                    tableHeaders={(afnResultsFiltered as validResponseTableDataType).headers}
                    tableData={(afnResultsFiltered as validResponseTableDataType).data}
                    onFiltersClick={() => {}}
                    showActionBarComponents={showActionBarComponentsForTable}
                    numberOfPages={Math.ceil((afnResultsFiltered as validResponseTableDataType).data.length / ROWS_PER_PAGE )!}
                    isRowHeader={true}
                />
            </Box>
        </Column>
    )
}
export const getDownloadDataBtn=(tableResponseForExport:AfnResponseType)=>{
        return <div style={{ margin: "2% 0 -1% 0" }}>
        <Row width="100%" alignmentHorizontal="end"  className="actions" >
            <Button  size="small" 
                onClick={() =>{
                    const table:any = tableResponseForExport.table;
                    handleDownloadFileBtnClick(table.headers,table.data)
                }}>
                <Icon tokens={downloadSmallIcon}/>Export to CSV 
            </Button>
        </Row>
    </div>
}