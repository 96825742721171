import React, { useState, useEffect } from "react";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import "./user-guide-modal.scss";

export interface UserGuideModalProps {
  title: string;
  descriptionText: string;
  pointers: string[];
  videoText: string;
  videoUrl: string;
  id: string;
  showFrequencyInSeconds: number;
  successButtonText: string;
  broadCastLink?: string;
}

export const UserGuideModal = (props: UserGuideModalProps) => {
  const [open, setOpen] = useState(false);
  const onClickFooterButton = () => setOpen(false);
  const onClose = () => setOpen(false);
  const LAST_SHOWN ='_lastShown'

  useEffect(() => {
    const lastShownTimestamp = localStorage.getItem(`${props.id}_${LAST_SHOWN}`);
    const currentTimestamp = new Date().getTime();
    const showFrequencyInMilliseconds = props.showFrequencyInSeconds * 1000;

    if (!lastShownTimestamp) {
      setOpen(true);
      localStorage.setItem(
        `${props.id}_${LAST_SHOWN}`,
        currentTimestamp.toString()
      );
    } else if (
      currentTimestamp - parseInt(lastShownTimestamp, 10) >=
      showFrequencyInMilliseconds
    ) {
      setOpen(true);
      localStorage.setItem(
        `${props.id}_${LAST_SHOWN}`,
        currentTimestamp.toString()
      );
    }
  }, [props.id, props.showFrequencyInSeconds]);

  return (
    <>
      {open && (
        <Modal
          title={props.title}
          open={open}
          onClose={onClose}
          scrollContainer="viewport"
          closeLabel="Close"
          aria-describedby="modal-description"
        >
          <div className="container">
            <div className="user_guide_summary_text">
              <Text type="h100">{props.descriptionText}</Text>
              <ul>
                {props.pointers.map((pointer, index) => (
                  <li key={index}>
                    <Text type="b100">{pointer}</Text>
                  </li>
                ))}
              </ul>
              <Text type="b200">{props.videoText}</Text>
            </div>

            <div className="user_guide_video">
              <div
                style={{
                  maxHeight: "60vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <br />
                <video height="auto" controls>
                  <source src={props.videoUrl} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <ModalFooter>
            {props.broadCastLink && (
              <Row alignmentHorizontal="start" widths="fit">
                <Text breakWord={true}>
                  Having trouble with the video? Try this
                  <a href={props.broadCastLink}> link instead.</a>
                </Text>
              </Row>
            )}
            <Row alignmentHorizontal="end" widths="fit">
              <Button type="primary" size="small" onClick={onClickFooterButton}>
                {props.successButtonText}
              </Button>
            </Row>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
