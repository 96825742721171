import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import AxiosInterceptor from "./axios.interceptors";

export const axios: AxiosInstance = AxiosInterceptor();

export const axiosGet = (
  baseUrl: String,
  endPoint: String,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return axios.get(`${baseUrl}/${endPoint}`, config);
};
export const axiosPost = (
  baseUrl: String,
  endPoint: String,
  data: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return axios.post(`${baseUrl}/${endPoint}`, data, config);
};
export const axiosPut = (
  baseUrl: String,
  endPoint: String,
  data: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return axios.put(`${baseUrl}/${endPoint}`, data, config);
};
export const axiosPatch = (
  baseUrl: String,
  endPoint: String,
  data: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return axios.put(`${baseUrl}/${endPoint}`, data, config);
};
export const axiosDelete = (
  baseUrl: String,
  endPoint: String,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return axios.delete(`${baseUrl}/${endPoint}`, config);
};
