import DOMPurify from "dompurify";
import { sanitizeInput } from "src/utils/input-santize-util";
class mfnSearchRequest {
  orderId: String;

  constructor(orderId: string) {
    orderId = sanitizeInput(orderId)
    this.orderId = orderId;
  }
}

export default mfnSearchRequest;