import React from "react"
import Modal, {ModalFooter} from "@amzn/meridian/modal"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import {ModalUtilType} from "src/components/helpers/modal-util/modal-util.type";

export const ModalUtil = (props: ModalUtilType) => {

    const {title, openModal, setOpenModal} = props

    return (
        <React.Fragment>
            <Modal
                title={title}
                open={openModal}
                onClose={() => setOpenModal(false)}
                scrollContainer="viewport"
                closeLabel="Close"
                aria-describedby="modal-description"
            >
                {props.children}
                { (props.isFooterRequired ?? false) && <ModalFooter>
                    <Row alignmentHorizontal="end" widths="fit">
                        <Button type="secondary" size="small" onClick={() => setOpenModal(false)}>
                            Cancel
                        </Button>
                        <Button type="primary" size="small" onClick={() => setOpenModal(false)}>
                            Confirm
                        </Button>
                    </Row>
                </ModalFooter> }
            </Modal>
        </React.Fragment>
    )
}