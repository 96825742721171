import React, {memo, useState, useEffect, useContext} from "react"
import AppLayout from "@amzn/meridian/app-layout"
import Loader from "@amzn/meridian/loader"
import SideMenuComponent from "src/components/presentational/side-menu/side-menu.component"
import AppHeaderComponent from "src/components/presentational/app-header/app-header.component"
import AppFooterComponent from "src/components/presentational/app-footer/app-footer.component"
import AppContentComponent from "src/components/presentational/app-content/app-content.component"
import {getSideBarMenuItems} from "src/common/constants/sideNav.const"
import { useAuthContext } from "src/context/AuthenticationContext"
import sideLogo from 'src/images/eagleeye.png'; 
import { useCommonDataContext, CommonAttributeContext } from "src/context/CommonDataContext"

const AppLayoutComponent = ():JSX.Element => {

    const userAuthorizationInfo = useCommonDataContext()
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [menuItems, setMenuItems] = useState(getSideBarMenuItems(userAuthorizationInfo))
    const userInfo = useAuthContext()

    useEffect(() => {
        const updatedMenuItems = getSideBarMenuItems(userAuthorizationInfo)
        setMenuItems(updatedMenuItems)
    },[userAuthorizationInfo])


    return (
       userInfo.loading? <Loader /> : <AppLayout
            sidebarComponent={SideMenuComponent}
            backgroundColor="alternateSecondary"
            headerComponent={AppHeaderComponent}
            footerComponent={AppFooterComponent}
        >
            <AppHeaderComponent handleMenuButton={setIsMenuOpen} isMenuOpen={isMenuOpen}/>
            <SideMenuComponent handleMenuButton={setIsMenuOpen} isMenuOpen={isMenuOpen} logo={sideLogo} SideBarMenuItems={menuItems} />
            <AppContentComponent />
            <AppFooterComponent/>
        </AppLayout>
    )
}

export default memo(AppLayoutComponent)