import React from "react";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import {DetailsRendererUtilPropsType, CellRendererUtilPropsType} from "./details-renderer-util.type";
import "./details-renderer-util.scss"
import Theme from "@amzn/meridian/theme";
import Box from "@amzn/meridian/box";

export const DetailsRendererUtil = ({detailsRendererUtilData}: DetailsRendererUtilPropsType) => {
    return (
            <Box key={"package-details"} type={"outline"} spacingInset={"0"}>
                <div key={"package-header"} className={"details-renderer-header"}>
                    <Text className={"section-header"}>{detailsRendererUtilData.sectionHeader}</Text>
                </div>
                <div className="details-renderer-1">
                <div className={"details-renderer-data"}>
                    {Object.entries(detailsRendererUtilData.details).map(([key, detail]) => {
                        // If the value is empty then adding -- to avoid empty fields
                        if(detail.value=="" || detail.value==undefined)
                            detail.value="----"
                        return (
                            detail.isVisible && <div
                                className={detailsRendererUtilData.className + "-cell"}>
                                <CellRendererUtil key={detail.label} title={detail.label} value={detail.value} color={detail.color}/>
                            </div>
                        )
                    })}
                </div>
                </div>
            </Box>
    )
}

export const CellRendererUtil = ({title, value, color}: CellRendererUtilPropsType) => {
    return (
        <Theme tokens={{textLineHeightB100:"14px",textColorError:"#ff0301"}}>
            <Column spacingInset="300" alignmentHorizontal="start" spacing="none" alignmentVertical="center">
                    <Text type={"b200"} className={"section-header"}>{title}</Text>
                    <Text type={"b100"} color={color}>{value}</Text>   
            </Column>
        </Theme>
    )
}