import React, { memo, useEffect, useState } from "react";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import { InputFieldUtil } from "src/components/helpers/input-field-util/input-field-util"
import { PageHeaderUtility } from "src/components/helpers/headers.util/headers.util";
import { ShipmentIdData, FCData, preslamHeaderData } from "src/common/constants/preslam.const";
import "./pre-slam.component.scss"
import { useSearchParams } from "react-router-dom";
import { preslamVisibilityApi } from "src/common/api/preslam.api";
import preSlamSearchResponse from "src/common/dao/preslam/preslam-response.dao"
import { InputFieldType } from "./preslam.component.type";
import { preSlamInitialState } from "./preslam.config";
import { ToolLinksRenderer } from "src/components/helpers/tool-links-renderer-util/tool-links-renderer-utl";
import { DetailsRendererUtil } from "src/components/helpers/details-renderer-util/details-renderer-util";
import { TableRendererUtil } from "src/components/helpers/table-renderer-util/table-renderer-util";
import { filterAndUpdateUrl, isStringNumber, isStringAlphaNumeric } from "src/utils/utils";
import { preSlamResponseType } from "src/common/dao/preslam/preslam-dao.type";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Loader from "@amzn/meridian/loader";
import Column from "@amzn/meridian/column";
import { buttonClickMetricsPublisher } from "src/utils/metricUtils";
import { apiFatalsMetricsPublisher } from "src/utils/metricUtils";
import VisibilityNavTabComponent from "../visibility-navtab/visibility-navTab.component";

const PreSlamComponent = () => {

    const preSlamInputFields: InputFieldType = {
        shipmentId: "",
        FC: "",
        showResultsInfo: false,
        stateLoadedwithParams: false,
        isLoading: false,
    }
    const [localStates, setLocalStates] = useState({ inputValid: false, handleSubmit: false });
    const [preSlamState, setPreSlamState] = useState(preSlamInputFields);
    const [preSlamMissingFields, setPreSlamMissingFields] = useState<Record<string, string>>({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [preSlamResults, setPreSlamResults] = useState<preSlamResponseType>(preSlamInitialState)

    // This function is used for validation of the input fields
    const validateInputFields = async () => {
        // TODO: Need to add a regex check for valid shipment ID for now, assuming valid shipment ID to be having a number with 14 digits
        // Resetting all the values to false before checking
        let SHIPMENT_ID_PATTERN = /^\d{14}\d*$/;
        setPreSlamMissingFields({})
        setLocalStates({ ...localStates, inputValid: false, handleSubmit: false })
        let shipmentError = "", FCError = "", FCBypass = 0;

        if (preSlamState.shipmentId === "")
            shipmentError = "No Shipment ID provided"
        else if (!SHIPMENT_ID_PATTERN.test(preSlamState.shipmentId) || preSlamState.shipmentId.toString().length > 30)
            shipmentError = "Invalid Shipment ID"
        else if (!isStringNumber(preSlamState.shipmentId))
            shipmentError = "Shipment ID can contain only numbers"

        if (preSlamState.FC === "" || preSlamState.FC === "NA")
            setPreSlamState({ ...preSlamState, FC: "NA" })
        else if (!isStringAlphaNumeric(preSlamState.FC))
            FCError = "WarehouseId should be Alphanumeric"
        else if (preSlamState.FC.length != 4)
            FCError = "WarehouseId should be of 4 characters"

        // If the input passess all the above checks then marking the input validation as true
        if (FCError === "" && shipmentError === "") {
            setLocalStates({ ...localStates, inputValid: true })
            return;
        }
        // If any errors are found then updating the missing fields section with the error message
        if (FCError !== "" && shipmentError !== "")
            setPreSlamMissingFields({ "shipmentId": shipmentError, "FC": FCError })
        else if (FCError !== "")
            setPreSlamMissingFields({ "FC": FCError })
        else if (shipmentError !== "")
            setPreSlamMissingFields({ "shipmentId": shipmentError })
    }

    // This Effect is always invoked to check if the URL is having the shipment ID provided 
    useEffect(() => {
        if (searchParams.get("shipmentId") || searchParams.get("FC")) {
            let shipmentId = searchParams.get("shipmentId") || "";
            let FC = searchParams.get("FC") || "";
            setPreSlamState({ ...preSlamState, shipmentId, FC, stateLoadedwithParams: true })
        }
    }, []);

    // This Effect will be invoked when the user opens a sharable URL or hits the submit button
    useEffect(() => {
        if (preSlamState.stateLoadedwithParams === true)
            validateInputFields()
    }, [preSlamState.stateLoadedwithParams])

    // This Effect is invoked to handle the submit operation
    useEffect(() => {
        if (localStates.handleSubmit === true) {

            validateInputFields()
        }
    }, [localStates.handleSubmit])

    // This Effect will be invoked when we have validated the input provided
    useEffect(() => {
        // Checking if the input is valid and there are no missing fields present
        if (localStates.inputValid === true && (Object.keys(preSlamMissingFields) as string[]).length === 0) {
            setPreSlamState({ ...preSlamState, showResultsInfo: false, isLoading: true })
            filterAndUpdateUrl(window.location.href, { shipmentId: preSlamState.shipmentId, FC: preSlamState.FC.toUpperCase() }, document.title)
            buttonClickMetricsPublisher.publishCounterMonitor("PreSlam-Search", 1)
            preslamVisibilityApi(preSlamState.shipmentId, preSlamState.FC.toUpperCase()).then(async (resp: preSlamSearchResponse) => {
                setPreSlamResults({
                    ...preSlamResults,
                    hankDetails: resp.response.hankDetails,
                    hankOverviewDetails: resp.response.hankOverviewDetails,
                    toolLinksData: resp.response.toolLinksData,
                    hankTableHeaders: resp.response.hankTableHeaders,
                    error: resp.response.error
                })
                setPreSlamState({ ...preSlamState, showResultsInfo: true, isLoading: false })
                setLocalStates({ ...localStates, inputValid: false, handleSubmit: false })
                apiFatalsMetricsPublisher.publishCounterMonitor("PreSlam-Success", 1)
            }).catch((err) => {
                setPreSlamState({ ...preSlamState, showResultsInfo: true, isLoading: false })
                setLocalStates({ ...localStates, inputValid: false, handleSubmit: false })
                setPreSlamResults({
                    ...preSlamResults,
                    error: err.message
                })
                apiFatalsMetricsPublisher.publishCounterMonitor("PreSlam-Failure", 1)
            })
        }
    }, [localStates.inputValid])

    return (
        <React.Fragment>
            <PageHeaderUtility pageTitle={preslamHeaderData.pageTitle} myKey={preslamHeaderData.myKey} />
            <Column alignmentHorizontal="center" spacing="none">
                <VisibilityNavTabComponent tab="PreSlam" />
                <Box className="preslam-input-container" width="75%" type="outline" spacingInset="400">
                    <Row widths={["grid-5", "grid-4", "grid-3"]} alignmentVertical="center" alignmentHorizontal="center">
                        <InputFieldUtil
                            inputFieldData={ShipmentIdData}
                            value={preSlamState.shipmentId}
                            setValue={(value: string) => {
                                setPreSlamState({ ...preSlamState, shipmentId: value })
                            }}
                            onBlur={() => { }}
                            mask={""}
                            error={preSlamMissingFields.hasOwnProperty("shipmentId")}
                            errorMessage={preSlamMissingFields["shipmentId"]}
                        />
                        <InputFieldUtil
                            inputFieldData={FCData}
                            value={preSlamState.FC}
                            setValue={(value: string) => {
                                setPreSlamState({ ...preSlamState, FC: value })
                            }}
                            onBlur={() => { }}
                            mask={""}
                            error={preSlamMissingFields.hasOwnProperty("FC")}
                            errorMessage={preSlamMissingFields["FC"]}
                        />
                    </Row>
                    <Row alignmentHorizontal={"center"} spacingInset="300">
                        <Button disabled={preSlamState.isLoading} onClick={() => {
                            setLocalStates({ ...localStates, handleSubmit: true })
                        }}>
                            {preSlamState.isLoading ?
                                <> Loading <pre /> <Loader type="circular" size="small" /> </> :
                                "Search"
                            }</Button>

                    </Row>
                </Box>
                {preSlamState.showResultsInfo && (preSlamResults.error ?
                    <Row alignmentHorizontal="center" spacingInset={"400"}>
                        <Box type="outline" spacingInset={"300"}>
                            <Text>{preSlamResults.error}</Text>
                        </Box>
                    </Row> :
                    <div className={"preslam-results"}>
                        <Column spacingInset="400">
                            <ToolLinksRenderer toolLinksDataMap={preSlamResults.toolLinksData} />
                            <DetailsRendererUtil detailsRendererUtilData={preSlamResults.hankOverviewDetails} />
                            <Box type="outline" spacingInset="400" >
                                <div className="hank-table">
                                    <div className={"hank-table-header"}>
                                        <Text className="section-header">Hank Details</Text>
                                    </div>
                                    <TableRendererUtil
                                        tableData={preSlamResults.hankDetails}
                                        onFiltersClick={() => { }}
                                        tableHeaders={preSlamResults.hankTableHeaders}
                                        showActionBarComponents={{
                                            "showActionBar": false,
                                            "showSearchBar": false,
                                            "showFilters": false,
                                            "showDownloadButton": false,
                                            "showColumnFilters":false
                                        }}
                                        numberOfPages={-1}
                                        onTablePageChange={() => { }}
                                        isRowHeader={true}
                                    />
                                </div>
                            </Box>
                        </Column>
                    </div>)
                }
            </Column>
        </React.Fragment>
    )
}

export default memo(PreSlamComponent)