import {getLanguageFromUrl} from "src/utils/utils";

type languageType = {
    [key : string] : string
}

type TranslationsType = {
    [key : string] : languageType
}

export const TranslationsConst : TranslationsType = {
    restrictedText : {
            'en_US' : "Important note! Information is 'Restricted' due to access control, please click on <a href='https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/Permissions#HWhatisthePermissionModel3F'>this wiki</a> and follow the steps to get needed permissions",
            'cz_CZ' : "Důležitá poznámka! Informace na této stránce jsou řízeny oprávněním, klikněte prosím na <a href='https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/Permissions#HWhatisthePermissionModel3F'>tato wiki</a> a postupujte podle pokynů, abyste získali potřebná oprávnění." ,
            'sk_SK' : "Dôležitá poznámka! Informácie na tejto stránke sú kontrolované povolením, kliknite na <a href='https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/Permissions#HWhatisthePermissionModel3F'>túto wiki</a> a postupujte podľa pokynov na získanie potrebných povolení.",
            'es_ES' : "¡Nota importante! La información de esta página está controlada por permisos. Haga clic en <a href='https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/Permissions#HWhatisthePermissionModel3F'>esta wiki</a> y siga los pasos para obtener los permisos necesarios.",
            'it_IT' : "Nota importante! Le informazioni su questa pagina sono soggette a autorizzazione, fai clic su <a href='https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/Permissions#HWhatisthePermissionModel3F'>questo wiki</a> e segui i passaggi per ottenere le autorizzazioni necessarie.",
            'ja_JP' : "重要な注意事項！このページの情報は権限が管理されています。<a href='https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/Permissions#HWhatisthePermissionModel3F'>このウィキ</a> をクリックし、手順に従って必要な権限を取得してください。",
            'fr_FR' : "Remarque importante ! Les informations de cette page sont soumises à un contrôle d'autorisation, veuillez cliquer sur <a href='https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/Permissions#HWhatisthePermissionModel3F'>ce wiki</a> et suivre les étapes pour obtenir les autorisations nécessaires.",
            'fr_CA' : "Nota important! La informació d'aquesta pàgina està controlada per permisos, feu clic a <a href='https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/Permissions#HWhatisthePermissionModel3F'>aquest wiki</a> i seguiu els passos per obtenir els permisos necessaris.",
            'de_DE' : "Wichtiger Hinweis! Die Informationen auf dieser Seite sind berechtigungsgesteuert. Bitte klicken Sie auf <a href='https://w.amazon.com/bin/view/TransportationFrontline/Eagleeye/Permissions#HWhatisthePermissionModel3F'>dieses wiki</a> und folgen Sie den Schritten, um die erforderlichen Berechtigungen zu erhalten.",
    },
    bannerText : {
        'en_US' : "This is EagleEye 2.0, please start using this for your day-to-day operations. In case you face any issues, please report <a href='https://issues.amazon.com/issues/create?assignedFolder=bc9d7672-7bd4-40e3-a72f-44aa99fda1d7'>here</a>.",
        'cz_CZ' : "Toto je EagleEye 2.0, začněte to používat pro své každodenní operace. V případě, že se setkáte s jakýmikoli problémy, prosím nahlaste <a href='https://issues.amazon.com/issues/create?assignedFolder=bc9d7672-7bd4-40e3-a72f-44aa99fda1d7'>zde</a>.",
        'sk_SK' : "Toto je EagleEye 2.0, začnite ho používať pre svoje každodenné operácie. V prípade akýchkoľvek problémov nahláste [<a href='https://issues.amazon.com/issues/create?assignedFolder=bc9d7672-7bd4-40e3-a72f-44aa99fda1d7'>tu</a>.",
        'es_ES' : "Esto es EagleEye 2.0, comience a usarlo para sus operaciones diarias. En caso de que tenga algún problema, infórmelo <a href='https://issues.amazon.com/issues/create?assignedFolder=bc9d7672-7bd4-40e3-a72f-44aa99fda1d7'>aquí</a>.",
        'it_IT' : "Questo è EagleEye 2.0, inizia a usarlo per le tue operazioni quotidiane. In caso di problemi, segnalalo <a href='https://issues.amazon.com/issues/create?assignedFolder=bc9d7672-7bd4-40e3-a72f-44aa99fda1d7'>qui</a>.",
        'ja_JP' : "これはEagleEye 2.0です。日常業務に使い始めてください。問題が発生した場合は、<a href='https://issues.amazon.com/issues/create?assignedFolder=bc9d7672-7bd4-40e3-a72f-44aa99fda1d7'>ここ</a> ",
        'fr_FR' : "Il s'agit d'EagleEye 2.0, veuillez commencer à l'utiliser pour vos opérations quotidiennes. Si vous rencontrez des problèmes, veuillez les signaler <a href='https://issues.amazon.com/issues/create?assignedFolder=bc9d7672-7bd4-40e3-a72f-44aa99fda1d7'>ici</a>.",
        'fr_CA' : "Aquest és EagleEye 2.0, si us plau, comenceu a utilitzar-lo per a les vostres operacions del dia a dia. En cas que s'enfronti a qualsevol problema, si us plau informe <a href='https://issues.amazon.com/issues/create?assignedFolder=bc9d7672-7bd4-40e3-a72f-44aa99fda1d7'>aquí</a>.",
        'de_DE' : "Dies ist EagleEye 2.0, bitte fangen Sie an, es für Ihren täglichen Betrieb zu verwenden. Falls Sie auf Probleme stoßen, melden Sie dies bitte <a href='https://issues.amazon.com/issues/create?assignedFolder=bc9d7672-7bd4-40e3-a72f-44aa99fda1d7'>hier</a>."
    },
    regionErrorText : {
        'en_US' : "Hope you are searching in the correct region! Otherwise, please change it in top right corner of page and search again",
        'cz_CZ' : "Doufám, že hledáte ve správné oblasti! V opačném případě jej prosím změňte v pravém horním rohu stránky a znovu vyhledejte",
        'sk_SK' : "Dúfam, že hľadáte v správnom regióne! V opačnom prípade ho zmeňte v pravom hornom rohu stránky a znova vyhľadajte",
        'es_ES' : "¡Espero que estés buscando en la región correcta! De lo contrario, cámbielo en la esquina superior derecha de la página y vuelva a buscar",
        'it_IT' : "Spero che tu stia cercando nella regione corretta! Altrimenti, modificalo nell'angolo in alto a destra della pagina e cerca di nuovo",
        'ja_JP' : "正しい地域で検索していることを願っています！それ以外の場合は、ページの右上隅で変更して、もう一度検索してください",
        'fr_FR' : "J'espère que vous recherchez dans la bonne région ! Sinon, veuillez le modifier en haut à droite de la page et effectuer une nouvelle recherche",
        'fr_CA' : "Espero que busqueu a la regió correcta! En cas contrari, canvieu-lo a la cantonada superior dreta de la pàgina i torneu a cercar",
        'de_DE' : "Ich hoffe, Sie suchen in der richtigen Region! Andernfalls ändern Sie es bitte in der oberen rechten Ecke der Seite und suchen Sie erneut"
    },
    inputErrorText : {
        'en_US' : "Error occurred, the inputs provided are not returning any results",
        'cz_CZ' : "Došlo k chybě, zadané vstupy nevracejí žádné výsledky",
        'sk_SK' : "Vyskytla sa chyba, poskytnuté vstupy nevracajú žiadne výsledky",
        'es_ES' : "Se produjo un error, las entradas proporcionadas no arrojan ningún resultado",
        'it_IT' : "Si è verificato un errore, gli input forniti non restituiscono alcun risultato",
        'ja_JP' : "エラーが発生しました。入力された内容は結果を返しません",
        'fr_FR' : "Une erreur s'est produite, les entrées fournies ne renvoient aucun résultat",
        'fr_CA' : "S'ha produït un error, les entrades proporcionades no estan retornant cap resultat",
        'de_DE' : "Ein Fehler ist aufgetreten, die angegebenen Eingaben geben keine Ergebnisse zurück"
    },
    searchErrorText : {
        'en_US' : "Search Id cannot be null",
        'cz_CZ' : "Vyhledávací ID nemůže být null",
        'sk_SK' : "Id vyhľadávania nemôže byť null",
        'es_ES' : "El identificador de búsqueda no puede ser nulo",
        'it_IT' : "L'ID di ricerca non può essere nullo",
        'ja_JP' : "検索 ID を NULL にすることはできません",
        'fr_FR' : "L'identifiant de recherche ne peut pas être nul",
        'fr_CA' : "ID de cerca no pot ser nul",
        'de_DE' : "Die Such-ID darf nicht Null sein"
    },
    searchLimitExceededErrorText : {
        "en_US": "Cannot search more than _inputIdLimit ids at a time. If you want to search beyond this limit, please turn on BULK SEARCH toggle, which should allow searching up to 1000 ids.",
        "cz_CZ": "Nelze najednou vyhledat více než _inputIdLimit identifikátorů. Pokud chcete hledat nad tento limit, prosím zapněte přepínač BULK SEARCH, který by měl umožnit vyhledávání až 1000 identifikátorů.",
        "sk_SK": "Nemožno vyhľadávať viac ako _inputIdLimit identifikátorov naraz. Ak chcete vyhľadávať za tento limit, prosím zapnite prepínač BULK SEARCH, čo by malo umožniť vyhľadávanie až 1000 identifikátorov.",
        "es_ES": "No se pueden buscar más de _inputIdLimit identificadores a la vez. Si desea buscar más allá de este límite, por favor active el interruptor BULK SEARCH, lo que debería permitir buscar hasta 1000 identificadores.",
        "it_IT": "Non è possibile cercare più di _inputIdLimit ID alla volta. Se si desidera cercare oltre questo limite, si prega di attivare il toogle di RICERCA BULK, che dovrebbe consentire di cercare fino a 1000 ID.",
        "ja_JP": "一度に_inputIdLimit以上のIDを検索することはできません。この制限を超えて検索する場合は、BULK SEARCHトグルをオンにしてください。これにより、最大1000のIDまで検索できるはずです。",
        "fr_FR": "Impossible de rechercher plus de _inputIdLimit identifiants à la fois. Si vous souhaitez effectuer une recherche au-delà de cette limite, veuillez activer le commutateur BULK SEARCH, ce qui devrait permettre de rechercher jusqu'à 1000 identifiants.",
        "fr_CA": "Impossible de rechercher plus de _inputIdLimit identifiants à la fois. Si vous souhaitez rechercher au-delà de cette limite, veuillez activer le commutateur BULK SEARCH, ce qui devrait permettre de rechercher jusqu'à 1000 identifiants.",
        "de_DE": "Es können nicht mehr als _inputIdLimit IDs auf einmal gesucht werden. Wenn Sie über dieses Limit hinaus suchen möchten, bitte aktivieren Sie den BULK SEARCH-Schalter, der das Suchen von bis zu 1000 IDs ermöglichen sollte."
      },
      bulkSearchNoteText : {
            "en_US": "Note: Bulk Search is for searching more than 50 ids. Search results will be available for download.",
            "cz_CZ": "Poznámka: Bulk Search slouží k hledání více než 50 identifikátorů. Výsledky hledání budou k dispozici ke stažení.",
            "sk_SK": "Poznámka: Bulk Search slúži na vyhľadávanie viac ako 50 identifikátorov. Výsledky vyhľadávania budú k dispozícii na stiahnutie.",
            "es_ES": "Nota: Bulk Search es para buscar más de 50 identificadores. Los resultados de la búsqueda estarán disponibles para descargar.",
            "it_IT": "Nota: Bulk Search è per cercare più di 50 ID. I risultati della ricerca saranno disponibili per il download.",
            "ja_JP": "注意: Bulk Search は 50 以上の ID を検索するためのものです。検索結果はダウンロード可能です。",
            "fr_FR": "Remarque : Bulk Search est destiné à la recherche de plus de 50 identifiants. Les résultats de la recherche seront disponibles en téléchargement.",
            "fr_CA": "Note : Bulk Search est destiné à la recherche de plus de 50 identifiants. Les résultats de la recherche seront disponibles en téléchargement.",
            "de_DE": "Hinweis: Bulk Search ist für die Suche nach mehr als 50 IDs gedacht. Die Suchergebnisse stehen zum Download zur Verfügung."
      },
      bulkSearchExpectedTimeText : {
        "en_US": "Search results will be available in less than 5 mins, max 15 minutes.",
        "cz_CZ": "Výsledky hledání budou k dispozici za méně než 5 minut, maximálně 15 minut.",
        "sk_SK": "Výsledky vyhľadávania budú k dispozícii do 5 minút, najviac 15 minút.",
        "es_ES": "Los resultados de la búsqueda estarán disponibles en menos de 5 minutos, máximo 15 minutos.",
        "it_IT": "I risultati della ricerca saranno disponibili in meno di 5 minuti, massimo 15 minuti.",
        "ja_JP": "検索結果は5分以内に利用可能であり、最大15分かかります。",
        "fr_FR": "Les résultats de la recherche seront disponibles en moins de 5 minutes, maximum 15 minutes.",
        "fr_CA": "Les résultats de la recherche seront disponibles en moins de 5 minutes, maximum 15 minutes.",
        "de_DE": "Suchergebnisse sind in weniger als 5 Minuten, maximal 15 Minuten verfügbar."
    },
    bulkSearchRequestIdText:{
        "en_US": "Your request id is '_request_id'.",
        "cz_CZ": "ID vašeho požadavku je '_request_id'.",
        "sk_SK": "ID vášho požiadavku je '_request_id'.",
        "es_ES": "Tu identificador de solicitud es '_request_id'.",
        "it_IT": "Il tuo ID di richiesta è '_request_id'.",
        "ja_JP": "リクエストIDは '_request_id' です。",
        "fr_FR": "Votre identifiant de demande est '_request_id'.",
        "fr_CA": "Votre identifiant de demande est '_request_id'.",
        "de_DE": "Ihre Anforderungs-ID lautet '_request_id'."
    },
    bulkSearchSubmittedText:{
        "en_US": "Your search request is submitted. Click on _button_placeholder to download results.",
        "cz_CZ": "Váš vyhledávací požadavek byl odeslán. Klikněte na _button_placeholder pro stažení výsledků.",
        "sk_SK": "Váš vyhľadávací požiadavok bol odoslaný. Kliknite na _button_placeholder pre stiahnutie výsledkov.",
        "es_ES": "Su solicitud de búsqueda ha sido enviada. Haga clic en _button_placeholder para descargar los resultados.",
        "it_IT": "La tua richiesta di ricerca è stata inviata. Clicca su _button_placeholder per scaricare i risultati.",
        "ja_JP": "検索リクエストが送信されました。_button_placeholder をクリックして結果をダウンロードしてください。",
        "fr_FR": "Votre demande de recherche est soumise. Cliquez sur _button_placeholder pour télécharger les résultats.",
        "fr_CA": "Votre demande de recherche est soumise. Cliquez sur _button_placeholder pour télécharger les résultats.",
        "de_DE": "Ihre Suchanfrage wurde übermittelt. Klicken Sie auf _button_placeholder, um die Ergebnisse herunterzuladen."
    },
    'MFN Pickup, No legs found for': {
        'sk_SK': 'MFN Pickup, Nenašli sa žiadne vetvy zapojenia',
        'fr_FR': 'Ramassage MFN, Aucune étape trouvée pour',
        'it_IT': 'MFN Pickup, Nessun dato trovato per',
        'fr_CA': 'Ramassage MFN, Aucune étape trouvée pour',
        'es_ES': 'Carga MFN, no se encontró más etapas',
        'ja_JP': 'データなし',
        'cz_CZ': 'DNV Pickup, Nebyly nalezeny žádné větve zapojení',
        'de_DE': 'MFN Pickup, keine Ebene gefunden für'
    },
    'Container too big to load': {
        'es_ES': 'Contenedor muy grande para cargarlo',
        'fr_FR': 'Contenant trop gros pour être chargé',
        'cz_CZ': 'Parametr je příliš velký na načtení',
        'de_DE': 'Container zu groß',
        'it_IT': 'Container troppo grande per essere caricato.',
        'ja_JP': 'コンテナ情報が大きすぎて読み込めません',
        'sk_SK': 'Parameter je príliš veľký na načítanie',
        'fr_CA': 'Contenant trop gros pour être chargé'
    },
    'Node Id is in invalid': {
        'de_DE': 'Node Id ist falsch',
        'sk_SK': 'ID uzla je neplatné',
        'fr_CA': "L'ID de nœud est invalide",
        'ja_JP': 'Node Idが不正です。',
        'cz_CZ': 'ID uzlu je neplatné',
        'es_ES': 'Node ID es inválido',
        'it_IT': "L'Id del magazzino è invalido",
        'fr_FR': "L'ID de site est invalide"
    },
    'Container Type': {
        'it_IT': 'Tipo di Container',
        'es_ES': 'Tipo de contenedor',
        'fr_FR': 'Type de contanant',
        'ja_JP': 'コンテナタイプ',
        'sk_SK': 'Druh oblasti dát',
        'cz_CZ': 'Druh oblasti dat',
        'de_DE': 'Container Art',
        'fr_CA': 'Type de contenant'
    },
    'Not in COMP': {
        'ja_JP': 'COMPに情報なし',
        'es_ES': 'No en Comp',
        'cz_CZ': 'Není v COMP',
        'de_DE': 'Nicht in Comp',
        'sk_SK': 'Nie je v COMP',
        'it_IT': 'Non in COMP',
        'fr_CA': 'Pas dans le COMP',
        'fr_FR': 'Pas dans  COMP'
    },
    'Too many input items received in short interval, please try again later. ( Allowed 50 id(s) in 60 sec(s). )': {
        'fr_FR': 'Trop d’éléments d’entrée reçus dans un court intervalle, s’il vous plaît essayer à nouveau plus tard. ( Permis 50 id(s) en 60 sec (s). )',
        'fr_CA': 'Trop d’éléments d’entrée reçus dans un court intervalle, s’il vous plaît essayer à nouveau plus tard. ( Permis 50 id(s) en 60 sec (s). )',
        'sk_SK': 'Príliš veľa vstupných položiek prijatých v krátkom intervale, skúste to znova neskôr. (Povolené 50 id za 60 s.)',
        'es_ES': 'Muchos artículos recibidos en un intervalo corto, por favor pruebe de nuevo más tarde (Se permiten 50 ids en 60 segundos)',
        'it_IT': 'Troppi input ricevuti in un intervallo breve, per favore prova più tardi. (Consentiti 50 id(s) in 60 sec(s). )',
        'cz_CZ': 'Příliš mnoho vstupních položek přijatých v krátkém intervalu, zkuste to znovu později. (Povolené 50 id za 60 s.)',
        'ja_JP': '短時間に多くの入力がありました。後でもう一度試してください。(60秒で50 idが目安です)'
    },
    'Link to this search': {
        'sk_SK': 'Odkaz na toto vyhľadávanie',
        'de_DE': 'Link zu dieser Suche',
        'ja_JP': '検索結果へのリンク',
        'fr_CA': 'Lien vers cette recherche',
        'it_IT': 'Link a questa ricerca',
        'cz_CZ': 'Odkaz na toto vyhledávání',
        'fr_FR': 'Lien vers cette recherche',
        'es_ES': 'Link a la búsqueda'
    },
    'Package count (GTS)': {
        'fr_FR': 'Nombre de colis (GTS)',
        'cz_CZ': 'Počet zásilek (GTS)',
        'fr_CA': 'Nombre de colis (GTS)',
        'it_IT': 'Conteggio Pacchi (GTS)',
        'ja_JP': 'パッケージ数 (GTS)',
        'es_ES': 'Conteo de paquetes (GTS)',
        'de_DE': 'Paket Anzahl (GTS)',
        'sk_SK': 'Počet zásielok (GTS)'
    },
    'Is Pallet/Gaylord closed': {
        'it_IT': 'Il Pallet/Gaylord è chiuso',
        'fr_FR': 'Palette/Gaylord est-il fermé',
        'cz_CZ': 'Je Paleta / Gaylord zavřená/ý',
        'sk_SK': 'Je Paleta / Gaylord zatvorená/ý',
        'fr_CA': 'Palette/Gaylord est-il fermé',
        'ja_JP': 'パレットが閉じている',
        'de_DE': 'Pallet/Gaylord geschlossen:',
        'es_ES': 'Está el Pallet/Gaylord cerrado'
    },
    'Shipping Purpose Type': {
        'fr_FR': "Type de raison d'expédition",
        'cz_CZ': 'Typ účelu přepravy',
        'it_IT': 'Tipo di Scopo Spedizione',
        'fr_CA': "Type d'objet de l'expédition",
        'sk_SK': 'Typ účelu prepravy',
        'es_ES': 'Razón de envío',
        'de_DE': 'Versandart',
        'ja_JP': '輸送目的タイプ'
    },
    'Node Id cannot be null while searching stale reservations': {
        'fr_FR': "L' Id de site ne peut pas être nul lors de la recherche de réservations périmées",
        'de_DE': 'Node ID darf nicht null sein während einer Suche',
        'fr_CA': 'Noeud Id ne peut pas être nul lors de la recherche de réserves périmées',
        'cz_CZ': 'ID uzlu nemůže být během vyhledávání prázdné',
        'ja_JP': 'Stale reservationの検索にはNode Idが必要です。',
        'it_IT': "L'Id del magazzino non può essere nullo nella ricerca di vecchie prenotazioni",
        'sk_SK': 'ID uzla nemôže byť počas vyhľadávania prázdné'
    },
    'There are new features being deployed to EagleEye. If you see any data misses, please refresh your page or clear your browser caches. More info here': {
        'sk_SK': 'Do EagleEye sa zavádzajú nové funkcie. Ak zistíte, že niektoré údaje chýbajú, obnovte stránku alebo vymažte vyrovnávaciu pamäť prehliadača. Viac informácií tu',
        'es_ES': 'Hay nuevas funciones siendo activadas en EagleEye. Si encuentras falta de datos, por favor refresca la página o borra los catches de tu navegador. Más información aquí',
        'fr_CA': "De nouvelles fonctionnalités sont déployées dans EagleEye. Si vous constatez qu’il manque des données, veuillez rafraîchir votre page ou effacer les caches de votre navigateur. Plus d'information ici",
        'cz_CZ': 'Do EagleEye se zavádějí nové funkce. Pokud zjistíte, že některé údaje chybí, obnovte stránku nebo vymažte mezipaměť prohlížeče. Více informací zde',
        'ja_JP': 'Eagle Eyeに新規機能が追加されました。データに異常がある場合は更新、もしくはキャッシュのクリアを行なってください。',
        'de_DE': 'Es wurden neuen Features für EagleEye installiert. Aktualisiere die Seite oder bereinige dein Browser Cache, wenn Daten bei deiner Abfrage fehlen . Mehr Informationen hier',
        'it_IT': 'Ci sono nuovi sviluppi rilasciati per EagleEye. Se vedi dati mancanti, per favore effettua il refresh della pagina o pulisci la cache del tuo browser. Più info qui',
        'fr_FR': "De nouvelles fonctionnalités sont déployées dans EagleEye. Si vous constatez qu’il manque des données, veuillez rafraîchir votre page ou effacer les caches de votre navigateur. Plus d'information ici"
    },
    'Manifest details': {
        'cz_CZ': 'Podrobnosti manifestu',
        'es_ES': 'Datos del manifiesto',
        'de_DE': 'Details des Bestimmungsortes',
        'fr_FR': 'Détails du manifeste',
        'it_IT': 'Dettagli del Manifest',
        'sk_SK': 'Podrobnosti manifestu',
        'fr_CA': 'Détails du manifeste',
        'ja_JP': 'マニフェスト詳細'
    },
    'Links': {
        'cz_CZ': 'Odkazy',
        'ja_JP': 'リンク',
        'fr_CA': 'Liens',
        'sk_SK': 'Odkazy',
        'it_IT': 'Links',
        'fr_FR': 'Liens'
    },
    'Transportation Type': {
        'de_DE': 'Transport Art',
        'ja_JP': '輸送タイプ',
        'sk_SK': 'Typ prepravy',
        'es_ES': 'Tipo de transporte',
        'fr_FR': 'Type de transport',
        'it_IT': 'Tipo di Trasporto',
        'fr_CA': 'Type de transport',
        'cz_CZ': 'Typ přepravy'
    },
    'Cannot search more than 50 ids at a time': {
        'es_ES': 'No puedo buscar más de 50 ids a la vez',
        'de_DE': 'No puedo buscar más de 50 ids a la vez',
        'fr_FR': 'Impossible de rechercher plus de 50 ids à la fois',
        'cz_CZ': 'Nelze vyhledávat najednou více než 50 ID',
        'fr_CA': 'Impossible de rechercher plus de 50 ids à la fois',
        'ja_JP': '50件以上のidを一度に検索できません。',
        'sk_SK': 'Nemožno vyhľadávať naraz viac ako 50 ID',
        'it_IT': 'Non è possibile ricercare più di 50 IDs alla volta'
    },
    'Incorrect display provided, allowed values are': {
        'fr_FR': 'Affichage incorrect fourni, les valeurs autorisées sont',
        'de_DE': 'Falsche Darstellung bereitgestellt, erlaubte Werte sind:',
        'es_ES': 'La visualización elegida es incorrecta, los valores permitidos son',
        'it_IT': 'Fornito display non corretto, i valori consentiti sono',
        'ja_JP': '誤ったフォーマットです。適切な値は以下です。',
        'cz_CZ': 'Poskytnuté nesprávné zobrazení, povolené hodnoty jsou',
        'sk_SK': 'Poskytnuté nesprávne zobrazenie, povolené hodnoty sú',
        'fr_CA': 'Affichage incorrect fourni, les valeurs autorisées sont'
    },
    'Location': {
        'it_IT': 'Luogo',
        'de_DE': 'Ort',
        'sk_SK': 'Umiestnenie',
        'cz_CZ': 'Umístění',
        'es_ES': 'Localización',
        'ja_JP': '場所',
        'fr_FR': 'Emplacement',
        'fr_CA': 'Emplacement'
    },
    'Unhandled Exception': {
        'es_ES': 'Excepción no manejada',
        'cz_CZ': 'Nezpracovaná výjimka',
        'it_IT': 'Eccezione Non Gestita',
        'ja_JP': '処理できない例外です',
        'sk_SK': 'Nespracovaná výnimka',
        'fr_FR': 'Exception non traitée',
        'fr_CA': 'Exception non traitée'
    },
    'Either type OR value is missing in URL which are mandatory, please update URL and try again. Sample url search params: ?type=Shipment&value=2513391256919&display=leg&node=ABE2': {
        'es_ES': 'En la URL falta o el valor o el tipo que son obligatorios. Por favor actualize la URL y pruebe de nuevo. Ejemplo de parámetros de búsqueda URL: ?type=Shipment&value=2513391256919&display=leg&node=ABE2',
        'fr_FR': 'L’une ou l’autre valeur de type OU est manquante dans l’URL qui sont obligatoires, s’il vous plaît mettre à jour l’URL et essayer à nouveau.Exemple de paramètres de recherche d’URL : ?type=Shipment&value=2513391256919&display=leg&node=ABE2',
        'cz_CZ': 'V adrese URL chybí povinný typ NEBO hodnota, aktualizujte adresu URL a zkuste to znovu. Vzorové parametry vyhledávání adresy:? Type=Shipment&value=2513391256919&display=leg&node=ABE2',
        'fr_CA': 'L’une ou l’autre valeur de type OU est manquante dans l’URL qui sont obligatoires, s’il vous plaît mettre à jour l’URL et essayer à nouveau.Exemple de params de recherche d’URL : ?type=Shipment&value=2513391256919&display=leg&node=ABE2',
        'it_IT': "Tipo O valore sono mancati nella URL e sono obbligatori, per favore aggiorna la URL e prova ancora. Esempio di parametri dell'URL di ricerca: ?type=Shipment&value=2513391256919&display=leg&node=FCO1",
        'ja_JP': 'URLから必要なタイプ、もしくは値が抜けています。URLを修正してください。URLパラメータ例：?type=Shipment&value=2513391256919&display=leg&node=ABE2',
        'sk_SK': 'V adrese URL chýba povinný typ ALEBO hodnota, aktualizujte adresu URL a skúste to znova. Vzorové parametre vyhľadávania adresy: ?type=Shipment&value=2513391256919&display=leg&node=ABE2'
    },
    'Incorrect type provided': {
        'cz_CZ': 'Byl zadán nesprávný typ',
        'fr_CA': 'Type incorrect fourni',
        'fr_FR': 'Type incorrect fourni',
        'de_DE': 'Falscher Typ eingefügt.',
        'es_ES': 'El tipo proporcionado es incorrecto',
        'it_IT': 'Fornito tipo non corretto',
        'sk_SK': 'Bol zadaný nesprávny typ',
        'ja_JP': '不正なタイプが指定さています'
    },
    'Export CSV': {
        'ja_JP': 'CSVでエクスポート',
        'cz_CZ': 'Exportovat soubor CSV',
        'fr_FR': 'Exportation CSV',
        'fr_CA': 'Exportation CSV',
        'it_IT': 'Esporta CSV',
        'es_ES': 'Exportar CSV',
        'sk_SK': 'Exportovať súbor CSV'
    },
    'Node Id cannot be null while searching': {
        'es_ES': 'El Node ID no puede ser cero en la búsqueda',
        'fr_CA': "L'ID de nœud ne peut pas être nul pendant la recherche",
        'ja_JP': '検索にはNode Idが必要です',
        'cz_CZ': 'ID uzlu nemůže být během vyhledávání nulové',
        'fr_FR': "L'ID de site ne peut pas être nul pendant la recherche",
        'de_DE': 'Node ID darf nicht null sein während einer Suche',
        'it_IT': "L'Id del magazzino non può essere nullo all'interno della ricerca",
        'sk_SK': 'ID uzla nemôže byť počas vyhľadávania nulové'
    },
    'Trailer state (GTS)': {
        'it_IT': 'Stato del Trailer (GTS)',
        'sk_SK': 'Stav prívesu (GTS)',
        'cz_CZ': 'Stav přívěsu (GTS)',
        'ja_JP': 'トレイラーステータス (GTS)',
        'fr_FR': 'État de la remorque (GTS)',
        'fr_CA': 'État de la remorque (GTS)',
        'es_ES': 'Estado del trailer (GTS)',
        'de_DE': 'TrailerZustand (GTS)'
    },
    'No data found for': {
        'fr_FR': "Aucune donnée n'a été trouvée pour",
        'cz_CZ': 'Nebyly nalezeny žádné údaje pro',
        'it_IT': 'Nessun dato trovato per',
        'de_DE': 'Keine Daten verfügbar für',
        'fr_CA': "Aucune donnée n'a été trouvée pour",
        'sk_SK': 'Nenašli sa žiadne údaje pre',
        'es_ES': 'No se encontró datos para',
        'ja_JP': 'データなし'
    },
    'Node Id cannot be null while searching Trailers': {
        'fr_FR': "L' Id de site ne peut pas être nul lors de la recherche de remorques",
        'it_IT': "L'Id del magazzino non può essere nullo quando si ricerca un Trailer",
        'de_DE': 'Bei der Suche nach Trailern darf die Node ID nicht null sein',
        'sk_SK': 'ID uzla nemôže byť počas vyhľadávania prívesov prázdné',
        'es_ES': 'El Node ID no puede ser cero en la búsqueda de trailers',
        'ja_JP': 'トレイラー検索にはNode Idが必要です。',
        'cz_CZ': 'ID uzlu nemůže být během vyhledávání přívěsů prázdné',
        'fr_CA': 'Noeud Id ne peut pas être nul lors de la recherche Trailers'
    },
    'No inputs provided': {
        'es_ES': 'No se ha introducido información',
        'fr_CA': 'Aucun apport fourni',
        'it_IT': 'Nessun input fornito',
        'de_DE': 'Keine Daten eingefügt.',
        'sk_SK': 'Neboli poskytnuté žiadne vstupy',
        'fr_FR': 'Aucun apport fourni',
        'cz_CZ': 'Nebyly poskytnuty žádné vstupy',
        'ja_JP': '入力がありませんでした。'
    },
    'Leg Level': {
        'de_DE': 'Ebene',
        'es_ES': 'Nivel de etapa',
        'fr_CA': 'Niveau de l’étape',
        'ja_JP': '経由地別',
        'fr_FR': 'Niveau de l’étape'
    },
    'Status (SSP)': {
        'ja_JP': 'ステータス (SSP)',
        'sk_SK': 'Stav (SSP)',
        'cz_CZ': 'Stav (SSP)',
        'es_ES': 'Estado (SSP)',
        'it_IT': 'Stato (SSP)',
        'fr_FR': 'État (SSP)',
        'fr_CA': 'État (SSP)'
    },
    'Due to regulatory reasons, items details will not be visible by default and would require an additional click of Get Items button. Please do not cut us a ticket for this.': {
        'it_IT': 'A causa di motivazioni legali, i dettagli degli oggetti non saranno visibili per default e richiederanno un click addizionale sul pulsante Ottieni Oggetti. Per favore, non aprire un ticket per questo.',
        'sk_SK': 'Z regulačných dôvodov sa podrobnosti o položkách v predvolenom nastavení nebudú zobrazovať a bude sa vyžadovať ďalšie kliknutie na tlačidlo Získať položky. Za to nám prosím neposielajte Trouble Ticket',
        'fr_FR': 'Pour des raisons réglementaires, les détails des articles ne seront pas visibles par défaut et nécessiteraient un clic supplémentaire sur le bouton « Obtenir les articles ». Veuillez ne pas créer de ticket pour cela.',
        'fr_CA': 'Pour des raisons réglementaires, les détails des articles ne seront pas visibles par défaut et nécessiteraient un clic supplémentaire sur le bouton «\xa0Obtenir les articles\xa0». Veuillez ne pas créer de billet pour cela.',
        'ja_JP': 'アイテム情報はデフォルトでは表示されません。アイテム情報の取得には取得ボタンのクリックが必要です。この件でチケットを切らないでください。',
        'es_ES': 'Debido a razones normativas, los detalles de los artículos no serán visibles por defecto y requerirán un click adicional en el botón Obtener Artículos. Por favor no crear un ticket por esto.',
        'cz_CZ': 'Z regulačních důvodů se podrobnosti o položkách ve výchozím nastavení nebudou zobrazovat a bude se vyžadovat další kliknutí na tlačítko Získat položky. Za to nám prosím neposílejte Trouble Ticket'
    },
    'Sample url search params': {
        'es_ES': 'Ejemplo de parámetros de búsqueda URL',
        'ja_JP': 'URL検索パラメータ例',
        'fr_FR': "Exemple de paramètres de recherche d'URL",
        'fr_CA': "Exemple de paramètres de recherche d'URL",
        'it_IT': 'Esempio url di ricerca parametri',
        'sk_SK': 'Vzorové parametre vyhľadávania adresy URL',
        'cz_CZ': 'Vzorové parametry vyhledávání adresy URL'
    },
    'Has Departed': {
        'fr_FR': 'Parti',
        'es_ES': 'Ha salido',
        'cz_CZ': 'Odešel',
        'it_IT': "E' Partito",
        'fr_CA': 'Parti',
        'sk_SK': 'Odišiel',
        'ja_JP': '出発済み',
        'de_DE': 'Abfahrt'
    },
    'Operation timed out. Can be due to any of the below reasons.': {
        'sk_SK': 'Časový limit operácie vypršal. Dôvodom môže byť ktorýkoľvek z nasledujúcich dôvodov.',
        'it_IT': "L'operazione è andata in time out. Questo può essere dovuto a una delle ragioni sotto.",
        'es_ES': 'La operación ha expirado. Esto puede ser por alguna de las razones de abajo.',
        'fr_CA': "L'opération a expiré. Cela peut être dû à l'une ou l'autre des raisons suivantes.",
        'fr_FR': "L'opération a expiré. Cela peut être dû à l'une ou l'autre des raisons suivantes.",
        'ja_JP': '以下のいずれかの原因でタイムアウトしました。',
        'cz_CZ': 'Časový limit operace vypršel. Důvodem může být kterýkoli z následujících důvodů.',
        'de_DE': 'Operation abgebrochen. Dies geschieht aufgrund folgender Gründe:'
    },
    'Multiple Container search is not allowed. Try with one container at a time.': {
        'ja_JP': '複数のコンテナ検索はできません。1つづつ試してください。',
        'de_DE': 'Mehrfache Container Suche ist nicht zulässig. Versuche deine Suche bitte mit einem Container.',
        'sk_SK': 'Vyhľadávanie viacerých oblastí dát nie je povolené. Vyskúšajte vždy jednu dátovú oblasť.',
        'fr_CA': 'La recherche multiple de conteneurs n’est pas autorisée. Essayez avec un conteneur à la fois.',
        'fr_FR': "La recherche de contenants multiples n'est pas autorisée. Essayez avec un contenant à la fois.",
        'it_IT': 'La ricerca di più container non è consentita. Prova con un container alla volta.',
        'cz_CZ': 'Vyhledávání více oblastí dat není povoleno. Vyzkoušejte vždy jednu datovou oblast.',
        'es_ES': 'La búsqueda de contenedores múltiples no está permitida. Intente un solo contendor a la vez'
    },
    'Comp Status': {
        'fr_CA': 'État de Comp',
        'de_DE': 'Stauts der Comp',
        'cz_CZ': 'stav COMPu',
        'sk_SK': 'Stav COMPu',
        'ja_JP': 'COMPステータス',
        'es_ES': 'Status del Comp',
        'it_IT': 'Status di Compo',
        'fr_FR': 'Statut dans Comp'
    },
    'Incorrect type provided, allowed types are:': {
        'sk_SK': 'Poskytnutý nesprávny typ, povolené typy sú:',
        'de_DE': 'Falscher Typ bereitgestellt, erlaubte Typen sind:',
        'cz_CZ': 'Poskytnutý nesprávný typ, povolené typy jsou:',
        'es_ES': 'El tipo elegido es incorrecto, los tipos permitidos son',
        'ja_JP': '誤ったタイプです。適切なタイプは以下です。',
        'it_IT': 'Fornito tipo non corretto, i valori consentiti sono',
        'fr_CA': 'Type incorrect fourni, les types autorisés sont\xa0:',
        'fr_FR': 'Type incorrect fourni, les types autorisés sont :'
    },
    'TT Tool Link': {
        'fr_CA': "Lien de l'outil TT",
        'ja_JP': 'TTツールリンク',
        'es_ES': 'TT Link',
        'fr_FR': "Lien de l'outil TT",
        'sk_SK': 'Odkaz na IT aplikáciu',
        'it_IT': 'TT Tool Link',
        'cz_CZ': 'Odkaz na IT aplikaci'
    },
    'Multiple Resource search is not allowed. Try with one Resource at a time.': {
        'sk_SK': 'Vyhľadávanie viacerých zdrojov nie je povolené. Vyskúšajte vždy jeden zdroj.',
        'fr_FR': 'La recherche multiple de ressources n’est pas autorisée. Essayez avec une ressource à la fois.',
        'ja_JP': '複数のリソース検索はできません。1つずつ検索してください。',
        'es_ES': 'La búsqueda de recursos múltiples no está permitida. Intenta un solo contenedor a la vez',
        'cz_CZ': 'Vyhledávání více zdrojů není povoleno. Vyzkoušejte vždy jeden zdroj.',
        'it_IT': 'La ricerca di più Risorse non è consentita. Prova con una Risorsa alla volta.',
        'fr_CA': 'La recherche multiple de ressources n’est pas autorisée. Essayez avec une ressource à la fois.'
    },
    'Table': {
        'ja_JP': 'テーブル',
        'de_DE': 'Tabelle',
        'cz_CZ': 'Tabulka',
        'fr_CA': 'Tableau',
        'es_ES': 'Tabla',
        'sk_SK': 'Tabuľka',
        'it_IT': 'Tabella',
        'fr_FR': 'Tableau'
    },
    'ESMM Info': {
        'it_IT': 'Info ESMM',
        'fr_CA': 'Info ESMM',
        'sk_SK': 'Informácie o ESMM',
        'fr_FR': 'Info ESMM',
        'es_ES': 'Info ESMM',
        'ja_JP': 'ESMM情報',
        'cz_CZ': 'Informace o ESMM'
    },
    'Package Status': {
        'de_DE': 'Paket Status',
        'fr_CA': 'État du colis',
        'ja_JP': 'パッケージステータス',
        'cz_CZ': 'Stav zásilky',
        'sk_SK': 'Stav zásielky',
        'fr_FR': 'État du colis',
        'es_ES': 'Estado del paquete',
        'it_IT': 'Stato del pacco'
    },
    'Package count (SSP)': {
        'fr_FR': 'Nombre de colis (SSP)',
        'it_IT': 'Conteggio Pacchi (SSP)',
        'cz_CZ': 'Počet zásilek (SSP)',
        'fr_CA': 'Nombre de colis (SSP)',
        'sk_SK': 'Počet zásielok (SSP)',
        'ja_JP': 'パッケージ数 (SSP)',
        'de_DE': 'Paket Anzahl (SSP)',
        'es_ES': 'Conteo de paquetes (SSP)'
    },
    'Leg level': {
        'sk_SK': 'Úroveň vetvy zapojenia',
        'cz_CZ': 'Úroveň větve zapojení',
        'it_IT': 'Dati Estesi'
    },
    'Node Id is invalid': {
        'ja_JP': 'Node Idが不正です。'
    },
    'ViewInspections': {
        'Access': 'amzn1.bindle.resource.gktzqsiksldsbdazathq'
    },
    'Departure time (GTS)': {
        'fr_CA': 'Heure de départ (GTS)'
    },
    'Table display is provided only when a nodeId is passed': {
        'es_ES': 'La tabla se muestra solo cuando un NodeId ha pasado',
        'it_IT': 'La visualizzazione della tabella è fornita solo quando viene fornito un Id magazzino',
        'fr_CA': 'L’affichage de la table n’est fourni que lorsqu’un nœudy est passé',
        'ja_JP': 'テーブル表示にはNode Idが必要です。',
        'fr_FR': 'L’affichage de la table n’est fourni que lorsqu’un site y est passé',
        'sk_SK': 'Zobrazenie tabuľky je k dispozícii iba vtedy, keď prešlo ID uzla',
        'cz_CZ': 'Zobrazení tabulky je k dispozici pouze tehdy, když prošlo ID uzlu'
    },
    'Get Items': {
        'it_IT': 'Ottieni Oggetti',
        'es_ES': 'Obtener artículos',
        'sk_SK': 'Získajte položky',
        'fr_FR': 'Obtenir les articles',
        'cz_CZ': 'Získejte položky',
        'ja_JP': 'アイテム情報を取得',
        'fr_CA': 'Obtenir les articles'
    },
    'ClearC13Shipments': {
        'Access': 'amzn1.bindle.resource.bk7urlmeswutvumgm2va'
    },
    'Departure time': {
        'fr_CA': 'Heure de départ'
    },
    'Has Hazmat?': {
        'es_ES': 'Contiene artículos Hazmat?',
        'cz_CZ': 'Má Hazmat?',
        'de_DE': 'Ist Hazmat enthalten?',
        'sk_SK': 'Má Hazmat?',
        'it_IT': 'Contiene articoli Hazmat?',
        'ja_JP': 'Hazmatを含む',
        'fr_CA': 'Contient des marchandises dangereuses?',
        'fr_FR': 'Contient des marchandises dangereuses?'
    },
    'Has Arrived': {
        'sk_SK': 'Dorazil',
        'fr_FR': 'Arrivé',
        'fr_CA': 'Arrivé',
        'ja_JP': '到着済み',
        'cz_CZ': 'Dorazil',
        'it_IT': "E' Arrivato",
        'de_DE': 'Ankunft',
        'es_ES': 'Ha llegado'
    },
    'Either type OR value is missing in URL which are mandatory, please update URL and try again': {
        'de_DE': 'Sowohl der Typ als auch der Wert sind in der URL notwending. Bitte update die URL und versuche es nochmal ',
        'fr_CA': "Il manque le type OU la valeur dans l'URL et ceux-ci sont obligatoires; veuillez mettre à jour l'URL et réessayer",
        'ja_JP': 'URLから必要なタイプ、もしくは値が抜けています。URLを修正してください。',
        'es_ES': 'En la URL falta o el valor o el tipo que son obligatorios. Por favor actualize la URL y pruebe de nuevo',
        'sk_SK': 'V adrese URL chýba povinný typ ALEBO hodnota, aktualizujte adresu URL a skúste to znova',
        'cz_CZ': 'V adrese URL chybí povinný typ NEBO hodnota, aktualizujte adresu URL a zkuste to znovu',
        'it_IT': 'Tipo O valore sono mancati nella URL e sono obbligatori, per favore aggiorna la URL e prova ancora'
    },
    'FORMAT': {
        'sk_SK': 'Formát',
        'it_IT': 'formato',
        'cz_CZ': 'Formát'
    },
    'BAG': {
        'fr_CA': 'Sac'
    },
    'SSP Hierarchy': {
        'fr_FR': 'Hiérarchie SSP',
        'es_ES': 'Jerarquía SSP',
        'fr_CA': 'Hiérarchie du SPP',
        'it_IT': 'Gerarchia SSP',
        'sk_SK': 'Hierarchia SSP',
        'ja_JP': 'SSPデータ階層',
        'cz_CZ': 'Hierarchie SSP'
    },
    'Error': {
        'it_IT': 'Errore',
        'fr_FR': 'Erreur',
        'ja_JP': 'エラー',
        'cz_CZ': 'Chyba',
        'fr_CA': 'Erreur',
        'sk_SK': 'Chyba',
        'de_DE': 'Fehler'
    },
    'Arrival time': {
        'fr_CA': "Heure d'arrivée"
    },
    'format': {
        'es_ES': 'formato',
        'fr_CA': 'format',
        'ja_JP': 'フォーマット'
    },
    'Business Type': {
        'fr_CA': 'Type de business'
    },
    'Arrival time (GTS)': {
        'fr_CA': "Heure d'arrivée (GTS)"
    },
    'Carrier': {
        'fr_CA': 'transporteur'
    },
    'TRAILER ID': {
        'fr_CA': 'Camion remorque ID'
    },
    'SHIPMENT ID': {
        'fr_CA': "ID d'expédition"
    },
    'MANIFEST ID': {
        'fr_CA': 'Manifeste ID'
    }
}

export const getDataForRegion = (data : string) => {
    let lang = getLanguageFromUrl()
    return (data in TranslationsConst) ? ((lang in TranslationsConst[data]) ? TranslationsConst[data][lang] : data) : data
}