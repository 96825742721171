import {NavTabType} from "src/components/helpers/navtab-util/navTab.util.type" ;

export const VisibilityNavTabItems: Array<NavTabType> = [
    {
        label: "Home",
        path: "/search",
        ref:"/afn"
    },
    {
        label: "MFN",
        path: "/mfn",
        ref:"/mfn"
        
    },
    {
        label: "OFIS",
        path: "/ofis",
        ref:"/ofis"
    },
    {
        label: "PreSlam",
        path: "/preslam",
        ref:"/preslam"

    }
    
];