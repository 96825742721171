import decode from 'jwt-decode'
import { getItemFromCache, getValidRegion, setItemInCache, toQueryString } from 'src/utils/utils'

export const TOKEN_KEY = 'amzn-cognito-token:eagle-eye'
const REDIRECT_PATH = "redirect-path"
const BETA_COGNITO_DOMAIN = 'https://eagle-eye-beta.auth.us-east-1.amazoncognito.com'
const BETA_COGNITO_CLIENTID ='6hgr9l903iqp0dnqbv95dhhqk5'

export const getToken = () => {
	var authToken: any = getItemFromCache(TOKEN_KEY)
	if (authToken != null) {
        // exp from token is in seconds, but Date object uses milliseconds, so we need to convert
		let decodedToken = getDecodedToken(authToken.token)
        if (decodedToken.exp * 1000 > Date.now()) {
            return authToken.token
        } else {
            // TODO: Need to check if we can silently refresh the token using fetch/axios on token expiry instead of redirecting
            const authenticatedUserEvent = new CustomEvent("cathode-customerId", {
                detail: {
                    customerId: decodedToken?.identities[0].userId,
                },
            });
            document.dispatchEvent(authenticatedUserEvent);
            cognitoAuthenticate(true)
        }
	} else {
		return null
	}
}

export const getEmployeeAlias = (): string => getDecodedToken(getToken())?.identities[0].userId

const setToken = (token: string) => {
    const decodedToken: Record<string, any> = decode(token)
    if (!decodedToken) throw new Error('Token could not be decoded')
    setItemInCache(TOKEN_KEY, { token: token })
}

const buildSSOUrl = () => {
    var cognitoDomain ='https://eagle-eye-prod.auth.us-east-1.amazoncognito.com';
    var clientId ='hio1oj7a8tn83eqk8nl9ptp70'

    if (getValidRegion() == "CN"){
        // Currentyl PROD CN lambda stack is in Beta account , for CN region use beta cognito auth
        cognitoDomain = BETA_COGNITO_DOMAIN
        clientId = BETA_COGNITO_CLIENTID
    }
    const queryParams = {
        client_id: clientId,
        response_type: 'token',
        scope: 'openid',
        redirect_uri: 'https://eagleeye.amazon.dev',
    }
    return `${cognitoDomain}/authorize?${toQueryString(queryParams)}`
}

const redirectToAuthUrl = () => {
    setItemInCache(REDIRECT_PATH, document.location.href)
    window.location.replace(buildSSOUrl())
}

export const getDecodedToken = (token: string) => {
    const decodedToken: Record<string, any> = decode(token)
    return decodedToken
}

export const cognitoAuthenticate = (fetchNewToken?: boolean): any  => {
    // try lookup from url
    const tokenParser = /id_token=([a-zA-Z0-9_\-.]+)/
    const isTokenInUrl = () => tokenParser.test(window.location.hash)
    const getTokenParams = () => tokenParser.exec(window.location.hash) 
    if (isTokenInUrl()) {
        const [, token, redirect] = getTokenParams()!
        const decodedToken = getDecodedToken(token)
        if (!decodedToken) {
            redirectToAuthUrl()
            return false
        }
        setToken(token)
        // These lines will ensure to redirect the user back the page they opened before Authentication
        const redirectPath: string = getItemFromCache(REDIRECT_PATH)
        window.location.replace(redirectPath)
        return true
    }
    // Not calling getToken again if getToken is calling authenticate to get a new token since the old one had expired
    if (!fetchNewToken) {
        const cachedToken = getToken()
        const decodedToken = cachedToken && getDecodedToken(cachedToken)
        if (decodedToken) return true
    }
   
    // redirect to auth
    redirectToAuthUrl()
    return false
}