import React, { memo, useState, useEffect } from "react";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import { XORRedriveFileUploadUtilData, trackingIdData, xorRedriveHeaderData, xorRediveType } from "src/common/constants/xor-redrive.const";
import './xor-redrive.component.scss';
import { PageHeaderUtility } from "src/components/helpers/headers.util/headers.util";
import { useAuthContext } from "src/context/AuthenticationContext";
import { useSearchParams } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import { xorRedriveApi } from "src/common/api/xor-redrive-api";
import { xorRedriveResponseType, xorRedrivePayloadType, xorInputFieldsType } from "src/common/dao/xor-redrive/xor-redrive-dao-type";
import Theme from "@amzn/meridian/theme";
import Link from "@amzn/meridian/link";
import Column from "@amzn/meridian/column";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import SolutionsNabTabComponent from "src/pages/solutions-page/components/solutions-navtab/solutions-navTab.component"
import { InputFieldUtil } from "src/components/helpers/input-field-util/input-field-util";
import { RadioButtonInlineFormatUtil } from "src/components/helpers/radio-button.util/radio-button.util";
import Icon from "@amzn/meridian/icon"
import infoTokens from "@amzn/meridian-tokens/base/icon/info"
import Loader from "@amzn/meridian/loader";

const XorRedriveComponent = () => {
    const initalXorRedriveState: xorInputFieldsType = {
        trackingId: "",
        stateLoadedwithParams: false,
        isLoading: false,
        hasError: false,
        encodedFileData: "",
        response: { requestId: "", successful: false, errorMessage: "", retryable: "", uploaded: false },
        showResultsInfo: false,
        redriveType: "single"
    }

    let userAlias = useAuthContext().userAlias;
    const [encodedFileData, setFileData] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [xorRedriveMissingFields, setXorRedriveMissingFields] = useState<Record<string, string>>({});
    const [files, setFiles] = useState<Array<{ name: string; size: number | string; }>>([])
    const [xorRedriveState, setXorRedriveState] = useState(initalXorRedriveState)
    const updateXorRedriveState = async (field: string, value: any) => setXorRedriveState({ ...xorRedriveState, [field]: value })



    const onFileAttached = (files: any) => {
        if (files) {
            let result: string | ArrayBuffer | null;
            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                result = reader.result;
                if (typeof result === 'string') {
                    result = result.split(',')[1];
                    setFileData(result)
                }
            };
        }
        setFiles(files);

    };

    const handleXORRedrive = async () => {
        if (handleXORError())
            return
        let payload: xorRedrivePayloadType = {
            requestId: uuid(),
            requesterName: userAlias,
            isBulkRedrive: false,
            xorRedriveStage: "Prod",
            xorRedriveRegion: searchParams.get("region")!
        }
        if (xorRedriveState.trackingId != "") {
            payload.trackingId = xorRedriveState.trackingId;
        }

        else if (files) {
            payload.isBulkRedrive = true;
            payload.fileContentEncoded = encodedFileData;
        }
        setXorRedriveState({ ...xorRedriveState, isLoading: true, showResultsInfo:false , response: { requestId: "", successful: false, errorMessage: "", retryable: "" }})
        xorRedriveApi(payload).then((response) => {
            setXorRedriveState({
                ...xorRedriveState,
                response: response.XOR,
                showResultsInfo: true
            });

        })
    }
    const handleXORError = () => {
        //Both tracking id and file should not be empty
        setXorRedriveMissingFields({})
        setXorRedriveState({ ...xorRedriveState, showResultsInfo: false })
        if (xorRedriveState.trackingId.length == 0 && files.length == 0) {
            setXorRedriveMissingFields({ ...xorRedriveMissingFields, "trackingId": "Please enter Tracking Id", "redriveFile": "Please upload a file" })
            return true;
        }
        else if (xorRedriveState.trackingId.length > 0 && files.length > 0) {
            setXorRedriveMissingFields({ ...xorRedriveMissingFields, "trackingId": "Both tracking id and file has been uploaded", "redriveFile": "Both tracking id and file has been uploaded" })
            return true;
        }
        return false;
    }
    return (
        <React.Fragment>
            <PageHeaderUtility pageTitle={xorRedriveHeaderData.pageTitle} myKey={xorRedriveHeaderData.key} />
            <Column alignmentHorizontal="center" spacing="none">
                <SolutionsNabTabComponent tab="XOR Redrive" />
                <Box className="xor-redrive-input-container" width={"60%"} type="outline" spacingInset="400">
                    <Row alignmentVertical="center" alignmentHorizontal="center" spacingInset="300 none 300 none">
                        <RadioButtonInlineFormatUtil radioButtonData={xorRediveType}
                            customisedStyle={{ display: "grid", gridGap: "55px", gridTemplateColumns: "0fr 2fr 2fr" }}
                            value={xorRedriveState.redriveType}
                            setValue={(value: string) => {
                                setXorRedriveState({
                                    ...xorRedriveState,
                                    redriveType: value
                                })
                            }}

                        />
                    </Row>
                    {xorRedriveState.redriveType === "single" ?
                        <Row alignmentVertical="center" alignmentHorizontal="center" spacingInset="300 none 300 none" >
                            <InputFieldUtil
                                inputFieldData={trackingIdData}
                                value={xorRedriveState.trackingId}
                                setValue={(value: string) => {
                                    updateXorRedriveState("trackingId", value)
                                    setXorRedriveMissingFields({})
                                }}
                                onBlur={() => { }}
                                mask={""}
                                error={xorRedriveMissingFields.hasOwnProperty('trackingId')}
                                errorMessage={xorRedriveMissingFields['trackingId']}
                            />

                        </Row>
                        :
                        <Box spacingInset={"100"}>
                            <Row alignmentHorizontal="center">
                                <Text>Choose file for bulk re-drives</Text>
                            </Row>
                            <Row alignmentVertical="center" alignmentHorizontal="center" spacingInset="300 none 300 none" spacing="500">
                                <div>
                                    <FileInput
                                        onFileAttached={(file) => {
                                            onFileAttached(file)
                                            setXorRedriveMissingFields({})
                                        }}
                                        type="single"
                                        uploadButtonSize="small"
                                    >
                                    </FileInput>
                                    {files.map(file => {
                                        return (
                                            <FileDetails
                                                file={file}
                                                key={file.name}
                                                removeFileButtonLabel={`Delete, ${file.name}`}
                                                onClickRemoveFile={() => {
                                                    const updatedFileArr = files.filter(fl => fl.name !== file.name)
                                                    setFiles(updatedFileArr)
                                                }}
                                                uploadComplete={true}
                                            />
                                        )
                                    })}
                                </div>
                                <div>
                                    <Link href={XORRedriveFileUploadUtilData.href}
                                        download={XORRedriveFileUploadUtilData.downloadedFileName}> <Icon tokens={infoTokens} /> {XORRedriveFileUploadUtilData.linkText}</Link>
                                </div>
                            </Row>
                        </Box>
                    }
                    <Row alignmentHorizontal="center" alignmentVertical="center" spacingInset="300 none 300 none">
                        <Button onClick={() => {
                            handleXORRedrive()
                        }} size="medium" type="primary">
                            {xorRedriveState.isLoading ?
                                <> Loading <pre /> <Loader type="circular" size="small" /> </> :
                                "XOR Redrive"
                            }
                        </Button>
                    </Row>
                </Box>
            </Column>

            {xorRedriveState.showResultsInfo ?
                <Row alignmentHorizontal="center">
                    {xorRedriveState.response?.successful || xorRedriveState.response?.uploaded ?
                        <Box type="outline" spacingInset={"300"}>
                            <Theme tokens={{ boxBackgroundColorOutline: '#77c57f' }}>
                                <Box type="outline" spacingInset="200">
                                    <Text type="h100" alignment="center">
                                        Xor Redrive works successful !
                                    </Text>
                                </Box>
                            </Theme>
                        </Box>
                        :
                        <Box type="outline" spacingInset={"300"}>
                            <Theme tokens={{ boxBackgroundColorOutline: '#feb96c' }}>
                                <Box type="outline" spacingInset="200">
                                    {xorRedriveState.response?.errorMessage ?
                                        <Text type="h100" alignment="center">
                                            {xorRedriveState.response?.errorMessage}
                                        </Text>
                                        :
                                        <Text type="h100" alignment="center" >
                                            Xor Redrive Failed
                                            {xorRedriveState.response?.errorMessage}
                                        </Text>
                                    }
                                </Box>
                            </Theme>
                        </Box>
                    }
                </Row>
                : <></>

            }

        </React.Fragment>
    )
}

export default memo(XorRedriveComponent)