import { realmToRegionMap } from "src/components/helpers/tool-links-renderer-util/tool-links-renderer.config";
import {
    AfnNodeDataType,
    afnNodeTableDataType,
    AfnPackageResponseType, AfnToolLinkDataType,
    AfnTrailerAndVridInfoType,
    EnrichedTrackingIdInfoDataType,
    ESMMDataType, GeneralObjectType, ManifestInfoType, PackageDetailsAttributesListType, PackageErrorType, PackageLegDetailsKeys,
    ResourceIdKeys, ScannableKeysTableFormat,
    TrailerIdKeys,
    AfnTrailerAndVrIdResponseType
} from "src/common/dao/afn/afn-dao.type";
import { DetailsUtilType } from "src/components/helpers/details-renderer-util/details-renderer-util.type";
import { afnInputFieldsType, AfnResponseType, AfnResponseErrorObject } from "src/pages/visibility-page/components/afn/afn.component.type";
import { CustomError, infoDataType, styleKeys } from "src/common/dao/mfn/mfn-dao.type";
import { StackingFilterValue, TableColumns, TableData } from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import { translateStrings, translateStringsForRecord, generateTableData, generateTableHeaders, formatTime } from "src/utils/dao-utils";
import { getRealmFromUrl } from "src/utils/utils";
import { countryCodeToTopLevelDomainMapping } from "src/pages/visibility-page/components/afn/afn.config";
import { HIDABLE_ATTR_PREFIX } from "src/components/helpers/table-renderer-util/table-renderer.config";

const formatManifestData = (manifestData: any) => {
    const result: string[] = [];

    for (const manifestNumber in manifestData) {
        const documentTypeArr = manifestData[manifestNumber].documentType;
        const documentType = Array.isArray(documentTypeArr) && documentTypeArr.length > 0 ? documentTypeArr[0] : "";
        result.push(`${manifestNumber}(${documentType})`);
    }
    return result.join("\n");
}
const populateResults = (result: Array<Record<ScannableKeysTableFormat, infoDataType>>
    , currentLegInfo: any, hierarchyDetails: any, currPack: any, afnForm: afnInputFieldsType, i: number) => {

    result.push(translateStrings({
        inputIdentifier: { label: HIDABLE_ATTR_PREFIX + "inputIdentifier", value: currPack.inputIdentifier, type: 'text', width: 200, addCopyIcon: true },
        trackingId: { label: "Tracking Id", value: currPack.trackingId, type: 'text', width: 200, addCopyIcon: true },
        enrichedTrackingIdInfo: { label: HIDABLE_ATTR_PREFIX + "enrichedTrackingIdInfo", value: currPack.enrichedTrackingIdInfo ?? "---", type: 'text', width: 200, addCopyIcon: true },
        scannableId: { label: "Sp00 Id", value: currPack.scannableId, type: 'text', width: 200, addCopyIcon: true },
        label: { label: "Encrypted Shipment Id", value: currPack.label, type: 'text', width: 200, addCopyIcon: true },
        orderingShipmentId: { label: "Shipment Id", value: currPack.orderingShipmentId, type: 'text', width: 200, addCopyIcon: true },
        orderingOrderId: { label: "Order Id", value: currPack.orderingOrderId, type: 'text', width: 200, addCopyIcon: true },
        tcdaId: { label: "tcda Id", value: currPack.tcdaId, type: 'text', width: 200, addCopyIcon: true },
        trmStatus: { label: "Package Status", value: currPack.trmStatus, type: 'text', width: 200 },
        package_count: { label: "Package Count", value: currPack.packageCount, isVisible: currPack.packageCount != undefined, type: 'text', width: 200 },
        enrichedLegInfo: { label: "enrichedLegInfo", value: currPack.enrichedLegInfo, type: 'text', width: 200 },
        // legs :{ label: "Legs", value: currPack.legInfo, type: 'text', width: 200 },
        hasHazmatItem: { label: "hasHazmatItem", value: currPack.hasHazmatItem, type: 'text', width: 200 },
        nodeId: { label: "Search Node", value: currentLegInfo.nodeId ?? "---", type: 'text', width: 200 },
        // Todo: hasArrived/ hasDeparted/ isClosed /criticalPullTime are not present for every leg .. need to check backend response
        hasArrived: { label: "Has Arrived", value: currentLegInfo.hasArrived ?? "---", type: 'text', width: 200 },
        hasDeparted: { label: "Has Departed", value: currentLegInfo.hasDeparted ?? "---", type: 'text', width: 200 },
        isClosed: { label: "isClosed", value: currentLegInfo.isClosed ?? "---", type: 'text', width: 200 },
        scheduledArrivalTime: { label: "scheduledArrivalTime", value: formatTime(currentLegInfo.scheduledArrivalTime), type: 'text', width: 200 },
        scheduledDepartureTime: { label: "scheduledDepartureTime", value: formatTime(currentLegInfo.scheduledDepartureTime), type: 'text', width: 200 },
        criticalPullTime: { label: "critical Pull Time", value: formatTime(currentLegInfo.criticalPullTime), type: 'text', width: 200 },
        stackingFilter: { label: "Stacking Filter", value: currentLegInfo.stackingFilter ?? "---", type: 'text', width: 200 },
        tcdaStackingFilter: { label: "SlamTime Stacking Filter", value: currentLegInfo.tcdaStackingFilter ?? "---", type: 'text', width: 200 },
        visualMarker: { label: "SlamTime VSM", value: currentLegInfo.visualMarker ?? "---", type: 'string', width: 200 },
        hierarchy: { label: "SortTech Hierarchy (SSP Hierarchy)", value: hierarchyDetails, type: 'text', width: 200 },
        manifestNumber: { label: "Manifest Id", value: formatManifestData(currentLegInfo.manifestNumber), type: 'text', width: 200 },
        items: { label: "Items", value: currentLegInfo.items, type: 'text', width: 200 },
        legInfo: { label: "Legs", value: currPack.legInfo, type: 'text', width: 200 },
        promiseShipDate: { label: "Promise Ship Dt", value: "unknown", type: 'text', width: 200 },
        cisPickupDate: { label: "CIS PickupDt", value: formatTime(currentLegInfo.cisPickupDate), type: 'text', width: 200 },
        cisEstimatedArrivalTime: { label: "CIS Estd ArrivalDt", value: formatTime(currentLegInfo.cisEstimatedArrivalTime), type: 'text', width: 200 },
        trailerId: { label: "Trailer Id", value: currentLegInfo.trailerId ?? "---", type: 'text', width: 200, addCopyIcon: true },
        compStatus: { label: "Comp Status", value: currentLegInfo.compStatus ?? "---", type: 'text', width: 200 },
        uaid: { label: "UAID", value: currPack.uaid, type: 'text', width: 200 },
        package_type: { label: "Package Type", value: currPack.package_type, type: 'text', width: 200 },
        trackingId2: { label: "Reverse Tracking ID", value: currPack.trackingId2, type: 'text', width: 200 }
    }))
}

class afnSearchResponse {

    response: AfnResponseType;
    tableResponse: AfnResponseType;
    idsMap : Record<string, string> // To create a map of scannable Id and Tracking Id to use in GetAllItem results.
    formatFunctionMap = {
        table: {
            "scannable": this.createScannableTable,
            "shipment": this.createScannableTable,
            "transshipment": this.createScannableTable,
            "container": this.createScannableTable,
            "pkglegdetail": this.createPackageLegDetailsTable,
            "trailer": this.createTrailerAndVrIdTable,
            "vrid": this.createTrailerAndVrIdTable,
            "resourceId": this.createResourceIdTable
        }
    }
    constructor(afnForm: afnInputFieldsType, afnResponse: any) {
        this.tableResponse = { leg: undefined, table: undefined, errors: undefined }
        this.idsMap = {}
        let errorsObject: AfnResponseErrorObject = {
            errorMessage: "Error occurred, the inputs provided are not returning any results dao ",
            ids: [],
            isError: false
        };

        if ((afnResponse as Record<string, string>).msg) {
            this.response = { leg: undefined, table: undefined, errors: errorsObject }
        } else if (afnForm.format == "leg" && (afnForm.shipmentType == "scannable" || afnForm.shipmentType == "container" || afnForm.shipmentType == "shipment" || afnForm.shipmentType == "transshipment")) {
            this.response = this.createGrid(afnForm, afnResponse);
            this.tableResponse = this.formatFunctionMap["table"][afnForm.shipmentType](afnForm, afnResponse, true)
        } else if (afnForm.format == "leg" && (afnForm.shipmentType == "trailer" || afnForm.shipmentType == "vrid")) {
            this.response = this.createGridForTrailerAndVrId(afnForm, afnResponse);
            this.tableResponse = this.formatFunctionMap["table"][afnForm.shipmentType](afnForm, afnResponse)
        } else {
            this.response = this.formatFunctionMap["table"][afnForm.shipmentType](afnForm, afnResponse)
        }
    }

    createPackageLegDetailsTable(afnForm: afnInputFieldsType, response: Record<string, Array<Record<PackageLegDetailsKeys, string>>> | Record<string, GeneralObjectType>) {
        let result: Record<string, infoDataType>[] = [];
        let errorsObject: AfnResponseErrorObject = {
            errorMessage: "",
            ids: [],
            isError: false
        };
        Object.keys(response).forEach((key: string) => {
            if ((response as Record<string, Record<string, string>>)[key].error) {
                errorsObject.isError = true;
                let id: string = (response as Record<string, Record<string, string>>)[key].errorReason;
                if ((response as Record<string, Record<string, string>>)[key].errorReason) {
                    let id: string = (response as Record<string, Record<string, string>>)[key].errorReason;
                    errorsObject.ids.push(id);
                }

            }
            else {
                (response[key] as Array<Record<PackageLegDetailsKeys, string>>).forEach((entry) => {

                    result.push(translateStrings({
                        searchId: { label: "Search ID", value: entry["searchId"], type: 'text', width: 200, addCopyIcon: true },
                        type: { label: "Type", value: entry["type"], type: 'text', width: 200 },
                        tracking: { label: "Tracking Id", value: entry["tracking"], type: 'text', width: 200, addCopyIcon: true },
                        shipmentId: { label: "Shipment ID", value: entry["shipmentId"], type: 'text', width: 200, addCopyIcon: true },
                        scannable: { label: "Scannable ID", value: entry["scannable"], type: 'text', width: 200, addCopyIcon: true }
                    }))
                })

            }
        })
        if (result.length == 0) {
            errorsObject.errorMessage = "check Other Region"
            return { leg: undefined, table: undefined, error: undefined, errors: errorsObject }
        }
        let tableHeaders: Array<TableColumns> = generateTableHeaders(result);
        let tableData: Array<Array<TableData>> = generateTableData(result);
        return { leg: undefined, table: { headers: tableHeaders, data: tableData }, error: undefined, errors: errorsObject }
    }

    createTrailerAndVrIdTable(afnForm: afnInputFieldsType, response: Array<Record<string, Record<TrailerIdKeys, string>>>) {
        let result: Record<string, infoDataType>[] = [];
        let errorsObject: AfnResponseErrorObject = {
            errorMessage: "",
            ids: [],
            isError: false
        };

        (response as Array<Record<string, Record<TrailerIdKeys, string>>>).forEach((currentTrailer) => {
            let currentTrailerValues = currentTrailer.trailer;
            if (currentTrailer.trailer["error"]) {
                let id: string = currentTrailer.trailer['errorReason'];
                errorsObject.ids.push(id);
                errorsObject.isError = true;
            }
            else {
                const manifestDetails = currentTrailerValues.manifestDetails;
                result.push(translateStrings({
                    vrId: { label: "VR Id", value: currentTrailerValues.vrId, type: 'text', width: 150, addCopyIcon: true },
                    trailerId: { label: "Trailer Id", value: currentTrailerValues.trailerId, type: 'text', width: 200, addCopyIcon: true },
                    trailerInfo: { label: "Trailer Info", value: currentTrailerValues.trailerInfo, type: 'text', width: 250 },
                    businessType: { label: "Business Type", value: currentTrailerValues.businessType, type: 'text', width: 150 },
                    shippingPurposeType: { label: "Shipping Purpose Type", value: currentTrailerValues.shippingPurposeType, type: 'text', width: 200 },
                    carrier: { label: "Carrier", value: currentTrailerValues.carrier, type: 'text', width: 150 },
                    criticalPullTime: { label: "CPT", value: formatTime(currentTrailerValues.criticalPullTime), type: 'text', width: 200 },
                    actualDepartureTime: { label: "ADT", value: formatTime(currentTrailerValues.actualDepartureTime), type: 'text', width: 200 },
                    scheduledDepartureTime: { label: "SDT", value: formatTime(currentTrailerValues.scheduledDepartureTime), type: 'text', width: 200 },
                    nodeId: { label: "Node Id", value: currentTrailerValues.nodeId, type: 'text', width: 150, addCopyIcon: true },
                    planId: { label: "Plan Id", value: currentTrailerValues.planId, type: 'text', width: 300, addCopyIcon: true },
                    status: { label: "Status (SSP)", value: currentTrailerValues.status, type: 'text', width: 150 },
                    location: { label: "Location", value: currentTrailerValues.location, type: 'text', width: 150 },
                    ssp_count: { label: "Package count (SSP)", value: currentTrailerValues.ssp_count.toString(), type: 'text', width: 200 },
                    packageCount: { label: "Package count (GTS)", value: currentTrailerValues.packageCount.toString(), type: 'text', width: 200 },
                    loadStopState: { label: "Trailer state (GTS)", value: formatTime(currentTrailerValues.loadStopState), type: 'text', width: 200 },
                    loadStopArrivalTime: { label: "Arrival Time (GTS)", value: formatTime(currentTrailerValues.loadStopArrivalTime), type: 'text', width: 200 },
                    loadStopDepartureTime: { label: "Departure Time (GTS)", value: formatTime(currentTrailerValues.loadStopDepartureTime), type: 'text', width: 200 },
                    manifestDetails: { label: "Manifest Details", value: manifestDetails && (Object.keys(manifestDetails).length !== 0) ? manifestDetails : "", type: 'text', width: 200 },
                }))
            }
        })
        if (result.length == 0) {
            errorsObject.errorMessage = "check Other Region"
            return { leg: undefined, table: undefined, error: undefined, errors: errorsObject }
        }
        let tableHeaders: Array<TableColumns> = generateTableHeaders(result);
        let tableData: Array<Array<TableData>> = generateTableData(result);
        return { leg: undefined, table: { headers: tableHeaders, data: tableData }, error: undefined, errors: errorsObject }
    }
    createResourceIdTable(afnForm: afnInputFieldsType, response: Array<Record<ResourceIdKeys, string>>) {
        let errorsObject: AfnResponseErrorObject = {
            errorMessage: "",
            ids: [],
            isError: false
        };
        let result: Record<string, infoDataType>[] = [];
        response.forEach((entry) => {
            if (entry["error"]) {
                errorsObject.ids.push(entry['errorReason']);
                errorsObject.isError = true;
            } else {
                result.push(translateStringsForRecord({
                    label: { label: "Label", value: entry["label"], type: 'text', width: 200, addCopyIcon: true },
                    type: { label: "Search Id", value: entry["type"], type: 'text', width: 200, addCopyIcon: true },
                    containerId: { label: "Container Id", value: entry["containerId"], type: 'text', width: 200, addCopyIcon: true },
                }))
            }

        })
        if (result.length == 0) {
            errorsObject.errorMessage = "check Other Region"
            return { leg: undefined, table: undefined, error: undefined, errors: errorsObject }
        }
        let tableHeaders: Array<TableColumns> = generateTableHeaders(result);
        let tableData: Array<Array<TableData>> = generateTableData(result);
        return { leg: undefined, table: { headers: tableHeaders, data: tableData }, error: undefined, errors: undefined }
    }
    checkErrorPackage(currentPackage: AfnPackageResponseType, errors: AfnResponseErrorObject) {
        if (currentPackage.package.error) {
            errors.isError = true;
            if (currentPackage.package.errorReason) {
                errors.ids.push(currentPackage.package.errorReason);
            } else {
                errors.ids.push(currentPackage.package.label);
            }
            return true
        }
        return false;
    }
    checkErrorTrailerOrVrid(trailer: AfnTrailerAndVrIdResponseType, errors: AfnResponseErrorObject) {
        if (trailer.trailer.error) {
            errors.isError = true;
            if (trailer.trailer.errorReason) {
                errors.ids.push(trailer.trailer.errorReason);
            } else {
                errors.ids.push(trailer.trailer.searchId);
            }
            return true
        }
        return false;
    }

    createScannableTable(afnForm: afnInputFieldsType, response: Array<AfnPackageResponseType> | Array<CustomError>, shouldIncludeAllNodesData?: boolean) {
        let result: Array<Record<ScannableKeysTableFormat, infoDataType>> = [];
        let errorsObject: AfnResponseErrorObject = {
            errorMessage: "",
            ids: [],
            isError: false
        };
        for (let i = 0; i < response.length; i++) {
            let currentPackage = (response as Array<AfnPackageResponseType>)[i];
            if (currentPackage.package.error) {
                errorsObject.isError = true;
                let id: string = currentPackage.package.label
                if (currentPackage.package.errorReason) {
                    id = currentPackage.package.errorReason;
                }
                errorsObject.ids.push(id);
            }
            else {
                let currPack = currentPackage.package;

                if (shouldIncludeAllNodesData === true) {
                    currentPackage.package.details.forEach(leg => {
                        let filteredLegInfo = [leg]
                        let currentLegInfo: any = filteredLegInfo.length > 0 ? filteredLegInfo[0].leg : {}
                        let hierarchyDetails = currentLegInfo.hierarchy ? currentLegInfo.hierarchy.map((currentType: any) => currentType.tier.label).join(" -> ") : "---"
                        populateResults(result, currentLegInfo, hierarchyDetails, currPack, afnForm, i)
                    })
                } else {
                    let filteredLegInfo = currentPackage.package.details.filter(currentLeg => currentLeg.leg.nodeId == afnForm.nodeInfo)
                    if (filteredLegInfo.length === 0 && currentPackage?.package?.details?.length >= 1) {
                        // Incase user searched with incorrect node  , consider first node of package as currentLeg
                        filteredLegInfo = [currentPackage.package.details[0]]
                    }
                    let currentLegInfo: any = filteredLegInfo.length > 0 ? filteredLegInfo[0].leg : {}
                    let hierarchyDetails = currentLegInfo.hierarchy ? currentLegInfo.hierarchy.map((currentType: any) => currentType.tier.label).join(" -> ") : "---"
                    populateResults(result, currentLegInfo, hierarchyDetails, currPack, afnForm, i)
                }
            }
        }

        if (result.length == 0) {
            errorsObject.errorMessage = "check Other Region"
            return { leg: undefined, table: undefined, error: "errorCustom", errors: errorsObject };
        }
        let tableHeaders: Array<TableColumns> = generateTableHeaders(result);
        let tableData: Array<Array<TableData>> = generateTableData(result);
        return { leg: undefined, table: { headers: tableHeaders, data: tableData }, error: "errorCustom", errors: errorsObject };


    }


    createGrid(afnForm: afnInputFieldsType, response: Array<AfnPackageResponseType> | Array<CustomError>): AfnResponseType {
        let result = [];
        let errorsObject: AfnResponseErrorObject = {
            errorMessage: "",
            ids: [],
            isError: false
        };
        for (let i = 0; i < response.length; i++) {
            let currentPackage = (response as Array<AfnPackageResponseType>)[i];
            if (!this.checkErrorPackage(currentPackage, errorsObject)) {
                let packageDetails = {
                    legInfo: this.generateLegInfo(currentPackage, (response as Array<AfnPackageResponseType>)[i].package.recycledTrackingId),
                    toolLinks: this.generateToolLinksData(currentPackage),
                    esmmInfo: this.generateESMMInfo(afnForm, currentPackage),
                    enrichedTrackingIdInfo: this.generateEnrichedTrackingIdInfo(afnForm, currentPackage),
                    packageDetails: this.generatePackageDetailsData(afnForm, currentPackage),
                    nodeDetails: this.generateNodeDetailsData(afnForm, currentPackage)
                }
                result.push(packageDetails);
            }
        }
        if (result.length == 0) {
            errorsObject.errorMessage = "check Other Region"
            return { leg: undefined, table: undefined, errors: errorsObject };
        }
        return { leg: result, table: undefined, errors: errorsObject };
    }

    createGridForTrailerAndVrId(afnForm: afnInputFieldsType, response: Array<AfnTrailerAndVrIdResponseType> | Array<CustomError>): AfnResponseType {
        let result = [];
        let errorCustom = ""
        let errorsObject: AfnResponseErrorObject = {
            errorMessage: "",
            ids: [],
            isError: false
        };
        for (let i = 0; i < response.length; i++) {
            let currentTrailerOrVrId = (response as Array<AfnTrailerAndVrIdResponseType>)[i];
            if (!this.checkErrorTrailerOrVrid(currentTrailerOrVrId, errorsObject)) {
                let trailerDetails: any = {
                    legInfo: { displayString: currentTrailerOrVrId.trailer.trailerInfo },
                    toolLinks: undefined,
                    esmmInfo: undefined,
                    enrichedTrackingIdInfo: undefined,
                    packageDetails: undefined,
                    nodeDetails: this.generateNodeDetailsForTrailerAndVrid(currentTrailerOrVrId.trailer)
                }
                result.push(trailerDetails);
            }
        }
        if (result.length == 0) {
            errorsObject.errorMessage = "check Other Region"
            return { leg: undefined, table: undefined, errors: errorsObject };
        }
        return { leg: result, table: undefined, errors: errorsObject };
    }

    generateNodeDetailsForTrailerAndVrid(currentTrailerOrVrId: AfnTrailerAndVridInfoType) {
        let result = [];

        result.push(translateStrings({
            vrId: { label: "VR Id", value: currentTrailerOrVrId.vrId, type: 'text' },
            trailerId: { label: "Trailer Id", value: currentTrailerOrVrId.trailerId, type: 'text' },
            businessType: { label: "Business Type", value: currentTrailerOrVrId.businessType, type: 'text' },
            shippingPurposeType: { label: "Shipping Purpose Type", value: currentTrailerOrVrId.shippingPurposeType, type: 'text' },
            carrier: { label: "Carrier", value: currentTrailerOrVrId.carrier, type: 'text' },
            criticalPullTime: { label: "CPT", value: formatTime(currentTrailerOrVrId.criticalPullTime), type: 'text' },
            actualDepartureTime: { label: "ADT", value: formatTime(currentTrailerOrVrId.actualDepartureTime), type: 'text' },
            scheduledDepartureTime: { label: "SDT", value: formatTime(currentTrailerOrVrId.scheduledDepartureTime), type: 'text' },
            nodeId: { label: "Node Id", value: currentTrailerOrVrId.nodeId, type: 'text' },
            planId: { label: "Plan Id", value: currentTrailerOrVrId.planId, type: 'text' },
            status: { label: "Status (SSP)", value: currentTrailerOrVrId.status, type: 'text' },
            location: { label: "Location", value: currentTrailerOrVrId.location, type: 'text' },
            ssp_count: { label: "Package count (SSP)", value: currentTrailerOrVrId.ssp_count.toString(), type: 'text' },
            packageCount: { label: "Package count (GTS)", value: currentTrailerOrVrId.packageCount.toString(), type: 'text' },
            loadStopState: { label: "Trailer state (GTS)", value: formatTime(currentTrailerOrVrId.loadStopState), type: 'text' },
            loadStopArrivalTime: { label: "Arrival Time (GTS)", value: formatTime(currentTrailerOrVrId.loadStopArrivalTime), type: 'text' },
            loadStopDepartureTime: { label: "Departure Time (GTS)", value: formatTime(currentTrailerOrVrId.loadStopDepartureTime), type: 'text' },
            manifestDetails: { label: "Manifest details", value: this.generateManifestData(currentTrailerOrVrId.manifestDetails), type: "tableInCell", width: 300 },
        }))

        return result as Array<afnNodeTableDataType>;
    }


    generateLegInfo(currentPackage: AfnPackageResponseType, recycledTid: boolean | undefined): { Tid?: string, displayString: string } {
        let legInfo: {
            Tid?: string, displayString: string
        };
        let currPack = currentPackage.package;
        if (currPack.update !== undefined) {
            legInfo = {
                Tid: currentPackage.package.trackingId.toString(),
                displayString: "PACKAGE: " + currPack.label + " with tracking id (" + currPack.inputIdentifier + ") is reslammed/replanned at Node -  " + currPack.legs[0].leg + ". Details of the new tracking id -  "

            }
        }
        else if (recycledTid === undefined) {
            legInfo = {
                displayString: currPack.type === undefined ? 'Error' : currPack.type + ': ' + currPack.label + ' ( ' + currPack.legInfo + ' )'
            }
        }
        else {
            legInfo = {
                Tid: "recycled",
                displayString: currPack.type === undefined ? 'Error' : currPack.type + ': ' + currPack.label + ' ( ' + currPack.legInfo + ' ) [Old Entry of Recycled Tracking id - ' + currPack.searchInputIdentifier.trackingId + ' ]'
            }
        }
        return legInfo;
    }

    generateToolLinksData(currentPackage: AfnPackageResponseType): AfnToolLinkDataType {

        let currPack = currentPackage.package;
        const realm: string = getRealmFromUrl();
        const realm_f: string = realm === 'fe' ? 'jp' : realm;
        const region: string = realmToRegionMap[realm]
        const warehouseId: string = currPack.details[0].leg["nodeId"]
        const shipmentId: string = currPack.orderingShipmentId
        const trackingId: string = currPack.trackingId
        const tcdaId: string = currPack.tcdaId
        const orderId: string = currPack.orderingOrderId
        let defaultToolLinksData: Array<string> = [realm, currPack.orderingOrderId]
        // splitting the links to retain order of links. If not the comp link will be added to last
        let afnLinks_part1: any = {
            'hitch': [realm_f, warehouseId, shipmentId],
            'hero': [realm_f, warehouseId, shipmentId],
            'atrops': [realm, shipmentId],
            'oblt': [realm, trackingId],
            'oblt-events': [realm, trackingId]
        }
        let afnLinks_part2: any = {
            'f2p-visualizer': [defaultToolLinksData[1]],
            'grass-ui': defaultToolLinksData,
            'ffrm': [realm, shipmentId],
            'tcda': [region, tcdaId],
            'manifest-visibility': [realm, shipmentId],
            'promise-analyzer': [realm, orderId]
        }
        let afnLinks_part1_with_comp_link = this.updateAfnToolLinksWithCompDetails(currentPackage, afnLinks_part1)
        return { ...afnLinks_part1_with_comp_link, ...afnLinks_part2 };
    }

    updateAfnToolLinksWithCompDetails(currentPackage: AfnPackageResponseType, afnLinks: AfnToolLinkDataType): AfnToolLinkDataType {
        let currPack = currentPackage.package;
        const lastLeg: number = currPack.details.length - 1;
        const compShipmentId = currPack.details[lastLeg].leg.compShipmentId
        const trackingId: string = currPack.trackingId

        if (currPack.countryCode === 'US' || currPack.countryCode === 'CA' || currPack.countryCode === 'MX' || currPack.countryCode === 'IN') {
            let topLevelDomain = countryCodeToTopLevelDomainMapping[currPack.countryCode]
            afnLinks['scc_comp'] = [topLevelDomain, trackingId]
        } else {
            if (compShipmentId !== undefined) {
                if (currPack.countryCode === 'JP' || currPack.countryCode === 'CN')
                    afnLinks['comp'] = [currPack.countryCode.toLocaleLowerCase(), compShipmentId]
                else
                    afnLinks['comp'] = ['eu', compShipmentId]
            }
        }
        return afnLinks
    }

    generateError(currentPackage: AfnPackageResponseType): PackageErrorType {
        let currPack = currentPackage.package;
        if (currPack.type === undefined && currPack.error !== undefined) {
            return {
                error: {
                    label: "Error for " + currPack.scannableId,
                    value: currPack.error
                }
            };
        }
        return { error: "" };
    }

    generateESMMInfo(afnForm: afnInputFieldsType, currentPackage: AfnPackageResponseType): ESMMDataType {
        let currPack = currentPackage.package;
        return translateStringsForRecord({
            label: "ESMM Info",
            value: currPack.enrichedLegInfo,
            isVisible: (afnForm.shipmentType !== 'transshipment' && currPack.enrichedTrackingIdInfo !== undefined)
        });
    }

    generateEnrichedTrackingIdInfo(afnForm: afnInputFieldsType, currentPackage: AfnPackageResponseType): EnrichedTrackingIdInfoDataType {
        let currPack = currentPackage.package;
        return translateStringsForRecord({
            label: "TrackingId Info",
            value: currPack.enrichedTrackingIdInfo,
            isVisible: afnForm.multipleVersionDataCheckbox
        });
    }

    packageDetailsMapping(afnForm: afnInputFieldsType, currentAttribute: PackageDetailsAttributesListType) {
        let completeList = ["scannableId", "label", "orderingShipmentId", "trackingId", "tcdaId", "hasHazmatItem", "gtsPreManifestNumber", "gtsPreManifestNumber", "auditHistory", "orderingOrderId", "trmStatus", "package_type", "trackingId2", "package_count"]
        let attributeToBeVisibile = {
            scannable: completeList,
            shipment: completeList,
            container: completeList,
            transshipment: ['scannableId', 'tcdaId', 'auditHistory'],
            pkglegdetail: [''],
            trailer: [''],
            vrid: [''],
            resourceId: ['']
        }
        if (attributeToBeVisibile[afnForm.shipmentType].indexOf(currentAttribute) == -1) {
            return false;
        }
        return true;
    }

    generatePackageDetailsData(afnForm: afnInputFieldsType, currentPackage: AfnPackageResponseType): DetailsUtilType {
        let currPack = currentPackage.package;
        this.idsMap[currPack.label] = currPack.trackingId
        return {
            sectionHeader: "Package Details",
            className: "package-details",
            details: translateStrings({
                scannableId: { label: "Sp00 Id", value: currPack.scannableId, isVisible: this.packageDetailsMapping(afnForm, "scannableId") },
                label: { label: "Encrypted Shipment Id", value: currPack.label, isVisible: this.packageDetailsMapping(afnForm, "label") },
                orderingShipmentId: { label: "Shipment Id", value: currPack.orderingShipmentId, isVisible: this.packageDetailsMapping(afnForm, "orderingShipmentId") },
                trackingId: { label: "Tracking Id", value: currPack.trackingId, isVisible: this.packageDetailsMapping(afnForm, "trackingId") },
                tcdaId: { label: "TCDA Id", value: currPack.tcdaId, isVisible: this.packageDetailsMapping(afnForm, "tcdaId") },
                hasHazmatItem: { label: "Has Hazmat?", value: String(currPack.hasHazmatItem), isVisible: this.packageDetailsMapping(afnForm, "hasHazmatItem") },
                gtsPreManifestNumber: {
                    label: "PreManifest Number",
                    value: currPack.gtsPreManifestNumber || "",
                    isVisible: currPack.gtsPreManifestNumber != undefined && this.packageDetailsMapping(afnForm, "gtsPreManifestNumber")
                },
                auditHistory: {
                    label: "Audit Info",
                    value: currPack.auditHistory || "",
                    isVisible: currPack.auditHistory != undefined && this.packageDetailsMapping(afnForm, "auditHistory")
                },
                orderingOrderId: { label: "Order Id", value: currPack.orderingOrderId, isVisible: this.packageDetailsMapping(afnForm, "orderingOrderId") },
                trmStatus: { label: "Package Status", value: currPack.trmStatus, color: this.packageStatusColorMapping(currPack.trmStatus), isVisible: this.packageDetailsMapping(afnForm, "trmStatus") },
                package_type: { label: "Package Type", value: currPack.package_type, isVisible: this.packageDetailsMapping(afnForm, "package_type") },
                package_count: {
                    label: "Package Count",
                    value: currPack.packageCount,
                    isVisible: currPack.packageCount != undefined && this.packageDetailsMapping(afnForm, "package_count")
                },
                trackingId2: { label: "Reverse Tracking ID", value: currPack.trackingId2, isVisible: this.packageDetailsMapping(afnForm, "trackingId2") }
            })
        };
    }

    packageStatusColorMapping(status: string) {
        return (status === "Cancelled" || status === "Cancellation Requested") ? "error" : "primary";
    }

    generateNodeDetailsData(afnForm: afnInputFieldsType, currentPackage: AfnPackageResponseType): Array<AfnNodeDataType> {
        let result = [];
        let currPack = currentPackage.package;
        let legs = currPack.details;
        for (let legIndex = 0; legIndex < legs.length; legIndex++) {
            let currentLeg = legs[legIndex].leg;
            let hierarchyDetails = currentLeg.hierarchy
                ? currentLeg.hierarchy.some((details: any) => details.tier.label === "Restricted")
                    ? "Restricted"
                    : currentLeg.hierarchy
                        .map((details: any) => details.tier.label)
                        .join("\n")
                : "---";
            result.push(translateStrings({
                nodeId: { label: "Leg", value: currentLeg.nodeId, type: 'highLightedText' },
                containerId: { label: "TT Tool Link", value: currentLeg.containerId || "" },
                atropsLink: { label: "ATROPs Warehouse Link", value: currentLeg.atropsLink },
                hasArrived: { label: "Has Arrived", value: currentLeg.hasArrived || "" },
                hasDeparted: { label: "Has Departed", value: currentLeg.hasDeparted || "" },
                isClosed: { label: "Is Pallet/Shuttle closed", value: currentLeg.isClosed || "" },
                stackingFilter: { label: "Stacking Filter", value: { nodeId: currentLeg.nodeId, stackingFilter: currentLeg.stackingFilter ?? '' } as StackingFilterValue, type: "graphInModal" },
                tcdaStackingFilter: { label: "SlamTime Stacking Filter", value: currentLeg.tcdaStackingFilter, isVisible: (afnForm.shipmentType !== 'transshipment') },
                visualMarker: { label: "SlamTime VSM", value: currentLeg.visualMarker, isVisible: (afnForm.shipmentType !== 'transshipment'), type: 'string' },
                containerType: { label: "Container Type", value: currentLeg.containerType || "" },
                transPlanType: { label: "Transportation Type", value: currentLeg.transPlanType },
                scheduledArrivalTime: { label: "SAT", value: formatTime(currentLeg.scheduledArrivalTime) },
                scheduledDepartureTime: { label: "SDT", value: formatTime(currentLeg.scheduledDepartureTime) },
                criticalPullTime: { label: "CPT", value: formatTime(currentLeg.criticalPullTime) },
                hierarchy: { label: "SSP Hierarchy", value: hierarchyDetails },
                // manifestNumber: { label: "Manifest details", value: this.generateManifestData(currentLeg.manifestNumber), type: "tableInCell", width: 300, isVisible: (afnForm.shipmentType !== 'transshipment') },
                //Todo: value-> 'Manifest details<br><a href="https://manifest-console.corp.amazon.com/" target=_blank>MANTIS UI</a>'
                //todo : formatManifestDetail function need to be implemented
                trailerId: { label: "Trailer Id", value: currentLeg.trailerId || "", isVisible: (afnForm.shipmentType !== 'transshipment') },
                compStatus: { label: "Comp Status", value: currentLeg.compStatus, isVisible: (afnForm.shipmentType !== 'transshipment') }
            }))
        }
        return result as Array<AfnNodeDataType>;
    }

    generateManifestData(manifestInfo: ManifestInfoType): afnNodeTableDataType[] {
        if (!manifestInfo) return manifestInfo
        let manifestTableList: Array<Record<string, infoDataType>> = []
        let styles: Record<styleKeys, string> = {
            wordWrap: 'break-word',
            fontSize: '11px'
        }
        Object.keys(manifestInfo).map((documentId: string) => {
            let transmissionChannel: string = manifestInfo[documentId]["transmissionChannel"] as string
            manifestTableList.push(translateStrings({
                documentId: { label: "Manifest Id", value: documentId, styles },
                documentType: { label: "Document Type", value: manifestInfo[documentId]["documentType"]?.join(", "), styles },
                transmissionType: { label: "Transmission Type", value: this.getTransmissionChannel(transmissionChannel, documentId), styles },
            }))
        })
        return manifestTableList as afnNodeTableDataType[]
    }

    getTransmissionChannel(transmissionChannel: string, manifestId: string): string {
        if (transmissionChannel === "Restricted") return transmissionChannel
        let ediUrl: string;
        let realm: string = getRealmFromUrl();
        let transmissionType: string;
        if (transmissionChannel == "EDI_TRANSMISSION") {
            if (realm == "na") {
                ediUrl = "https://eisws-console.amazon.com/"
            } else if (realm == "fe") {
                ediUrl = "https://eisws-console-jp.amazon.com/"
            } else {
                ediUrl = "https://eisws-console-" + realm + ".amazon.com/"
            }
            ediUrl = ediUrl + "console/search?date=now&documentType=&endDate=&objects=" + manifestId + "&inDays=true&searchArchiveAthena=false&forceSearch=false&allDocTypes=false&summaryOffset=0&businessUnitId=&objectType=&eventsOnly=false&allAccountOrExchangeIds=false&encrypt=false&allEventNames=false&searchDateMode=byTimeRange&lookBack=true&eventName=true&searchArchive=false&searchEventCountOnly=false&event=&searchOption=&businessUnitName=&searchForward=true&accountOrExchangeId=&allBusinessUnits=false&timespan=90&exchangeName=&startDate=&eventsOffset=0";
            transmissionType = "<a href='" + ediUrl + "' target='_blank'>EDI</a>";
        } else if (transmissionChannel == "API_TRANSMISSION") {
            transmissionType = "<a href='https://w.amazon.com/bin/view/Transportation/MLTS/MANTIS/RunBook/#HRe-drivingfailedstepfunctioninAPIbasedtransmissiontransmission:' target='_blank'>API</a><br>";
        } else {
            transmissionType = '';
        }
        return transmissionType;
    }
}

export default afnSearchResponse;
