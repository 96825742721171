import { DropdownDataType, DependentDropdownDataType } from "src/components/helpers/dropdown-util/dropdown-util.type"
import { TableColumns, TableData } from "src/components/helpers/table-renderer-util/table-renderer-util.type"

export const documentTypeDropdownData: DropdownDataType = {
    label: "Document Type",
    placeholder: "Select Document Type",
    errorMessage: "Please select a document type",
    dropdownOptions: [
        {
            visibleText: "Statuses",
            value: "STATUS"
        },{
            visibleText: "Manifests",
            value: "MANIFEST"
        },{
            visibleText: "MFN Statuses",
            value: "CDEX_STATUS"
        },{
            visibleText: "MFN Manifests",
            value: "CDEX_MANIFEST"
        },{
            visibleText: "Transship Manifest",
            value: "TRANSSHIP_MANIFEST"
        },{
            visibleText: "Mfn Priority Updates",
            value: "MFN_PRIORITY_UPDATE"
        },{
            visibleText: "Mfn API Scan Puller",
            value: "MFN_API_SCAN_PULLER"
        }
    ]
}

export const identifierTypeAllWorkflowDropdownData: DependentDropdownDataType = {
    label: "Identifier Type",
    placeholder: "Select Identifier Type",
    errorMessage: "Please select a Identifier type",
    dropdownOptions: {
        "STATUS": [
            {
                visibleText: "Tracking Id",
                value: "tracking_id"
            }, {
                visibleText: "EDI File Name",
                value: "file_name",
                constraintText: "Please provide the file name from protocol-delivery step in EI Console search"
            }, {
                visibleText: "Ticket Id",
                value: "tmpp_ticket_id"
            }
        ],
        "MANIFEST": [
            {
                visibleText: "Tracking Id",
                value: "tracking_id"
            }, {
                visibleText: "Fulfillment Shipment Id",
                value: "fulfillment_shipment_id"
            }, {
                visibleText: "Manifest Id",
                value: "manifest_id"
            }, {
                visibleText: "Ticket Id",
                value: "tmpp_ticket_id"
            }
        ],
        "CDEX_STATUS": [
            {
                visibleText: "Tracking Id",
                value: "tracking_id"
            },{
                visibleText: "Ticket Id",
                value: "tmpp_ticket_id"
            }
        ],
        "CDEX_MANIFEST": [
            {
                visibleText: "Order Id",
                value: "order_id"
            },{
                visibleText: "Ticket Id",
                value: "tmpp_ticket_id"
            }
        ],
        "TRANSSHIP_MANIFEST": [
            {
                visibleText: "Load Id",
                value: "load_id"
            },{
                visibleText: "Manifest Id",
                value: "manifest_id"
            },{
                visibleText: "Ticket Id",
                value: "tmpp_ticket_id"
            }
        ],
        "MFN_PRIORITY_UPDATE": [
            {
                visibleText: "Carrier Tracking Id",
                value: "tracking_id"
            },{
                visibleText: "Ticket Id",
                value: "tmpp_ticket_id"
            }
        ],
        "MFN_API_SCAN_PULLER": [
            {
                visibleText: "Carrier Tracking Id",
                value: "tracking_id"
            },{
                visibleText: "Ticket Id",
                value: "tmpp_ticket_id"
            }
        ]
    }

}

export const ofisAllWorkflowsTableColumns: Array<TableColumns> = [
    {
        visibleText: "Creation Date",
        isColumnActive: true,
        columnKey: "CREATION DATE",
        width: 150
    }, {
        visibleText: "Tracking Id",
        isColumnActive: true,
        columnKey: "TRACKING ID",
        width: 150
    }, {
        visibleText: "Herd Object",
        isColumnActive: true,
        columnKey: "HERD OBJECT",
        width: 150
    }, {
        visibleText: "Workflow Status Reason",
        isColumnActive: true,
        columnKey: "STATUS REASON",
        width: 150
    }, {
        visibleText: "Workflow Status",
        isColumnActive: true,
        columnKey: "WORKFLOW STATUS",
        width: 150
    }, {
        visibleText: "Carrier Name Received",
        isColumnActive: true,
        columnKey: "NAME RECEIVED",
        width: 150
    }, {
        visibleText: "ShipTrack Carrier Id",
        isColumnActive: true,
        columnKey: "CARRIER ID",
        width: 150
    }, {
        visibleText: "Package Tracking Status",
        isColumnActive: true,
        columnKey: "TRACKING STATUS",
        width: 150
    }, {
        visibleText: "Sender Id",
        isColumnActive: true,
        columnKey: "SENDER ID",
        width: 150
    }, {
        visibleText: "Herd Work Item Id",
        isColumnActive: true,
        columnKey: "ITEM ID",
        width: 150
    }, {
        visibleText: "Customer Order Id",
        isColumnActive: true,
        columnKey: "ORDER ID",
        width: 150
    }, {
        visibleText: "Parent Herd Object Id",
        isColumnActive: true,
        columnKey: "OBJECT ID",
        width: 150
    }, {
        visibleText: "Fulfillment Shipment Id",
        isColumnActive: true,
        columnKey: "SHIPMENT ID",
        width: 150
    }, {
        visibleText: "Package Id",
        isColumnActive: true,
        columnKey: "PACKAGE ID",
        width: 150
    }, {
        visibleText: "Manifest Herd Object Id",
        isColumnActive: true,
        columnKey: "OBJECT ID",
        width: 150
    }, {
        visibleText: "Ticket",
        isColumnActive: true,
        columnKey: "TICKET",
        width: 150
    },
]

export const ofisAllWorkflowsTableData: Array<Array<TableData>> = [
    [
 	   {
            value: "Wed, 04 Jan 2023 12:02:35 GMT",
            type: "text"
        }, {
                value: "123456",
            type: "text"
        }, {
                value: "e02e5f350a9c69b2538838a6d8110c6e7899894678961237bdf757e570cdedbd",
            type: "text"
        }, {
                value: "SUCCESS",
            type: "text"
        }, {
                value: "CLOSED",
            type: "text"
        }, {
                value: "La Poste",
            type: "text"
        }, {
                value: "-",
            type: "text"
        }, {
                value: "PENDING_TRACKING",
            type: "text"
        }, {
                value: "-",
            type: "text"
        }, {
                value: "e02e5f350a9c69b2538838a6d8110c6e7899894678961237bdf757e570cdedbd",
            type: "text"
        }, {
                value: "403-8732526-3741965",
            type: "text"
        }, {
                value: "2388a8e3bae47ba9d7c93ade21b1a72dbd3061b17097edfbcf26a583946fd63a",
            type: "text"
        }, {
                value: "20348177012039",
            type: "text"
        }, {
                value: "1",
            type: "text"
        }, {
                value: "396e00d91a20df6ce6a3ce44eee21f6f1a3a3259abe77080ad1362ecf09ba040",
            type: "text"
        }, {
                value: "-",
            type: "text"
        }   
    ]
]

export const facetKeyToInfoMapping: {[key: string]: string} = {
    scac_code_f: "SCAC Code",
    tmpp_workitem_status_reason_f: "WorkItem Status Reason",
    tmpp_workitem_status_f: "WorkItem Status",
    trading_partner_f: "Trading Partner",
    tmpp_ticket_id_f: "Ticket Id",
    sender_id: "Sender",
    manifest_type_f: "Manifest Type",
    transport_carrier_id_f: "Transport Carrier",
    warehouse_id_f: "Warehouse Id",
    carrier_f: "Carrier",
    carrier_id_f: "Carrier Id",
    pkg_status_f: "Package Status",
    realm_f: "Realm",
    destination_fc_f: "Destination DC",
    scac_f: "SCAC"
}

export const ofisAllWorkflowsTableHeaders: TableColumns[] = [
    {columnKey: "tmpp_creation_date", visibleText: "Creation Date", isColumnActive: true, width: 150},
    {columnKey: "tracking_id", visibleText: "Tracking ID", isColumnActive: true, width: 150},
    {columnKey: "tmpp_herd_obj_id", visibleText: "Herd Object", isColumnActive: true, width: 150},
    {columnKey: "tmpp_workitem_status_reason",visibleText: "Workflow Status Reason", isColumnActive: true, width: 250},
    {columnKey: "tmpp_workitem_status", visibleText: "Workflow Status", isColumnActive: true, width: 150},
    {columnKey: "tmpp_herd_workitem_id",visibleText: "Herd Work Item id", isColumnActive: true, width: 150},
    {columnKey: "carrier", visibleText: "Carrier Name Received", isColumnActive: true, width: 150},
    {columnKey: "carrier_id", visibleText: "ShipTrack Carrier ID", isColumnActive: true, width: 150},
    {columnKey: "manifest_id", visibleText: "Manifest ID", isColumnActive: true, width: 150},
    {columnKey: "manifest_type", visibleText: "Manifest Type", isColumnActive: true, width: 150},
    {columnKey: "order_id", visibleText: "Order ID", isColumnActive: true, width: 150},
    {columnKey: "fulfillment_shipment_id",visibleText: "Fulfillment Shipment ID", isColumnActive: true, width: 200},
    {columnKey: "trading_partner", visibleText: "Trading Partner", isColumnActive: true, width: 150},
    {columnKey: "sender_id", visibleText: "Sender ID", isColumnActive: true, width: 150},
    {columnKey: "status_code", visibleText: "Status Code", isColumnActive: true, width: 150},
    {columnKey: "tmpp_parent_herd_obj_id",visibleText: "Parent Herd Object Id", isColumnActive: true, width: 150},
    {columnKey: "tmpp_parent_herd_workitem_id",visibleText: "Parent Herd WorkItem Id", isColumnActive: true, width: 150},
    {columnKey: "customer_order_id", visibleText: "Customer Order Id", isColumnActive: true, width: 200},
    {columnKey: "customer_shipment_id", visibleText: "Customer Shipment Id", isColumnActive: true, width: 150},
    {columnKey: "ordering_order_id", visibleText: "Ordering Order Id", isColumnActive: true, width: 150},
    {columnKey: "package_id", visibleText: "Package Id", isColumnActive: true, width: 150},
    {columnKey: "package_type", visibleText: "Package Type", isColumnActive: true, width: 150},
    {columnKey: "pkg_tender_date", visibleText: "Package Tender Date", isColumnActive: true, width: 150},
    {columnKey: "ship_method", visibleText: "Ship Method", isColumnActive: true, width: 150},
    {columnKey: "tmpp_workitem_errored_data",visibleText: "Workitem errored data", isColumnActive: true, width: 150},
    {columnKey: "transport_carrier_id",visibleText: "Transport Carrier Id", isColumnActive: true, width: 150},
    {columnKey: "warehouse_id", visibleText: "Warehouse id", isColumnActive: true, width: 150},
    {columnKey: "tmpp_ticket_id", visibleText: "Ticket", isColumnActive: true, width: 150},
    {columnKey: "destination_fc", visibleText: "Destination fc", isColumnActive: true, width: 150},
    {columnKey: "encrypted_shipment_id",visibleText: "Encrypted Shipment id", isColumnActive: true, width: 150},
    {columnKey: "file_name", visibleText: "File Name", isColumnActive: true, width: 150},
    {columnKey: "isa_appt_id", visibleText: "Isa Appointment Id", isColumnActive: true, width: 150},
    {columnKey: "load_id", visibleText: "Load id", isColumnActive: true, width: 150},
    {columnKey: "manifest_count", visibleText: "Manifest Count", isColumnActive: true, width: 150},
    {columnKey: "manifest_herd_obj_id",visibleText: "Manifest Herd Object Id", isColumnActive: true, width: 150},
    {columnKey: "marketplace_id", visibleText: "Marketplace id", isColumnActive: true, width: 150},
    {columnKey: "package_count", visibleText: "Package Count", isColumnActive: true, width: 150},
    {columnKey: "parent_herd_obj_id",visibleText: "Parent Herd Object Id", isColumnActive: true, width: 150},
    {columnKey: "pkg_status", visibleText: "Package Tracking Status", isColumnActive: true, width: 150},
    {columnKey: "purchase_id", visibleText: "Purchase id", isColumnActive: true, width: 150},
    {columnKey: "realm", visibleText: "Realm", isColumnActive: true, width: 150},
    {columnKey: "scac", visibleText: "Scac", isColumnActive: true, width: 150},
    {columnKey: "scac_code", visibleText: "SCAC", isColumnActive: true, width: 150},
    {columnKey: "shipment_count", visibleText: "Shipment Count", isColumnActive: true, width: 150},
    {columnKey: "shipment_id", visibleText: "Shipment id", isColumnActive: true, width: 150},
    {columnKey: "tmpp_storage_url", visibleText: "Storage Url", isColumnActive: true, width: 150},
    {columnKey: "trackable_pkg_count",visibleText: "Trackable Package Count", isColumnActive: true, width: 150},
]