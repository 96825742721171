import { DropdownDataType, DependentDropdownDataType } from "src/components/helpers/dropdown-util/dropdown-util.type"
import { TableColumns, TableData } from "src/components/helpers/table-renderer-util/table-renderer-util.type"

export const shipmentTypeDropdownData: DropdownDataType = {
    label: "Shipment Type",
    placeholder: "Select Shipment Type",
    errorMessage: "Please select a shipment type",
    dropdownOptions: [
        {
            visibleText: "Statuses",
            value: "STATUS"
        }, {
            visibleText: "Manifests",
            value: "MANIFEST"
        }, {
            visibleText: "MFN Statuses",
            value: "CDEX_STATUS"
        }, {
            visibleText: "MFN Manifests",
            value: "CDEX_MANIFEST"
        }
    ]
}

export const identifierTypeDropdownData: DependentDropdownDataType = {
    label: "Identifier Type",
    placeholder: "Select Identifier Type",
    errorMessage: "Please select a Identifier type",
    dropdownOptions: {
        "STATUS": [
            {
                visibleText: "Tracking Id",
                value: "tracking_id"
            }, {
                visibleText: "EDI File Name",
                value: "file_name",
                constraintText: "Please provide the file name from protocol-delivery step in EI Console search"
            }
        ],
        "MANIFEST": [
            {
                visibleText: "Tracking Id",
                value: "tracking_id"
            }, {
                visibleText: "Fulfillment Shipment Id",
                value: "fulfillment_shipment_id"
            }, {
                visibleText: "Manifest Id",
                value: "manifest_id"
            }
        ],
        "CDEX_STATUS": [
            {
                visibleText: "Tracking Id",
                value: "tracking_id"
            }
        ],
        "CDEX_MANIFEST": [
            {
                visibleText: "Order Id",
                value: "order_id"
            }
        ]
    }

}

export const ofisFailedWorkflowTableColumns: Array<TableColumns> = [
    {
        visibleText: "CREATION DATE",
        isColumnActive: true,
        columnKey: "CREATION DATE",
        width: 150
    }, {
        visibleText: "TRACKING ID",
        isColumnActive: true,
        columnKey: "TRACKING ID"
    }, {
        visibleText: "HERD OBJECT",
        isColumnActive: true,
        columnKey: "HERD OBJECT",
        width: 150
    }, {
        visibleText: "WORKFLOW STATUS REASON",
        isColumnActive: true,
        columnKey: "STATUS REASON",
        width: 150
    }, {
        visibleText: "WORKFLOW STATUS",
        isColumnActive: true,
        columnKey: "WORKFLOW STATUS",
        width: 150
    }, {
        visibleText: "CARRIER NAME RECEIVED",
        isColumnActive: true,
        columnKey: "NAME RECEIVED",
        width: 150
    }, {
        visibleText: "SHIPTRACK CARRIER ID",
        isColumnActive: true,
        columnKey: "CARRIER ID",
        width: 150
    }, {
        visibleText: "PACKAGE TRACKING STATUS",
        isColumnActive: true,
        columnKey: "TRACKING STATUS",
        width: 150
    }, {
        visibleText: "SENDER ID",
        isColumnActive: true,
        columnKey: "SENDER ID",
        width: 150
    }, {
        visibleText: "TICKET",
        isColumnActive: true,
        columnKey: "TICKET",
        width: 150
    }, 
]


export const ofisFailedWorkflowTableHeaders: TableColumns[] = [
    {columnKey: 'tmpp_creation_date', visibleText: 'Creation Date',  isColumnActive: true, width: 150},
    {columnKey: 'tracking_id', visibleText: 'Tracking ID',  isColumnActive: true, width: 150},
    {columnKey: 'tmpp_herd_obj_id', visibleText: 'Herd Object',  isColumnActive: true, width: 150},
    {columnKey: 'tmpp_workitem_status_reason', visibleText: 'Workflow Status Reason',  isColumnActive: true, width: 150},
    {columnKey: 'tmpp_workitem_status', visibleText: 'Workflow Status',  isColumnActive: true, width: 150},
    {columnKey: 'scac_code', visibleText: 'SCAC',  isColumnActive: true, width: 150},
    {columnKey: 'file_name', visibleText: 'File Name',  isColumnActive: true, width: 150},
    {columnKey: 'trading_partner', visibleText: 'Trading Partner',  isColumnActive: true, width: 150},
    {columnKey: 'sender_id', visibleText: 'Sender ID',  isColumnActive: true, width: 150},
    {columnKey: 'tmpp_ticket_id', visibleText: 'Ticket',  isColumnActive: true, width: 150},
    {columnKey: 'manifest_id', visibleText: 'Manifest ID',  isColumnActive: true, width: 150},
    {columnKey: 'manifest_type', visibleText: 'Manifest Type',  isColumnActive: true, width: 150},
    {columnKey: 'fulfillment_shipment_id', visibleText: 'Fulfillment Shipment ID',  isColumnActive: true, width: 200},
    {columnKey: 'carrier', visibleText: 'Carrier Name Received',  isColumnActive: true, width: 150},
    {columnKey: 'carrier_id', visibleText: 'ShipTrack Carrier ID',  isColumnActive: true, width: 150},
    {columnKey: 'pkg_status', visibleText: 'Package Tracking Status',  isColumnActive: true, width: 150},
    {columnKey: 'order_id', visibleText: 'Order ID',  isColumnActive: true, width: 150},
]