import {FileUploadUtilDataType} from "src/components/helpers/file-upload/file-upload.util.type";
import { InputFieldDataType } from "src/components/helpers/input-field-util/input-field-util.type";
import {RadioButtonDataType} from "src/components/helpers/radio-button.util/radio-button.util.type";

export const stuckShipmentsHeaderData = {
    pageTitle: "EagleEye Solutions - Stuck Shipments",
    key: "stuck-shipments-page-header"
}

export const StuckShipmentsFileUploadUtilData: FileUploadUtilDataType = {
    href : encodeURI("data:text/csv;charset=utf-8,nodeId,shipmentId"),
    downloadedFileName: "stuck-shipment-bulk-redrive-template.csv",
    linkText: "Download Template"
}

export const nodeIdData: InputFieldDataType = {
    label: "Node Id",
    type: "text",
    placeholder: "Enter Node id",
    errorMessage: "Please enter Node id",
}

export const shipmentIdData: InputFieldDataType = {
    label: "Shipment Id",
    type: "text",
    placeholder: "Enter Shipment id",
    errorMessage: "Please enter Shipment id",
}


export const clearStuckShipmentType: RadioButtonDataType = {
    label: "",
    radioButtonValues: [
        {
            visibleText: "Singular Clear",
            value: "single"
        },{
            visibleText: "Bulk Clear",
            value: "bulk"
        }
    ]
}        