import React, { memo, useState } from "react";
import Text from "@amzn/meridian/text";
import "./stuck-shipments.component.scss"
import Input from "@amzn/meridian/input";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import { FileUploadUtil } from "src/components/helpers/file-upload/file-upload.util";
import { PageHeaderUtility } from "src/components/helpers/headers.util/headers.util";
import { StuckShipmentsFileUploadUtilData, stuckShipmentsHeaderData, nodeIdData, shipmentIdData, clearStuckShipmentType } from "src/common/constants/stuck-shipments.const";
import Theme from "@amzn/meridian/theme";
import Link from "@amzn/meridian/link";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import { useAuthContext } from "src/context/AuthenticationContext";
import { useSearchParams } from "react-router-dom";
import { stuckShipmentPayloadType, stuckShipmenResponseType, stuckShipmentInputFieldsType } from "src/common/dao/stuck-shipments/stuck-shipments-dao.type";
import { stuckShipmentsSolutionsApi } from "src/common/api/stuck-shipments.api"
import SolutionsNabTabComponent from "src/pages/solutions-page/components/solutions-navtab/solutions-navTab.component";
import { InputFieldUtil } from "src/components/helpers/input-field-util/input-field-util";
import { RadioButtonInlineFormatUtil } from "src/components/helpers/radio-button.util/radio-button.util";
import Icon from "@amzn/meridian/icon"
import infoTokens from "@amzn/meridian-tokens/base/icon/info"
import Loader from "@amzn/meridian/loader";
import { v4 as uuid } from 'uuid';


const StuckShipmentsComponent = () => {
    const initalstuckShipmentState: stuckShipmentInputFieldsType = {
        nodeId: "",
        shipmentId: "",
        isLoading: false,
        hasError: false,
        response: { requestId: "", successful: false, errorMessage: "", retryable: "" },
        showResultsInfo: false,
        clearType: "single"
    }

    let userAlias = useAuthContext().userAlias;
    const [files, setFiles] = useState<Array<{ name: string; size: number | string; }>>([])
    const [stuckShipmentState, setstuckShipmentState] = useState(initalstuckShipmentState)
    const [stuckShipmentMissingFields, setstuckShipmentMissingFields] = useState<Record<string, string>>({});
    const [encodedFileData, setFileData] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const updateStuckShipmentState = async (field: string, value: any) => { setstuckShipmentState({ ...stuckShipmentState, [field]: value }) }

    const onFileAttached = (files: any) => {
        if (files) {
            let result: string | ArrayBuffer | null;
            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                result = reader.result;
                if (typeof result === 'string') {
                    result = result.split(',')[1];
                    setFileData(result)
                }
            };
        }
        setFiles(files);

    };
    const handlestuckShipment = async () => {
        if (handlestuckShipmentrror())
            return

        let payload: stuckShipmentPayloadType = {
            nodeId: stuckShipmentState.nodeId,
            shipmentId: stuckShipmentState.shipmentId,
            requesterName: userAlias,
            requestId: uuid(),
            isBulkClear: false,
            clearShipmentsStage: "Prod",
            clearShipmentsRegion: searchParams.get("region")!
        }
        if (stuckShipmentState.nodeId != "" && stuckShipmentState.shipmentId != "") {
            payload.nodeId = stuckShipmentState.nodeId;
            payload.shipmentId = stuckShipmentState.shipmentId;
        }

        else if (files) {
            payload.isBulkClear = true;
            payload.fileContentEncoded = encodedFileData;
        }
        setstuckShipmentState({...stuckShipmentState,isLoading: true , showResultsInfo:false , response: { requestId: "", successful: false, errorMessage: "", retryable: "" }})
        stuckShipmentsSolutionsApi(payload).then((response) => {
            if (response.C13){
                setstuckShipmentState({
                    ...stuckShipmentState,
                    response: {requestId: response.C13.requestId , errorMessage: response.C13.errorMessage != "" ? response.C13.errorMessage : "" , successful: response.C13.successful ,retryable: response.C13.retryable ? response.C13.retryable : ""},
                    showResultsInfo: true,
                    isLoading: false,
                });
            }
        })
    }

    const handlestuckShipmentrror = () => {
        //Both tracking id and file should not be empty
        let isHavingIncompleteFields: boolean = false
        setstuckShipmentMissingFields({})
        setstuckShipmentState({ ...stuckShipmentState, showResultsInfo: false })
        if (files.length == 0) {
            if (stuckShipmentState.shipmentId.length == 0) {
                stuckShipmentMissingFields["shipmentId"] = "Please enter shipment Id"
                setstuckShipmentMissingFields({ ...stuckShipmentMissingFields })
                isHavingIncompleteFields = true;
            }
            if (stuckShipmentState.nodeId.length == 0) {
                stuckShipmentMissingFields["nodeId"] = "Please enter node Id"
                setstuckShipmentMissingFields({ ...stuckShipmentMissingFields })
                isHavingIncompleteFields = true;
            }
            return isHavingIncompleteFields;
        }

        else if (stuckShipmentState.shipmentId.length != 0 && files.length > 0 && stuckShipmentState.nodeId.length != 0) {
            setstuckShipmentMissingFields({ ...stuckShipmentMissingFields, "trackshipmentIdingId": "Both Shipment id ,Node Id and file has been uploaded", "redriveFile": "Both Shipment id  , Node Id  and file has been uploaded" })
            return true;
        }
        return false;
    }

    return (
        <React.Fragment>
            <PageHeaderUtility pageTitle={stuckShipmentsHeaderData.pageTitle} myKey={stuckShipmentsHeaderData.key} />
            <Column alignmentHorizontal="center" spacing="none">
                <SolutionsNabTabComponent tab="Stuck Shipments" />
                <Box className="stuck-shipments-input-container" width={"60%"} type="outline" spacingInset="400">
                    <Row alignmentVertical="center" alignmentHorizontal="center" spacingInset="300 none 300 none">
                        <RadioButtonInlineFormatUtil radioButtonData={clearStuckShipmentType}
                            customisedStyle={{ display: "grid", gridGap: "55px", gridTemplateColumns: "0fr 2fr 2fr" }}
                            value={stuckShipmentState.clearType}
                            setValue={(value: string) => {
                                setstuckShipmentState({
                                    ...stuckShipmentState,
                                    clearType: value
                                })
                            }}

                        />
                    </Row>
                    {stuckShipmentState.clearType === "single" ?
                        <Row alignmentVertical="center" alignmentHorizontal="center" spacingInset="300 none 300 none" >
                            <InputFieldUtil
                                inputFieldData={nodeIdData}
                                value={stuckShipmentState.nodeId}
                                setValue={(value: string) => {
                                    updateStuckShipmentState("nodeId", value)
                                    setstuckShipmentMissingFields({})

                                }}
                                onBlur={() => { }}
                                mask={""}
                                error={stuckShipmentMissingFields.hasOwnProperty('nodeId')}
                                errorMessage={stuckShipmentMissingFields['nodeId']}
                            />
                            <InputFieldUtil
                                inputFieldData={shipmentIdData}
                                value={stuckShipmentState.shipmentId}
                                setValue={(value: string) => {
                                    updateStuckShipmentState("shipmentId", value)
                                    setstuckShipmentMissingFields({})
                                }}
                                onBlur={() => { }}
                                mask={""}
                                error={stuckShipmentMissingFields.hasOwnProperty('shipmentId')}
                                errorMessage={stuckShipmentMissingFields['shipmentId']}
                            />
                        </Row>
                        : <Box spacingInset={"100"}>
                            <Row alignmentHorizontal="center">
                            <Text alignment="center">Choose file for bulk Clear</Text>
                            </Row>
                            <Row alignmentVertical="center" alignmentHorizontal="center" spacingInset="300 none 300 none"    spacing="500">
                                <div>
                                    <FileInput
                                        onFileAttached={(file) => {
                                            onFileAttached(file)
                                            setstuckShipmentMissingFields({})
                                        }}
                                        type="single"
                                        uploadButtonSize="small"
                                    >
                                    </FileInput>
                                    {files.map(file => {
                                        return (
                                            <FileDetails
                                                file={file}
                                                key={file.name}
                                                removeFileButtonLabel={`Delete, ${file.name}`}
                                                onClickRemoveFile={() => {
                                                    const updatedFileArr = files.filter(fl => fl.name !== file.name)
                                                    setFiles(updatedFileArr)
                                                }}
                                                uploadComplete={true}
                                            />
                                        )
                                    })}
                                </div>
                                <div>
                                    <Link href={StuckShipmentsFileUploadUtilData.href}
                                        download={StuckShipmentsFileUploadUtilData.downloadedFileName}> <Icon tokens={infoTokens} /> {StuckShipmentsFileUploadUtilData.linkText}</Link>
                                </div>
                            </Row>
                        </Box>
                    }
                    <Row alignmentHorizontal="center" alignmentVertical="center" spacingInset="300 none 300 none">
                        <Button onClick={() => {
                            handlestuckShipment()
                        }} size="medium" type="primary">
                             {stuckShipmentState.isLoading ?
                                    <> Loading <pre /> <Loader type="circular" size="small" /> </> :
                                    "Clear Stuck Shipments"
                                }</Button>
                       
                    </Row>
                </Box>

            </Column>

            {stuckShipmentState.showResultsInfo ?
                <Row alignmentHorizontal="center"> 
                    {stuckShipmentState.response?.successful || stuckShipmentState.response?.uploaded ?
                        <Box type="outline" spacingInset={"300"}>
                            <Theme tokens={{ boxBackgroundColorOutline: '#77c57f' }}>
                                <Box type="outline" spacingInset="200">
                                    <Text type="h100" alignment="center">
                                       Clear Shipments works successful !
                                    </Text>
                                </Box>
                            </Theme>
                        </Box>
                        :
                        <Box type="outline" spacingInset={"300"}>
                            <Theme tokens={{ boxBackgroundColorOutline: '#feb96c' }}>
                                <Box type="outline" spacingInset="200">
                                    {stuckShipmentState.response?.errorMessage ?
                                        <Text type="h100" alignment="center">
                                            {stuckShipmentState.response?.errorMessage}
                                        </Text>
                                        :
                                        <Text type="h100" alignment="center">
                                            Clear Shipment Redrive Failed . 
                                        </Text>
                                    }
                                </Box>
                            </Theme>
                        </Box>
                    }
                </Row>
                : <></>

            }
        </React.Fragment>
    )
}

export default memo(StuckShipmentsComponent)