import Input from "@amzn/meridian/input";
import React from "react";
import {InputFieldUtilProps} from "./input-field-util.type";

export const InputFieldUtil = ({inputFieldData, value, setValue,onBlur,error, errorMessage, size, mask, placeholder}:InputFieldUtilProps) => {

    return(
        <Input
            id="my-input"
            data-cyid="input-comp"
            value={value}
            onChange={value => setValue(value)}
            onBlur={()=>{onBlur()}}
            type={inputFieldData.type}
            placeholder={placeholder? placeholder :inputFieldData.placeholder}
            label={inputFieldData.label}
            error={error}
            errorMessage={ errorMessage ?? ''}
            size={size == undefined ? "small" : size}
            mask={mask}
        />
    )
}