import { AxiosError } from "axios";
import { apiFatalsMetricsPublisher } from "src/utils/metricUtils";

export default function AxiosResponseError(error: AxiosError): any {
  const Error: AxiosError = error;
  // TODO: Will also implement the authGaurd check for 401 error responses here later
  // TODO: to add different error messages based on the error when given by the business team
  switch (error.code) {
    case AxiosError.ERR_NETWORK: {
      Error.message = "Error in network, Please try again later";
      // TODO: Customize the Fatals for error code and for API call
      apiFatalsMetricsPublisher.publishCounterMonitor("Error", 1)
      break;
    }
    default:
  }
  return Promise.reject(Error);
}
