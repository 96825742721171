import {
    mfnResponseDataType,
    PackageLevelDetailsDataType,
    MfnToolLinksDataType,
    MfnResponseType,
    ShippingLabelInfoType,
    GetShippingLabelInfo,
    CustomError,
    GetResponseTableDataType,
    GetPackageLevelDetailsInfoType
} from './mfn-dao.type';
import {DetailsUtilType} from "src/components/helpers/details-renderer-util/details-renderer-util.type";
import {TableColumns, TableData} from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import {realmToRegionMap} from "src/components/helpers/tool-links-renderer-util/tool-links-renderer.config";
import {generateTableData, generateTableHeaders} from "src/utils/dao-utils";
import { apiFatalsMetricsPublisher } from "src/utils/metricUtils";
import {getRealmFromUrl} from "src/utils/utils";

class mfnSearchResponse {
    response: MfnResponseType;

    constructor(mfnResponse: mfnResponseDataType) {
        //Todo: validating only merchant length once back end sends same response for both EU and NA will revert this change
        //if(!mfnResponse.MerchantInfo.Error && mfnResponse.MerchantInfo.merchantList.length == 0){
        if(mfnResponse.MerchantInfo.merchantList.length == 0){
            this.response = {
                toolLinksData: undefined,
                overviewDetailsData: undefined,
                packageLevelDetailsData: undefined,
                shippingLabelInfoData: undefined,
                error: "Error occurred, the orderID provided is not MFN or is not returning any results"
            }
            apiFatalsMetricsPublisher.publishCounterMonitor("MFN-Failure", 1)
        } else if(mfnResponse.MORSE.Error && mfnResponse.MerchantInfo.Error && mfnResponse.shippingLabelSummary.Error) {
            this.response = {
                toolLinksData: undefined,
                overviewDetailsData: undefined,
                packageLevelDetailsData: undefined,
                shippingLabelInfoData: undefined,
                error: "Error occurred, Can be due to any of the below reasons.:Wrong Region must have been selected: Order ID not valid or is not MFN: Unhandled Exception"
            }
            apiFatalsMetricsPublisher.publishCounterMonitor("MFN-Failure", 1)
        } else {
            this.response = {
                overviewDetailsData: this.generateOverviewLevelDetails(mfnResponse),
                toolLinksData: this.getToolLinksData(mfnResponse),
                shippingLabelInfoData: this.getShippingLabelInfoTableData(mfnResponse),
                packageLevelDetailsData: this.generatePackageDetailsTableData(mfnResponse),
                error: undefined
            };
            apiFatalsMetricsPublisher.publishCounterMonitor("MFN-Success", 1)
        }
    }

    getToolLinksData(data: mfnResponseDataType): MfnToolLinksDataType {
        const trackingId: string = (data.trackingAndCarrier.packageList[0].trackingNumber) ?
            data.trackingAndCarrier.packageList[0].trackingNumber :
            data.trackingAndCarrier.packageList[1].trackingNumber;
        //Todo: need to get the realm value from header
        const realm: string = getRealmFromUrl()
        const region: string = realmToRegionMap[realm]
        const orderId: string = data.MORSE.orderId
        const merchantId: string = data.MerchantInfo.merchantList[0].merchantId
        let defaultToolLinksData: Array<string> = [realm, orderId]
        return {
            'grass-ui': defaultToolLinksData,
            'hank-ui': defaultToolLinksData,
            'mox-console': [region],
            'meta': [region, orderId],
            'merchant-portal': [realm, merchantId],
            'promise-audit-tool': [region, orderId],
            'ape': defaultToolLinksData,
            'oblt': [realm, trackingId]
        }
    }

    generateOverviewLevelDetails(data: mfnResponseDataType): DetailsUtilType {
        let overviewDetailsData =  {
            sectionHeader:"Order level details",
            className: "order-level-details",
            details: {
                "merchantId":{
                    label: "Merchant Id",
                    value: data.MORSE.merchantId,
                    isVisible: true
                },
                "marketplaceId":{
                    label: "Market Place Id",
                    value: data.MORSE.marketplaceId,
                    isVisible: true
                },
                "isMerchantFulfilled":{
                    label: "Fulfillment channel",
                    value: (data.MORSE.isMerchantFulfilled) ? "MFN" : "AFN",
                    isVisible: true
                },
                "isPrime":{
                    label: "is Prime?",
                    value:  data.MORSE.isPrime.toString().valueOf(),
                    isVisible: true
                },
                "marketplaceName":{
                    label: "Marketplace Name",
                    value: data.MORSE.marketplaceName,
                    isVisible: true
                },
                "orderCanceled":{
                    label: "Order Cancelled?",
                    value: data.MORSE.orderCanceled.toString(),
                    isVisible: true
                },
                "orderDateUtc":{
                    label: "Order Date",
                    value: this.getUTCDate(data.MORSE.orderDateUtc),
                    isVisible: true
                },
                "internalEarliestArrivalDateUTC":{
                    label: "Earliest Arrival Date",
                    value: this.getUTCDate(data.MORSE.internalEarliestArrivalDateUTC),
                    isVisible: true
                },
                "internalLatestArrivalDateUTC":{
                    label: "Latest Arrival Date",
                    value: this.getUTCDate(data.MORSE.internalLatestArrivalDateUTC),
                    isVisible: true
                },
                "internalEarliestShipDateUTC":{
                    label: "Earliest Ship Date",
                    value: this.getUTCDate(data.MORSE.internalEarliestShipDateUTC),
                    isVisible: true
                },
                "internalLatestShipDateUTC":{
                    label: "Latest Ship Date",
                    value: this.getUTCDate(data.MORSE.internalLatestShipDateUTC),
                    isVisible: true
                },
                "fulfillmentStatusStr":{
                    label: "Fulfillment Status",
                    value: data.MORSE.fulfillmentStatusStr,
                    isVisible: true
                },
                "shippingConfig":{
                    label: "Shipping Config",
                    value: data.shippingConfigDetails.shippingConfig,
                    isVisible: true
                }
            }
        }
        return overviewDetailsData;
    }

    generatePackageLevelDetails(data: mfnResponseDataType): GetPackageLevelDetailsInfoType{

        let MORSE_data = data.MORSE;
        let PACMAN_data = data.trackingAndCarrier;
        let STS_data = data.carrierStatusAtPackageLevel;

        let packageLevelDetails = [];
        if (!MORSE_data.Error && !PACMAN_data.Error && MORSE_data.morsePackageList.length == PACMAN_data.packageList.length) {
            for (let i = 0; i < PACMAN_data.packageList.length; i++) {

                let currentPackageId = PACMAN_data.packageList[i].packageId;
                let currentPackageCarrierStatus = {}
                let hasSTS_DataWithoutError = (currentPackageId in STS_data && !STS_data[currentPackageId].Error);
                let currentPackageDetails = {
                    packageId: {label: "Package Id", value: currentPackageId, type: 'highLightedText', width: 400, addCopyIcon: true},
                    carrier: {label: "Carrier", value: MORSE_data.morsePackageList[i].carrier, width: 150},
                    shipperTrackingNumber: {
                        label: "Tracking Number",
                        value: MORSE_data.morsePackageList[i].shipperTrackingNumber,
                        width: 250
                    },
                    isShipped: {label: "is Shipped?", value: MORSE_data.morsePackageList[i].isShipped, width: 150},
                    clientName: {
                        label: "Confirm Shipping/ Buy Shipment",
                        value: PACMAN_data.packageList[i].clientName,
                        width: 150
                    },
                    shippingMethod: {
                        label: "Shipping Method",
                        value: MORSE_data.morsePackageList[i].shippingMethod,
                        width: 150
                    },
                }
                if (currentPackageId in STS_data && !STS_data[currentPackageId].Error ) {
                    currentPackageCarrierStatus = {
                        currentStatus: {
                            label: "Current Carrier Status",
                            value: STS_data[currentPackageId].currentStatus,
                            isVisible: hasSTS_DataWithoutError,
                            width: 150
                        },
                        currentStatusCode: {
                            label: "Current Carrier Status code",
                            value: STS_data[currentPackageId].currentStatusCode,
                            isVisible: hasSTS_DataWithoutError,
                            width: 150
                        },
                        isDelivered: {
                            label: "Is Delivered",
                            value: ((STS_data[currentPackageId].trackingEvents[0].depictedState) === "DELIVERED") ? "Yes" : "No",
                            isVisible: hasSTS_DataWithoutError,
                            width: 150
                        }
                    }
                }
                currentPackageDetails = {...currentPackageDetails, ...currentPackageCarrierStatus}
                packageLevelDetails.push(currentPackageDetails)
            }
            return packageLevelDetails as Array<PackageLevelDetailsDataType>;
        }
        else {
            return {
                error: "Found inconsistency in package level data"
            }
        }

    }

    generatePackageDetailsTableData(mfnResponse: mfnResponseDataType): GetResponseTableDataType{
        let getPackageLevelDetails: GetPackageLevelDetailsInfoType = this.generatePackageLevelDetails(mfnResponse);
        if((getPackageLevelDetails as CustomError).error){
            return (getPackageLevelDetails as CustomError)
        }
        else {
            let packageLevelDetails = getPackageLevelDetails as Array<PackageLevelDetailsDataType>
            let tableHeaders: Array<TableColumns> = generateTableHeaders(packageLevelDetails)
            let tableData: Array<Array<TableData>> = generateTableData(packageLevelDetails);
            return {headers: tableHeaders, data: tableData}
        }
    }

    getUTCDate(epoch_date: number | undefined) {
        if(epoch_date == undefined) return "Invalid date";
        let UTCDate = new Date(epoch_date*1000);
        return UTCDate.toUTCString()
    }

    getShippingLabelInfo(mfnResponse: mfnResponseDataType): GetShippingLabelInfo{

        let info = mfnResponse.shippingLabelSummary
        let labelInfo: Array<ShippingLabelInfoType> = []
        if(!info.Error && info.labelSummaryList.length !=0){
            for (let i = 0; i < mfnResponse.shippingLabelSummary.labelSummaryList.length; i++) {
                if(info.labelSummaryList[i].packageId == null) continue;
                labelInfo.push({
                    packageId: {label: "Package" , value: info.labelSummaryList[i].packageId, type: 'highLightedText', width: 400, addCopyIcon: true    },
                    carrierName: {label: "Carrier Name" , value: info.labelSummaryList[i].carrierName, type: 'text', width: 150},
                    refundable: { label: "Is refundable?", value: info.labelSummaryList[i].labelRefundInformation.refundable, type: 'text', width: 150 },
                    refundSLADate: {label: "Refund SLA Date" , value: info.labelSummaryList[i].labelRefundInformation.refundSLADate , type: 'text', width: 150 },
                    labelStatus: {label: "Label Status" , value: info.labelSummaryList[i].labelStatus, type: 'text', width: 150 },
                    shipMethod: {label: "Ship Method" , value: info.labelSummaryList[i].shipMethod, type: 'text', width: 150 },
                    rateItemList: {label: "Rate Item" ,
                        value: info.labelSummaryList[i].rateItemList.map((currentRateItem)=> {
                            return {
                                "rateItemCharge" : {label: "Rate Item Charge value" , value: currentRateItem.rateItemCharge.valueAsBigDecimal, type: 'text', width: 400 },
                                "rateItemChargeUnit" : {label: "Rate Item Charge currency" , value: currentRateItem.rateItemCharge.unit, type: 'text', width: 400},
                                "rateItemID" : {label: "Rate Item ID" , value: currentRateItem.rateItemID, type: 'text', width: 400},
                                "rateItemType" : {label: "Rate Item Type" , value: currentRateItem.rateItemType, type: 'text', width: 400},
                            }
                        })
                        , type: 'tableInModal', width: 150 }
                })
            }
            return labelInfo;
        } else {
            return {
                error: "Could not retrieve Shipping Label information."
            }
        }
    }

    getShippingLabelInfoTableData(mfnResponse: mfnResponseDataType): GetResponseTableDataType{
        let getShippingLabelInfo: GetShippingLabelInfo = this.getShippingLabelInfo(mfnResponse);
        if((getShippingLabelInfo as CustomError).error ){
            return (getShippingLabelInfo as CustomError)
        }
        else {
            let shippingLabelInfo = getShippingLabelInfo as Array<ShippingLabelInfoType>
            let tableHeaders: Array<TableColumns> = generateTableHeaders(shippingLabelInfo);
            let tableData: Array<Array<TableData>> = generateTableData(shippingLabelInfo);
            return {headers: tableHeaders, data: tableData}
        }
    }

}

export default mfnSearchResponse;