import React, { useState, useEffect, memo } from "react"
import { useNavigate } from "react-router-dom"
import { tab } from "src/components/helpers/navtab-util/navTab.util.type";
import { SolutionsNavTabItems } from "src/common/constants/solutions-navTab.const";
import {NavTabComponent} from "src/components/helpers/navtab-util/navTab.util"

const SolutionsNabTabComponent = ({ tab }: tab) => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("");
  useEffect(() => {
    setCurrentTab(tab);
  })

  return (
    <NavTabComponent tab={tab} NavTabItems={SolutionsNavTabItems} />
  )
}
export default memo(SolutionsNabTabComponent);
