import React, { memo, useState, useEffect } from "react";
import { inspectionInputFieldsType, searchIdType } from './inspections.component.type';
import { PageHeaderUtility } from "src/components/helpers/headers.util/headers.util";
import { inspectionHeaderData } from '../../../../common/constants/inspections.const';
import { buttonClickMetricsPublisher } from "src/utils/metricUtils";
import { InspectionInputComponent } from './inspections-input.component';
import { useSearchParams } from "react-router-dom";
import { inspectionApi } from "src/common/api/inspection.api";
import infoTokens from "@amzn/meridian-tokens/base/icon/info";
import "./inspections.component.scss";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import downloadSmallIcon from "@amzn/meridian-tokens/base/icon/download-small";
import { InspectionResponseType } from "src/common/dao/inspections/inspections-dao.type";
import { TableRendererUtil, handleDownloadFileBtnClick } from "src/components/helpers/table-renderer-util/table-renderer-util";
import { useCommonDataContext } from "src/context/CommonDataContext"
import { ROWS_PER_PAGE } from "src/components/helpers/table-renderer-util/table-renderer.config";


const InspectionComponent = () => {
    const today = new Date()
    const previousweek = new Date(today)
    previousweek.setDate(today.getDate() - 6)
    const initialInspectionState : inspectionInputFieldsType = {
        searchType : "userId",
        searchId : "",
        startDate : previousweek.toISOString().split('T')[0],
        endDate : today.toISOString().split('T')[0],
        isLoading : false,
        showResultsInfo : false,
        stateLoadedWithParams : false,
        numberOfPages : 1
    }
    const inspectionResultInitialstate : InspectionResponseType = {
        inspectionData: [],
        inspectionTableHeaders: [],
        error: undefined
    }



   const [inspectionForm,setInspectionForm] = useState<inspectionInputFieldsType>(initialInspectionState);
   const [inspectionMissingFields,setInspectionMissingFields] = useState<Record<string, string>>({});
   const [inspectionResults,setInspectionResults] = useState<InspectionResponseType>(inspectionResultInitialstate)
   const [isSheetOpen, setIsSheetOpen] = useState(false)
   const [searchParams,setSearchParams] = useSearchParams()
   const isUserAuthorized = useCommonDataContext().isUserAuthorizedForInspection
   useEffect(() => {
    if( searchParams.get("searchType") === null) return
    setInspectionForm(prevForm => ({ ...prevForm,
        searchType: searchParams.get("searchType") as searchIdType || "userId",
        searchId: searchParams.get("searchId") || "",
        startDate: searchParams.get("startDate") || today.toLocaleDateString(),
        endDate: searchParams.get("endDate") || today.toLocaleDateString(),
        stateLoadedWithParams: true,
     }))
   },[])

   useEffect(() => {
    if (inspectionForm.stateLoadedWithParams) {
        setInspectionForm({ ...inspectionForm, isLoading: true })
        handleSubmit()
    }
}, [inspectionForm.stateLoadedWithParams])

   const getInspectionIncompleteFields = () : boolean => {
    let incompleteFields: Record<string,string> = {}
    let isHavingIncompleteFields: boolean = false;
    const USER_ID_PATTERN = /^[a-zA-Z]+$/
    const LOCATION_PATTERN = /^[a-zA-Z0-9]+$/
    const checkAndSetMissingFields = ( field: string, condition: boolean, errorMessage: string): void  => {
        if(condition)
        {
            incompleteFields[field] = errorMessage,
            setInspectionMissingFields({...incompleteFields})
            isHavingIncompleteFields = true
        }
    }

    checkAndSetMissingFields("searchId", !inspectionForm.searchId && inspectionForm.searchType === "userId", "User Id cannot be null")
    checkAndSetMissingFields("searchId", !inspectionForm.searchId && inspectionForm.searchType === "location", "FC/SC(location) cannot be null")
    checkAndSetMissingFields("searchId", !inspectionForm.searchId && inspectionForm.searchType === "packageId", "Package Id cannot be null")
    checkAndSetMissingFields("searchId", inspectionForm.searchType === "userId" && inspectionForm.searchId!== "" && (!USER_ID_PATTERN.test(inspectionForm.searchId)), "Invalid User Id format. It should contain only alphabetical characters (A-Z, a-z).")
    checkAndSetMissingFields("searchId",inspectionForm.searchType === "location" && inspectionForm.searchId!== "" && (!LOCATION_PATTERN.test(inspectionForm.searchId)),"Invalid FC/SC format. It should contain only alphabetical and numerical characters (A-Z,a-z,0-9).")
    checkAndSetMissingFields("startDate", !inspectionForm.startDate, "Please select From date")
    checkAndSetMissingFields("endDate", !inspectionForm.endDate, "Please select To date")
    checkAndSetMissingFields("startDate",inspectionForm.startDate > inspectionForm.endDate,"From date shouldn't be greater than To date")
    setInspectionMissingFields({...incompleteFields})
    return isHavingIncompleteFields
   }

   const getDownloadDataBtn=(tableResponseForExport:InspectionResponseType)=>{
    return <div style={{ marginBottom: "5px"}}>
        <Row alignmentHorizontal="end"  className="actions" >
            <Button  size="small" 
                onClick={() =>{
                    handleDownloadFileBtnClick(tableResponseForExport.inspectionTableHeaders,tableResponseForExport.inspectionData)
                }}>
                <Icon tokens={downloadSmallIcon}/>Export to CSV 
            </Button>
        </Row>
    </div>
}

   const handleSubmit = () => {
    if(getInspectionIncompleteFields())
    {
        setInspectionForm({ ...inspectionForm,isLoading:false})
        return
    }


    const updateResultMessage = (errorMessage : string|undefined) : string|undefined => {
        let message = errorMessage
        if(errorMessage){
            if(inspectionForm.searchType === "userId")
                message = `No results were found for user ${inspectionForm.searchId} as they didn't use the GetItems details in EagleEye for the selected time range.`
            else if(inspectionForm.searchType === "location")
                message = `No results were found as no user utilized the GetItems details from ${inspectionForm.searchId} in EagleEye for the selected time range.`
            else
                message = `No results were found as no user utilized the GetItems details for ${inspectionForm.searchId} in EagleEye for the selected time range.`
        }
        return message

    }

    const startTime = new Date(inspectionForm.startDate)
    const fromTimeStamp = startTime.getTime()/1000
    const endTime = new Date(inspectionForm.endDate)
    const toTimeStamp = endTime.getTime()/1000 + 24*60*60

    buttonClickMetricsPublisher.publishCounterMonitor("Inspection-search",1)
    if(isUserAuthorized){
        inspectionApi(inspectionForm.searchType,inspectionForm.searchId,fromTimeStamp,toTimeStamp).then((response:InspectionResponseType)=>{
        setInspectionResults({
            inspectionData: response.inspectionData,
            inspectionTableHeaders: response.inspectionTableHeaders,
            error: updateResultMessage(response.error)
        })
        setInspectionForm({ ...inspectionForm,
            numberOfPages:Math.ceil(response.inspectionData.length / ROWS_PER_PAGE),
            isLoading:false,
            showResultsInfo:true })
        }).catch((err)=>{
            setInspectionResults({
                ...inspectionResults,
                error: err
            })
            setInspectionForm({...inspectionForm,isLoading:false,showResultsInfo:true})

        })
    }
    else{
         setInspectionResults({
             ...inspectionResults,
             error: "Sorry! You are not authorised to view Inspections page. Please reach out to eagleeyeteam@amazon.com for more details."
         })
         setInspectionForm({...inspectionForm,isLoading:false,showResultsInfo:true})
     }
    return
   }

    return (
        <React.Fragment>
            <PageHeaderUtility pageTitle={inspectionHeaderData.pageTitle} myKey={inspectionHeaderData.key} />
            <InspectionInputComponent
                inspectionForm = {inspectionForm}
                setInspectionForm = {setInspectionForm}
                inspectionMissingFields = {inspectionMissingFields}
                setInspectionMissingFields = {setInspectionMissingFields}
                isSheetOpen={isSheetOpen}
                setIsSheetOpen={setIsSheetOpen}
                handleSubmit = {handleSubmit}
            />

            {inspectionForm.showResultsInfo && (inspectionResults.error ? 
            <div className={"inspection-search-results"}>
                <Box type="outline" spacingInset={"300"}  backgroundColor="#f9fad2;">
                <Row alignmentHorizontal="center" spacing={"300"}>
                    <Icon tokens={infoTokens} />
                    <Text>{inspectionResults.error}</Text>
                </Row>
                </Box>
            </div> : 
            <div className="inspection-search-results" >
            {getDownloadDataBtn(inspectionResults)}
            <Row alignmentHorizontal="center">
            <Box type="outline"> 
                    <div className="table-render">
                        <TableRendererUtil
                            tableData={inspectionResults.inspectionData}
                            tableHeaders={inspectionResults.inspectionTableHeaders}
                            onFiltersClick={()=>{}}
                            showActionBarComponents={{
                                "showActionBar": false,
                                "showSearchBar": false,
                                "showFilters": false,
                                "showDownloadButton": false,
                                showColumnFilters:true
                            }}
                            numberOfPages={inspectionForm.numberOfPages}
                            isRowHeader={true}

                        ></TableRendererUtil>
                    </div>
            </Box>
            </Row>
            </div>
            )
            }
        </React.Fragment>
    )
   
}

export default memo(InspectionComponent)




