import React, { useCallback, useState } from 'react'
import Link from "@amzn/meridian/link"
import Box from "@amzn/meridian/box"
import Text from "@amzn/meridian/text"
import { LinkRendererPropsType, ToolLinkPropsType } from "src/components/helpers/tool-links-renderer-util/tool-links-renderer-util.type"
import { toolLinksTemplates } from 'src/components/helpers/tool-links-renderer-util/tool-links-renderer.config'
import "./tool-links-renderer-util.scss"
import Theme from '@amzn/meridian/theme'
import { buttonClickMetricsPublisher } from 'src/utils/metricUtils'
import {ModalUtil} from "src/components/helpers/modal-util/modal-util";
import {ManifestDetails} from "src/pages/visibility-page/components/afn/results/afn-manifest-details.component";
import Tooltip from "@amzn/meridian/tooltip"
import { toolTipDisplay } from 'src/pages/visibility-page/components/afn/afn.config'

export const ToolLinksRenderer = ({ toolLinksDataMap }: ToolLinkPropsType) => {
    const [openModal, setOpenModal] = useState<Record<string,boolean>>({});
    const getToolLinks = () => {
        //Todo: hardcoded stage but not used
        const stage = 'prod'
        return Object.keys(toolLinksDataMap).map((toolName: string) => {
            if (toolLinksTemplates[toolName]) {
                if (toolLinksTemplates[toolName].label === "Manifest Visibility") {
                return (
                <Theme tokens={{textFontSizeB100:"12px", linkForegroundColorDefault:"#0000EE"}}> 
                <Text type="b100" className="links" >
                <ModalUtil openModal={openModal[toolLinksTemplates[toolName].label.toString()]}
                    title={"Manifest details"}
                    setOpenModal={(val: boolean) => { setOpenModal({ ...openModal, [toolLinksTemplates[toolName].label.toString()]: val }) }}
                >
                    <ManifestDetails realm={toolLinksDataMap[toolName][0]} shipmentId={toolLinksDataMap[toolName][1]}></ManifestDetails>
                </ModalUtil>
                <Tooltip position="end" title={toolTipDisplay.manifestDetails}> 
                <Link onClick={useCallback(() => setOpenModal({...openModal, [toolLinksTemplates[toolName].label.toString()]: true}), [])}>{toolLinksTemplates[toolName].label}</Link>
                </Tooltip>
                </Text>
                </Theme>
                )
            }
                return <LinkRenderer key={toolName + "-key"} toolName={toolName} args={toolLinksDataMap[toolName]} />
            }
        })
    }

    return (
        <Box type={"outline"} spacingInset={"100"}>
            <div className="tool-links-data" >
                {getToolLinks()}
            </div>
        </Box>
    )
}

export const LinkRenderer = ({ toolName, args }: LinkRendererPropsType) => {
    /*
        This function is a replication of String.format() in Java where a string contents such as {0}
        is replaced with the value in index of the args. if there are {0} twice in string template the 
        value at index 0 will be used 2 times.
    */
    const getToolLink = (toolLink: string, args: Array<string | number>) => {
        return toolLink.replace(/{([0-9]+)}/g, function (match: any, index: any) {
            return args[index] == null ? match : args[index]
        })
    }

    const getStageUrl = () => {
        const stage: string = 'prod'
        return stage === 'prod' ? toolLinksTemplates[toolName].prodUrl : toolLinksTemplates[toolName].integUrl
    }

    return (
        <Theme tokens={{ textFontSizeB100: "12px", linkForegroundColorDefault: "#0000EE" }}>
            <Text type="b100" >
                <a
                    onClick={() => {
                        buttonClickMetricsPublisher.publishCounterMonitor(toolLinksTemplates[toolName].label.replace(/\s/g, ""), 1)
                    }}
                    type="primary"
                    aria-describedby="myTooltip"
                    href={getToolLink(getStageUrl(), args)} target="_blank" rel="noopener noreferrer">{toolLinksTemplates[toolName].label}</a>
            </Text>
        </Theme>
    )

}