import React, { useEffect } from "react";
import Text from "@amzn/meridian/text";
import {PageHeaderUtilityPropsType} from "src/components/helpers/headers.util/headers.util.type";
import "./headers.util.scss"
import Link from "@amzn/meridian/link"
import exportSmallTokens from "@amzn/meridian-tokens/base/icon/export-small"
import Icon from "@amzn/meridian/icon"
import Tooltip from "@amzn/meridian/tooltip";


export const PageHeaderUtility = ({pageTitle,myKey,documentationLink,toolTipTitle}:PageHeaderUtilityPropsType) => {
    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    return (
        <h1 className="page-header" key={myKey}>
        <Text type="h300">{pageTitle}
            {documentationLink &&
                <Link href={documentationLink} target="_blank" type="secondary">
                   <Tooltip position="end" title={toolTipTitle} id="myTooltip">
                        <Icon className="links-icon" tokens={exportSmallTokens} />
                    </Tooltip>
                </Link>
            }
        </Text>
    </h1>
    )
}