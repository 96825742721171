import { afnInputFieldsType } from "src/pages/visibility-page/components/afn/afn.component.type";
import { sanitizeInput } from "src/utils/input-santize-util";
class afnSearchRequest {
    request: any;
    constructor(afnForm: afnInputFieldsType) {

        switch (afnForm.shipmentType) {
            case "scannable": {
                this.request = { "scannableIds": convertSearchIdToArray(afnForm.searchId), "multipleVersions": afnForm.multipleVersionDataCheckbox }; break;
            }
            case "container": {
                this.request = { "containerIds": convertSearchIdToArray(afnForm.searchId), "nodeId": afnForm.nodeInfo }; break;
            }
            case "transshipment": {
                this.request = { "containerIds": convertSearchIdToArray(afnForm.searchId) }; break;
            }
            case "shipment": {
                this.request = { "shipmentIds": convertSearchIdToArray(afnForm.searchId), "multipleVersions": afnForm.multipleVersionDataCheckbox }; break;
            }
            case "resourceId": {
                this.request = { "resourceId": afnForm.searchId, "nodeId": afnForm.nodeInfo }; break;
            }
            case "pkglegdetail": {
                this.request = { "trackingIds": convertSearchIdToArray(afnForm.searchId) }; break;
            }
            case "trailer": {
                this.request = { "trailerIds": convertSearchIdToArray(afnForm.searchId), "nodeId": afnForm.nodeInfo }; break;
            }
            case "vrid": {
                this.request = { "vrids": convertSearchIdToArray(afnForm.searchId), "nodeId": afnForm.nodeInfo }; break;
            }

        }
    }
}
export const convertSearchIdToArray = (searchId: string) => {
    const delimiters = /[\n\t,; ]+/; // Character class with \n, \t, space, ;, and ,
    searchId =sanitizeInput(searchId)
    return searchId
        .replace(/^\s+/gm, '') // Triming all the starting spaces
        .replace(/\s+$/gm, '') // Triming all the ending spaces
        .split(delimiters) // splitting the string based on the delimeters using regex
        .filter((searchId) => searchId !== '')
        .filter((item, pos, self) => self.indexOf(item) === pos); // filtering out the duplicate entries
}

export default afnSearchRequest;