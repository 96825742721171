import { DropdownDataType } from "src/components/helpers/dropdown-util/dropdown-util.type"
import { InputFieldDataType } from "src/components/helpers/input-field-util/input-field-util.type"
import {RadioButtonDataType} from "src/components/helpers/radio-button.util/radio-button.util.type"

export const STR_ALL_WORKFLOWS = 'all-workflows'
export const STR_FAILED_WORKFLOWS = 'failed-workflows'

export const ofisHeaderData = {
    pageTitle: "EagleEye Visibility - OFIS",
    key: "ofis-page-header",
    documentationLink: "https://w.amazon.com/bin/view/TransportationFrontline/OBLT_Failure_Identification/Usage&FAQ/",
    toolTipLinkTitle: "OFIS Documentation"
}

export const ofisBetaLink = "https://eagleeye-integ.amazon.dev/ofis?lang=en_US&region=NA"

export const workflowRadioButtonData: RadioButtonDataType= {
    label: "Workflow",
    radioButtonValues: [
        {
            visibleText: "All workflows",
            value: "all-workflows"
        },
        {
            visibleText: "Failed Workflows",
            value: "failed-workflows"
        }
    ]
}

// TODO: These options are obtained dynamically from API response. Update when integrating with backend
export const packageStatusDropdownData: DropdownDataType = {
    label: "Package Status",
    placeholder: "Select Package Status",
    errorMessage: "",
    dropdownOptions: [
        {
            visibleText: "Pending Tracking",
            value: "pending-tracking"
        }, {
            visibleText: "Delivered",
            value: "delivered"
        }
    ]
}

export const workItemStatusDropdownData: DropdownDataType = {
    label: "WorkItem Status",
    placeholder: "Select WorkItem Status Items",
    errorMessage: "",
    dropdownOptions: [
        {
            visibleText: "Error Closed",
            value: "error-closed"
        }, {
            visibleText: "Closed",
            value: "closed"
        }
    ]
}

export const workItemStatusReasonDropdownData: DropdownDataType = {
    label: "WorkItem Status Reason",
    placeholder: "Select WorkItem Status Reason",
    errorMessage: "",
    dropdownOptions: [
        {
            visibleText: "Success",
            value: "Success"
        }, {
            visibleText: "TrackingFailureHardException",
            value: "TrackingFailureHardException"
        }
    ]
}

export const carrierLabelDropdownData: DropdownDataType = {
    label: "Carrier Label",
    placeholder: "Select Carrier Label",
    errorMessage: "",
    dropdownOptions: [
        {
            visibleText: "La Poste",
            value: "La-Poste"
        }, {
            visibleText: "Yamato",
            value: "yamato"
        }
    ]
}

export const ofisSearchIdData: InputFieldDataType = {
    label: "",
    type: "text",
    errorMessage: "Please enter Order Id",
    placeholder: "Enter Order Id"
}


// TODO: Check if you want to Remove this and add it into Lambdas or from here only in that case lambdas can be a pass through
export const FACET_FIELDS_BY_DOCTYPE_MAP = {
    STATUS: ["scac_code_f","tmpp_workitem_status_reason_f","tmpp_workitem_status_f","trading_partner_f","tmpp_ticket_id_f","sender_id"],
    MANIFEST: ["tmpp_workitem_status_reason_f","manifest_type_f","tmpp_workitem_status_f","trading_partner_f","transport_carrier_id_f","warehouse_id_f"],
    CDEX_STATUS: ["tmpp_workitem_status_reason_f","tmpp_workitem_status_f","carrier_f","carrier_id_f","pkg_status_f","tmpp_ticket_id_f"],
    CDEX_MANIFEST: ["tmpp_workitem_status_reason_f","tmpp_workitem_status_f","tmpp_ticket_id_f","realm_f"],
    TRANSSHIP_MANIFEST: ["destination_fc_f","tmpp_workitem_status_reason_f","tmpp_workitem_status_f","trading_partner_f","tmpp_ticket_id_f"],
    MFN_PRIORITY_UPDATE: ["scac_f","tmpp_ticket_id_f"],
    MFN_API_SCAN_PULLER: ["tmpp_workitem_status_reason_f","tmpp_workitem_status_f","carrier_f","carrier_id_f","pkg_status_f","tmpp_ticket_id_f"]
};