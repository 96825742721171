import React, { createContext, useContext, useEffect, useState } from "react"
import {checkIsUserAuthorized} from "../utils/custom-utils"
import { inspectionBindleId, reportBindleId } from "../common/constants/inspections.const";

export type commonDataType = {
    isUserAuthorizedForInspection: boolean,
    isUserAuthorizedForActivityReport: boolean

}

export const defaultCommonDataInfo:commonDataType = {
    isUserAuthorizedForInspection: false,
    isUserAuthorizedForActivityReport: false
}

export const commonDataContext = createContext(defaultCommonDataInfo)

export const useCommonDataContext = () => useContext(commonDataContext)

export const CommonAttributeContext = (props : any) => {
    const [data, setData] = useState(defaultCommonDataInfo)

    useEffect(()=>{
        const fetchData = async () => {
            const authForInspection = await checkIsUserAuthorized(inspectionBindleId);
            const authForReport = await checkIsUserAuthorized(reportBindleId);
            setData({
                isUserAuthorizedForActivityReport: authForReport,
                isUserAuthorizedForInspection: authForInspection,
            });
            
        }
        fetchData()
    }, []);

    return (
        <commonDataContext.Provider value={data}>
            {props.children}
        </commonDataContext.Provider>
    )
}


