import { itemDetailsRequestType } from "./afn-dao.type"

export class ItemDetailsRequest {
    request: itemDetailsRequestType
    constructor(scannableId: string | Array<string>) {
        let requestIds: Array<string> = []
        if (typeof scannableId == "string") {
            requestIds.push(scannableId)
        }
        else {
            requestIds = scannableId
        }
        this.request = {
            packageIds: requestIds
        }
    }
}