import React, { memo } from "react"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import {footerLinks} from "src/common/constants/footer.const"
import exportSmallTokens from "@amzn/meridian-tokens/base/icon/export-small"
import Icon from "@amzn/meridian/icon"
import { HideForAftxUsers } from "src/utils/aftx-utils";
import Theme from "@amzn/meridian/theme"
import { TokenObject } from "src/components/helpers/button-util/button-util.type"
import logo from 'src/images/eagleeye_gradient.png';

import './app-footer.component.scss'

const AppFooterComponent = () => {
    const customTokens:TokenObject = {
        linkForegroundColorDefault: "#ffffff",
        linkForegroundColorHover: "#51E1ED",
        textColorPrimary: "#ffffff",
        textFontSizeB100:"14px"
    }

    return (
        <Theme tokens={customTokens}>
            <div className="footer">
                <section className="eagle-eye">
                <img src={logo} alt="Eagle Eye" className="logo" />  
                </section>
                <HideForAftxUsers>
                    {
                    footerLinks.Items.map(item => {
                        return (
                            <Text type="b100" className="links">
                                <Link href={item.value} target="_blank" type="secondary">{item.visibleText}
                                    <Icon className="links-icon" tokens={exportSmallTokens}/>
                                    |
                                </Link>
                            </Text>
                        )
                    }) }
                </HideForAftxUsers>
            </div>
        </Theme>
    )
}

export default memo(AppFooterComponent)