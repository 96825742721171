import React from "react"
import Toaster from "@amzn/meridian/toaster"
import Alert from "@amzn/meridian/alert"
import {ToasterUtilType} from "src/components/helpers/toaster-util/toaster-util.type";

export const ToasterUtil = ({toasts, setToasts, type, toasterAlignmentHorizontal, toasterAlignmentVertical, toasterPosition}:ToasterUtilType) => {

    return (
        <React.Fragment>
            <Toaster
                onCloseToast={ id => setToasts(toasts.filter(t => t.id !== id))}
                toasts={toasts}
                alignmentHorizontal={toasterAlignmentHorizontal}
                alignmentVertical={toasterAlignmentVertical}
                position={toasterPosition}
            >
                {toast => (
                    <Alert key={toast.id}
                           type={type}
                           onClose={ id => setToasts(toasts.filter(t => t.id !== id))}
                           toast={true}
                    >
                        {toast.message}
                    </Alert>
                )}
            </Toaster>
        </React.Fragment>
    )
}
