// React Imports
import React, { useEffect, useState } from "react";
import { manifestDetailsApi } from "src/common/api/afn.api";
import { infoDataType, styleKeys } from "src/common/dao/mfn/mfn-dao.type";
import { TableRendererUtil } from "src/components/helpers/table-renderer-util/table-renderer-util";
import "src/index.scss";
import { translateStrings } from "src/utils/dao-utils";
import { showActionBarComponentsForTableType } from "../afn.component.type";
import Loader from "@amzn/meridian/loader";
import Text from "@amzn/meridian/text";
import { getRealmForRegion } from "src/utils/utils";

export const ManifestDetails = (props: any) => {
    let { realm, shipmentId } = props;
    const region = realm == "fe" ? "jp" : realm
    useEffect(() => {
        manifestDetailsApiCall();
    }, []);
    const [manifestData, setManifestData] = useState<any>(null);
    const manifestDetailsApiCall = async () => {
        const data = await manifestDetailsApi(shipmentId);
        setManifestData(createManifestGrid(data));
    };

    function createManifestGrid(manifestData: any): any {
        if (manifestData.message) return ""
        let manifestTableList: Array<Record<string, infoDataType>> = []
        let styles: Record<styleKeys, string> = {
            wordWrap: 'break-word',
            fontSize: '13px'
        }
        Object.keys(manifestData["legs"]).map((leg: any) => {
            if (manifestData["legs"][leg]["manifestDetails"]) {
                Object.keys(manifestData["legs"][leg]["manifestDetails"]).map((id: any) => {
                    let manifestInfo = manifestData["legs"][leg]["manifestDetails"][id]
                    manifestTableList.push(translateStrings({
                        nodeId: { label: "Node", value: manifestData["legs"][leg]["leg"], styles },
                        manifestId: { label: "Manifest ID", value: manifestInfo["manifestId"]? manifestInfo["manifestId"]: "", styles },
                        documentType: { label: "Document Type", value: manifestInfo["documentType"]? manifestInfo["documentType"]: "", styles },
                        transmissionType: { label: "Transmission Type", value: manifestInfo["EDITransmissionDetails"] && manifestInfo["EDITransmissionDetails"]["Error"] != true ? "EDI" : "", styles },
                        transmissionStatus: { label: "Transmission Status", value: getTransmissionStatus(manifestInfo["EDITransmissionDetails"], manifestInfo["manifestId"], manifestInfo["fileName"]), styles },
                    }))
                })
            }
        })
        if (manifestTableList.length === 0) {
            return [{ label: "Manifest details", value: "No manifest details found.", type: "highLightedText", width: 20 }];
        }
        return [{ label: "Manifest details", value: manifestTableList, type: "tableInCell", width: 200 }];
    }
    const showActionBarComponentsForTable: showActionBarComponentsForTableType = {
        "showActionBar": false,
        "showSearchBar": false,
        "showFilters": false,
        "showDownloadButton": false,
        showColumnFilters:true
    }

    function getTransmissionStatus(EDITransmissionDetails: any, manifestId: string, fileName: any): string {
        if (!EDITransmissionDetails)
            return ""
        
        if (EDITransmissionDetails["Error"] && fileName) {
            let irisLink = `<a href="https://${getRealmForRegion(region)}.prod.transcentral.ctt.amazon.dev/message-listing?operation=OR&FILE_NAME=${fileName}&type=relative&amount=1&unit=month&key=one-month" target="_blank">IRIS</a>`;
            return `Not via eiConsole, check in ${irisLink}`;
        }
        const status = EDITransmissionDetails["transmissionStatus"];
        if (status === "Restricted") return status

        if (status === "Delivered") {
            const timestamp = new Date(EDITransmissionDetails.deliveryCompleteTimestamp || "");
            const startDate = new Date(timestamp);
            const endDate = new Date(timestamp);
            startDate.setDate(timestamp.getDate() - 10);
            endDate.setDate(timestamp.getDate() + 10);

            const dateToString = (date: Date) =>
                `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}+12%3A00+PM`;

            const eiConsolelink = `<a href="https://eisws-console${region === "na" ? "" : `-${region}`}.amazon.com/console/search?date=&documentType=&endDate=${dateToString(
                endDate
            )}&objects=${EDITransmissionDetails.triggerEvent}&inDays=true&forceSearch=false&allDocTypes=false&summaryOffset=0&businessUnitId=&objectType=&eventsOnly=false&allAccountOrExchangeIds=false&encrypt=false&allEventNames=false&searchDateMode=byDateRange&lookBack=true&eventName=true&searchArchive=false&searchEventCountOnly=false&searchOption=&businessUnitName=&searchForward=true&accountOrExchangeId=&allBusinessUnits=false&timespan=&exchangeName=&startDate=${dateToString(
                startDate
            )}&eventsOffset=0" target="_blank">`;

            const receiptId = EDITransmissionDetails.receiptId;
            if (receiptId) {
                const eiConsolelinkWithReceipt = `${eiConsolelink}${receiptId}</a>`;
                return `Delivered to the Carrier <br> <b> MDN:</b> ${eiConsolelinkWithReceipt}`;
            } else {
                const eiConsolelinkForSFTP = `${eiConsolelink}Delivered</a>`;
                if (EDITransmissionDetails.receiverEndpointReference.includes("SFTP"))
                    return `${eiConsolelinkForSFTP} to the Carrier (SFTP Endpoint)`;
                else
                    return "Delivered to the Carrier";
            }
        }

        else if (status === "Error") {
            const eventId = EDITransmissionDetails.eventId || manifestId;
            const tt_link = `<a href="https://t.corp.amazon.com/issues?q=extensions.tt.status%3A%28Assigned%20OR%20Researching%20OR%20%22Work%20In%20Progress%22%20OR%20Pending%20OR%20Resolved%20OR%20Closed%29%20AND%20currentSeverity%3A%281%20OR%202%20OR%203%20OR%204%20OR%205%20OR%202.5%20OR%20N%29%20AND%20full_text%3A%28${eventId}%29%20AND%20extensions.tt.assignedGroup%3A%28TransEDI_Operations%20OR%20EDITransportation%29" target="_blank"> link </a>`;
            return `Error <br> <b> Trouble Ticket: </b>${tt_link}`;
        }

        else if (status === "PendingDelivery"){
            const tt_link = `<a href="https://t.corp.amazon.com/issues?q=extensions.tt.status%3A%28Assigned%20OR%20Researching%20OR%20%22Work%20In%20Progress%22%20OR%20Pending%29%20AND%20currentSeverity%3A%281%20OR%202%29%20AND%20full_text%3A%28${EDITransmissionDetails.originalReceiverBusinessUnitReference}%29%20AND%20extensions.tt.assignedGroup%3A%28TransEDI_Operations%20OR%20EDITransportation%29" target="_blank">link</a>`;
            return `Pending Delivery <br> <b> Trouble Ticket: </b>${tt_link}`;
        }
        else {
            return status
        }
    }

    return (
        <div className="table-render">
            {
                manifestData === null &&
                <Text alignment="center">
                        <pre /> 
                        <Loader type="circular" size="large" /> 
                     Loading... this may take a while... 
                </Text>
            }
            {
                manifestData &&
                <TableRendererUtil
                    tableData={[manifestData]}
                    onFiltersClick={() => { }}
                    tableHeaders={[]}
                    showActionBarComponents={showActionBarComponentsForTable}
                    numberOfPages={-1}
                    onTablePageChange={() => { }}
                    isRowHeader={true}
                />
            }
        </div>
    );
};

