import React, { useState, useEffect, memo } from "react"
import {useNavigate} from "react-router-dom"
import SideMenu, { SideMenuTitle, SideMenuLink } from "@amzn/meridian/side-menu"
import Row from "@amzn/meridian/row"
import { SideMenuComponentProps } from "./side-menu.component.type"
import { getLangAndRegionQueryParam } from "src/utils/utils"

const SideMenuComponent = ({handleMenuButton, isMenuOpen, SideBarMenuItems, logo }: SideMenuComponentProps) => {

    const navigate = useNavigate();
    const [hash, setHash] = useState(null)
    const [openLinkHash, setOpenLinkHash] = useState(null)
    // need to add the tokens for styling the side menu 
    return (
        <Row alignmentVertical="stretch" spacing="none">
            <SideMenu open={isMenuOpen} onClose={() => handleMenuButton(!isMenuOpen)} type="overlay">
                <SideMenuTitle  onClick={setHash}>
                    <img src={logo} className="logo"/>
                </SideMenuTitle>

                { SideBarMenuItems.map((item) => {
                        return (
                            item.hasAccess && (item.childItems.length !=0) && <SideMenuLink
                                key={item.label}
                                href={item.ref}
                                selected={hash === item.ref}
                                open={openLinkHash === item.ref}
                                onClick={hash => {
                                    setHash(hash)
                                    setOpenLinkHash(openLinkHash === item.ref ? null : hash)
                                }}
                            > {item.label}
                                {item.childItems.map((childItem) => {
                                    return (
                                        childItem.hasAccess && <SideMenuLink
                                            key={childItem.label}
                                            href={childItem.ref}
                                            selected={hash === childItem.ref}
                                            onClick={hash => {
                                                setHash(hash)
                                                navigate(childItem.path + getLangAndRegionQueryParam(window.location.search))
                                                handleMenuButton(!isMenuOpen)
                                            }}
                                        >
                                            {childItem.label}
                                        </SideMenuLink>
                                    )
                                })}
                            </SideMenuLink>
                        )
                    })
                }
            </SideMenu>
        </Row>
    )
}

export default memo(SideMenuComponent)