import { inspectionRequestType } from "./inspections-dao.type";
import { inspectionInputFieldsType, searchIdType} from "../../../pages/visibility-page/components/inspections/inspections.component.type"
import { sanitizeInput } from "src/utils/input-santize-util";


class inspectionsRequest {
    request : inspectionRequestType
    constructor(searchType: searchIdType,searchId: string, fromTimeStamp: number, toTimeStamp: number){
        searchId = sanitizeInput(searchId)
        const user = searchType === "userId" ? searchId.toLowerCase() : ""
        const packageID = searchType === "packageId" ? searchId : ""
        const FC = searchType === "location" ? searchId.toUpperCase() : ""
        this.request = {
            userId : user,
            location : FC,
            packageId : packageID,
            startTime : fromTimeStamp,
            endTime : toTimeStamp
        }

    }
    
}

export default inspectionsRequest