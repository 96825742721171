import { axiosGet, axiosPost } from "../axios/axios-api-wrapper.hook";
import { searchIdType } from "../../pages/visibility-page/components/inspections/inspections.component.type";
import inspectionsRequest from "../dao/inspections/inspections-request.dao";
import {getEECSApiForRegion} from "src/utils/utils";
import { katalLoggerInfo } from "src/logger";
import inspectionsResponse from "../dao/inspections/inspections-response.dao";
import { searchType} from "src/pages/visibility-page/components/activityreport/activityreport.component.type";
import activityReportRequest from "../dao/activityreport/activityreport-request.dao";
import activityReportResponse from "../dao/activityreport/activityreport-response.dao";
import { activityReportResponseType } from "../dao/activityreport/activityreport-dao.type";


export const inspectionApi = (searchType : searchIdType, searchId : string, fromTimeStamp: number, toTimeStamp: number) => {
    const inspectionSearchParams = new inspectionsRequest(searchType,searchId,fromTimeStamp,toTimeStamp).request
    const requestStartTime = Date.now()
    katalLoggerInfo(window.location.href, "inspectionAPI => Request", inspectionSearchParams)
    return axiosPost(
        getEECSApiForRegion(),
        `inspections`,
        inspectionSearchParams
        ).then((response : any)=>{
            const requestTime = Date.now() - requestStartTime
            katalLoggerInfo(window.location.href, "inspectionAPI => Response",response)
            return (new inspectionsResponse(response)).response;
        }).catch((error: any)=>{
            return error
        })
};

export const userActivityApi = (searchType: searchType, searchId: string, fromTimeStamp: number, toTimeStamp: number) => {
    const userActivitySearchParams = new activityReportRequest(searchType,searchId,fromTimeStamp,toTimeStamp).request;
    const requestStartTime = Date.now();
    katalLoggerInfo(window.location.href, "UserActivity Inspection => Request", userActivitySearchParams);
    return axiosPost(
        getEECSApiForRegion(),
        `userActivity`,
        userActivitySearchParams
        ).then((response : any)=>{
            const requestTime = Date.now() - requestStartTime
            katalLoggerInfo(window.location.href, "inspectionAPI => Response",response)
            return (new activityReportResponse(response)).response;
        }).catch((error: any)=>{
            const error_result : activityReportResponseType = {
                activityReportData: [],
                activityReportDataCount: 0,
                activityReportTableHeaders: [],
                error: error["response"]["data"]["msg"]
            }
            return error_result
        });
}

export const canUserUnlockBindle = (bindleId: string) => {
    return axiosGet(
        getEECSApiForRegion(),
        `unlockBindle//${bindleId}`,
    ).then((response:any) => {
        katalLoggerInfo(window.location.href, "canUnlockBindle => Response", response)
        return response;
    }).catch((error: any) => {
        return error
    })
};