export interface RateLimitingConfigs {
    apiName: string;
    maxAllowedCalls: number;
    durationWindowInSeconds: number;
    callWeight: number;
  }

export const rateLimitApiCall = (rateLimittingConfigs:RateLimitingConfigs) => {
    const {
      apiName,
      maxAllowedCalls,
      durationWindowInSeconds,
      callWeight
    } = rateLimittingConfigs;
  
    const currentTimeInMillis = Date.now();
    const lastCallTimeInMillis = Number(localStorage.getItem(`lastCallTime#${apiName}`));
    let callsMadeTillNow = Number(localStorage.getItem(`callCounter#${apiName}`));
  
    if (lastCallTimeInMillis === 0 || (currentTimeInMillis - lastCallTimeInMillis) >= durationWindowInSeconds * 1000) {
      localStorage.setItem(`lastCallTime#${apiName}`, currentTimeInMillis.toString());
      localStorage.setItem(`callCounter#${apiName}`, "0");
      callsMadeTillNow = 0;
    }
  
    if (callsMadeTillNow < maxAllowedCalls && (lastCallTimeInMillis - currentTimeInMillis) < durationWindowInSeconds * 1000) {
      callsMadeTillNow += callWeight;
      localStorage.setItem(`callCounter#${apiName}`, callsMadeTillNow.toString());
      return true;
    }
  
    const remainingTime = durationWindowInSeconds - Math.abs(lastCallTimeInMillis - currentTimeInMillis) / 1000;
    return `You have exceeded the limit of ${maxAllowedCalls} calls to ${apiName} in last ${durationWindowInSeconds} seconds. Please wait for  ${Math.floor(remainingTime)} seconds before making another request`
  };
  