
import { ToolLinkType } from "src/components/helpers/tool-links-renderer-util/tool-links-renderer-util.type"
import { afnToolLinksTemplates } from "src/pages/visibility-page/components/afn/afn.config"
import { mfnToolLinksTemplates } from "src/pages/visibility-page/components/mfn/mfn.config"
import { preSlamToolLinksTemplates } from "src/pages/visibility-page/components/preslam/preslam.config"

export const toolLinksTemplates: Record<string, ToolLinkType> = {
	...afnToolLinksTemplates,
	...mfnToolLinksTemplates,
	...preSlamToolLinksTemplates
}

export const realmToRegionMap: Record<string, string> = {
	'na': 'iad',
	'eu': 'dub',
	'cn': 'pek',
	'fe': 'pdx'
}

export const realmToFulfillmentMap: Record<string,string> = {
	'na': 'NAFulfillment',
	'eu': 'EUFulfillment',
	'cn': 'CNFulfillment',
	'fe': 'FEFulfillment',
}
