import { RadioButtonDataType } from "src/components/helpers/radio-button.util/radio-button.util.type"
import { InputFieldDataType } from "src/components/helpers/input-field-util/input-field-util.type"

export const inspectionBindleId : string = "amzn1.bindle.resource.gktzqsiksldsbdazathq"
export const reportBindleId : string = "amzn1.bindle.resource.5byexlt3k4yd36dkhjqa"

export const inspectionHeaderData = {
    pageTitle: "EagleEye Visibility - Inspections",
    key: "inspection-page-header"
}

export const activityReportHeaderData = {
    pageTitle: "EagleEye Visibility - Activity Report",
    key: "report-page-header"
}

export const searchIdData: InputFieldDataType = {
    label: "",
    type: "text",
    errorMessage: "Please enter Search Id for Search Type",
    placeholder: "Please enter Search Id for Search Type"
}

export const searchId: InputFieldDataType = {
    label: "",
    type: "text",
    errorMessage: "Please provide userId or location",
    placeholder: "Please enter user id or location(FC/SC)"
}



export const inspectionInputTypeData: RadioButtonDataType = {
    label: "",
    radioButtonValues: [
        {
            visibleText: "FC/SC",
            value: "location"
        },
        {
            visibleText: "User Id",
            value: "userId"
        },
        {
            visibleText: "Package Id",
            value: "packageId"
        }
    ]
}

export const activityReportInputTypeData: RadioButtonDataType = {
    label: "",
    radioButtonValues: [
        {
            visibleText: "User Id",
            value: "userId"
        },
        {
            visibleText: "FC/SC",
            value: "location"
        }
    ]
}
