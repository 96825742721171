import {NavTabType} from "src/components/helpers/navtab-util/navTab.util.type" ;

export const SolutionsNavTabItems: Array<NavTabType> = [
    {
        label: "XOR Redrive",
        path: "/xor-redrive",
        ref: "/xor-redrive"
    },
    {
        label: "Stuck Shipments",
        path: "/stuck-shipments",
        ref: "/stuck-shipments"
    }
];