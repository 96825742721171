import {InputFieldDataType} from "src/components/helpers/input-field-util/input-field-util.type";

export const mfnHeaderData = {
    pageTitle: "EagleEye Visibility - MFN",
    key: "mfn-page-header"
}

export const OrderIdData: InputFieldDataType = {
    label: "Order Id",
    type: "text",
    errorMessage: "Please enter Order Id",
    placeholder: "Enter Order Id"
}

