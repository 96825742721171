import React, { memo, useState, useEffect } from "react";
import { activityReportInputFieldsType, searchType } from "./activityreport.component.type";
import { PageHeaderUtility } from "src/components/helpers/headers.util/headers.util";
import { activityReportHeaderData } from '../../../../common/constants/inspections.const';
import { buttonClickMetricsPublisher } from "src/utils/metricUtils";
import { ActivityReportInputComponent } from './activityreport-input.component';
import { useSearchParams } from "react-router-dom";
import { userActivityApi } from "src/common/api/inspection.api";
import infoTokens from "@amzn/meridian-tokens/base/icon/info";
import "../inspections/inspections.component.scss"
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import downloadSmallIcon from "@amzn/meridian-tokens/base/icon/download-small";
import { TableRendererUtil, handleDownloadFileBtnClick } from "src/components/helpers/table-renderer-util/table-renderer-util";
import { activityReportResponseType } from "src/common/dao/activityreport/activityreport-dao.type";
import { ROWS_PER_PAGE } from "src/components/helpers/table-renderer-util/table-renderer.config";
import { useCommonDataContext } from "src/context/CommonDataContext"


const ActivityReportComponent = () => {
    const today = new Date()
    const previousweek = new Date(today)
    previousweek.setDate(today.getDate() - 6)
    const initialActivityReportState : activityReportInputFieldsType = {
        searchType : "userId",
        searchId : "",
        startDate : previousweek.toISOString().split('T')[0],
        endDate : today.toISOString().split('T')[0],
        isLoading : false,
        showResultsInfo : false,
        stateLoadedWithParams : false,
        numberOfPages : 1
    }
    const activityReportResultInitialState : activityReportResponseType = {
        activityReportData: [],
        activityReportTableHeaders: [],
        activityReportDataCount: 0,
        error: undefined
    }


    const [activityReportForm, setActivityReportForm] = useState<activityReportInputFieldsType>(initialActivityReportState);
    const [activityReportMissingFields, setActivityReportMissingFields] = useState<Record<string, string>>({});
    const [activityReportResults, setActivityReportResults] = useState<activityReportResponseType>(activityReportResultInitialState);
    const [isSheetOpen, setIsSheetOpen] = useState(false);
    const [searchParams,setSearchParams] = useSearchParams();
    const isUserAuthorized = useCommonDataContext();

   useEffect(() => {
    if( searchParams.get("searchType") === null) return
    setActivityReportForm(prevForm => ({ ...prevForm,
        searchType: searchParams.get("searchType") as searchType || "userId",
        searchId: searchParams.get("searchId") || "",
        startDate: searchParams.get("startDate") || today.toLocaleDateString(),
        endDate: searchParams.get("endDate") || today.toLocaleDateString(),
        stateLoadedWithParams: true,
     }))
   },[])

   useEffect(() => {
    if (activityReportForm.stateLoadedWithParams) {
        setActivityReportForm({ ...activityReportForm, isLoading: true })
        handleSubmit()
    }
    }, [activityReportForm.stateLoadedWithParams])

   const getActivityReportIncompleteFields = () : boolean => {
    let incompleteFields: Record<string,string> = {}
    let isHavingIncompleteFields: boolean = false;
    const USER_ID_PATTERN = /^[a-zA-Z]+$/
    const checkAndSetMissingFields = ( field: string, condition: boolean, errorMessage: string): void  => {
        if(condition)
        {
            incompleteFields[field] = errorMessage,
            setActivityReportMissingFields({...incompleteFields})
            isHavingIncompleteFields = true
        }
    }

    checkAndSetMissingFields("searchId", !activityReportForm.searchId && activityReportForm.searchType==="userId", "User Id cannot be null")
    checkAndSetMissingFields("searchId", !activityReportForm.searchId && activityReportForm.searchType==="location", "FC/SC(location) cannot be null")
    checkAndSetMissingFields("searchId", activityReportForm.searchType === "userId" && activityReportForm.searchId!== "" && (!USER_ID_PATTERN.test(activityReportForm.searchId)), "Invalid User Id format. It should contain only alphabetical characters (A-Z, a-z).")
    checkAndSetMissingFields("startDate", !activityReportForm.startDate, "Please select From date")
    checkAndSetMissingFields("endDate", !activityReportForm.endDate, "Please select To date")
    checkAndSetMissingFields("startDate",activityReportForm.startDate > activityReportForm.endDate,"From date shouldn't be greater than To date")
    setActivityReportMissingFields({...incompleteFields})
    return isHavingIncompleteFields
   }

   const getDownloadDataBtn=(tableResponseForExport:activityReportResponseType)=>{
    return <div style={{ marginBottom: "5px"}}>
        <Row alignmentHorizontal="end"  className="actions" >
            <Button  size="small" 
                onClick={() =>{
                    handleDownloadFileBtnClick(tableResponseForExport.activityReportTableHeaders,tableResponseForExport.activityReportData)
                }}>
                <Icon tokens={downloadSmallIcon}/>Export to CSV 
            </Button>
        </Row>
    </div>
}

   const handleSubmit = () => {
    if(getActivityReportIncompleteFields())
    {
        setActivityReportForm({ ...activityReportForm,isLoading:false})
        return
    }

    const startTime = new Date(activityReportForm.startDate)
    const fromTimeStamp = startTime.getTime()/1000
    const endTime = new Date(activityReportForm.endDate)
    const toTimeStamp = endTime.getTime()/1000 + 24*60*60

    buttonClickMetricsPublisher.publishCounterMonitor("UserActivity-search",1)
    if(isUserAuthorized.isUserAuthorizedForActivityReport){
        userActivityApi(activityReportForm.searchType,activityReportForm.searchId,fromTimeStamp,toTimeStamp).then((response:activityReportResponseType)=>{
        setActivityReportResults({
            activityReportData: response.activityReportData,
            activityReportTableHeaders: response.activityReportTableHeaders,
            activityReportDataCount: response.activityReportDataCount,
            error: response.error
        })
        setActivityReportForm({...activityReportForm,
            isLoading:false,
            showResultsInfo:true,
            numberOfPages:Math.ceil(response.activityReportDataCount / ROWS_PER_PAGE)})
        }).catch((err)=>{
            setActivityReportResults({
                ...activityReportResults,
                error: err
            })
            setActivityReportForm({...activityReportForm,isLoading:false,showResultsInfo:true})

        })
    }
    else{
         setActivityReportResults({
             ...activityReportResults,
             error: "Sorry! You are not authorised to view Activity-Report page. Please reach out to eagleeyeteam@amazon.com for more details."
         })
         setActivityReportForm({...activityReportForm,isLoading:false,showResultsInfo:true})
     }
    return
   }

    return (
        <React.Fragment>
            <PageHeaderUtility pageTitle={activityReportHeaderData.pageTitle} myKey={activityReportHeaderData.key} />
            <ActivityReportInputComponent
                activityReportForm = {activityReportForm}
                setActivityReportForm = {setActivityReportForm}
                activityReportMissingFields = {activityReportMissingFields}
                setActivityReportMissingFields = {setActivityReportMissingFields}
                isSheetOpen={isSheetOpen}
                setIsSheetOpen={setIsSheetOpen}
                handleSubmit = {handleSubmit}
            />

            {activityReportForm.showResultsInfo && (activityReportResults.error ? 
            <div className={"inspection-search-results"}>
                <Box type="outline" spacingInset={"300"}  backgroundColor="#f9fad2;">
                <Row alignmentHorizontal="center" spacing={"300"}>
                    <Icon tokens={infoTokens} />
                    <Text>{activityReportResults.error}</Text>
                </Row>
                </Box>
            </div> : 
            <div className="inspection-search-results" >
            {getDownloadDataBtn(activityReportResults)}
            <Row alignmentHorizontal="center">
            <Box type="outline"> 
                    <div className="table-render">
                        <TableRendererUtil
                            tableData={activityReportResults.activityReportData}
                            tableHeaders={activityReportResults.activityReportTableHeaders}
                            onFiltersClick={()=>{}}
                            showActionBarComponents={{
                                "showActionBar": false,
                                "showSearchBar": false,
                                "showFilters": false,
                                "showDownloadButton": false,
                                showColumnFilters:true
                            }}
                            numberOfPages={activityReportForm.numberOfPages}
                            isRowHeader={true}

                        ></TableRendererUtil>
                    </div>
            </Box>
            </Row>
            </div>
            )
            }
        </React.Fragment>
    )
   
}

export default memo(ActivityReportComponent)




