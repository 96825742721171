import React, {useState} from "react"
import Select, { SelectOption } from "@amzn/meridian/select"
import {DropDownUtilComponentProps, DependentDropDownUtilComponentProps} from "./dropdown-util.type"

export const DropDownUtilComponent = ({dropDownData, value, setValue, error, width}: DropDownUtilComponentProps) => {
    const [constraintText, setConstraintText] = useState<string>("");
    return(
        <Select
            label={dropDownData.label}
            value={value}
            data-cyid="select-comp"
            onChange={ selectedValue =>{
                setValue(selectedValue);
                // The below-mentioned logic is to update the Constraints Text based on selected option
                let selectedDropdownOptions = dropDownData.dropdownOptions.filter((option)=>{return option.value == selectedValue})
                setConstraintText(selectedDropdownOptions[0].constraintText ?? "")
            }}
            placeholder={dropDownData.placeholder}
            constraintText={constraintText}
            width={ width ?? "300"}
            size="small"
            error={error}
            errorMessage={ error ? dropDownData.errorMessage : undefined}
        >
            {dropDownData.dropdownOptions.map((dropDown) => {
                return <SelectOption key={dropDown.value+"-key"} value={dropDown.value} label={dropDown.visibleText} />
            })}
        </Select>
    )
}

export const DependentDropDownUtilComponent = ({dropDownData, value, setValue, parentDropdownValue, error, width}: DependentDropDownUtilComponentProps) => {
    const [constraintText, setConstraintText] = useState<string>("");
    return(
        <Select
            label={dropDownData.label}
            value={value}
            onChange={ selectedValue =>{
                setValue(selectedValue);
                // The below-mentioned logic is to update the Constraints Text based on selected option
                let selectedDropdownOptions = dropDownData.dropdownOptions[parentDropdownValue].filter((option)=>{return option.value == selectedValue})
                setConstraintText(selectedDropdownOptions[0].constraintText ?? "")
            }}
            placeholder={dropDownData.placeholder}
            constraintText={constraintText}
            width={width ?? "300"}
            size="small"
            error={error}
            errorMessage={error ? dropDownData.errorMessage : undefined}
        >
            {dropDownData.dropdownOptions[parentDropdownValue] && dropDownData.dropdownOptions[parentDropdownValue].map((dropDown) => {
                return <SelectOption key={dropDown.value+"-key"} value={dropDown.value} label={dropDown.visibleText} />
            })}
        </Select>
    )
}
