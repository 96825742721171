import React ,  { useRef, useState } from "react"
import { ButtonComponentProps } from "./button-util.type"
import Button from "@amzn/meridian/button";
import Theme from "@amzn/meridian/theme"
import Icon from "@amzn/meridian/icon";
import caretDownTokens from "@amzn/meridian-tokens/base/icon/caret-down"

export const ButtonUtilComponent = React.forwardRef(({customTokens, buttonProperties, handleClick, value, setValue}:ButtonComponentProps,ref) =>{
    return(
        <Theme tokens={customTokens}>
            <React.Fragment>
                <Button  
                    ref={ref} 
                    onClick={handleClick} 
                    size={buttonProperties.size}
                    type={buttonProperties.type}
                    className={buttonProperties.className}
                    disabled={buttonProperties.disabled}
                    minWidth={buttonProperties.minWidth}
                    id={buttonProperties.id}
                    
                    // if adding of any other properties is required, refer the API's of Button Component in meridian
                    >
                        {buttonProperties.label}{value} 
                </Button>
                {buttonProperties.Icon && <Icon tokens={caretDownTokens}> </Icon>}
            </React.Fragment>
        </Theme>
    )
})