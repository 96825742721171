import {axiosGet, axiosPost} from "src/common/axios/axios-api-wrapper.hook";
import preSlamSearchRequest from "src/common/dao/preslam/preslam-request.dao";
import preSlamSearchResponse from "src/common/dao/preslam/preslam-response.dao";
import { getEECSApiForRegion } from "src/utils/utils";
import { latencyMetricsPublisher } from "src/utils/metricUtils";

export const preslamVisibilityApi = async (shipmentId:string, FC: string) => {
    const preSlamSearchQueryParams = new preSlamSearchRequest(shipmentId,FC);
    const requestStartTime = Date.now()
    // getEECSApiForRegion()
    // "http://localhost:3000/",
    if(FC=="")
        FC="NA"
    return axiosGet(
        getEECSApiForRegion(),
        `preslam/${shipmentId}/${FC}`,
    ).then((response: any) => {
        const requestTime = Date.now() - requestStartTime
        latencyMetricsPublisher.publishTimerMonitor( `preslam`, requestTime)
        return new preSlamSearchResponse(response,FC);
    });
};