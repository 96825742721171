import {axiosGet, axiosPost} from "src/common/axios/axios-api-wrapper.hook";
import {getEECSApiForRegion} from "src/utils/utils";
import { latencyMetricsPublisher } from "src/utils/metricUtils";
import { katalLoggerInfo } from "src/logger";
import { stuckShipmentPayloadType } from "src/common/dao/stuck-shipments/stuck-shipments-dao.type";
import stuckShipmentRequest from "src/common/dao/stuck-shipments/stuck-shipments-request-dao";

export const stuckShipmentsSolutionsApi = async (payload: stuckShipmentPayloadType) => {
    const stuckShipmentstPayload = new stuckShipmentRequest(payload).request
    const requestStartTime = Date.now()
    katalLoggerInfo(window.location.href, "Stuck Shipments => Request", stuckShipmentstPayload)
    return axiosPost(
        getEECSApiForRegion(),
        `solutions`,
        stuckShipmentstPayload,
    ).then((response: any) => {
        const requestTime = Date.now() - requestStartTime
        katalLoggerInfo(window.location.href, "Stuck Shipments  => Response", response)
        latencyMetricsPublisher.publishTimerMonitor( 'solutions', requestTime)
        return response
    });
};
