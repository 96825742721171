import {axiosGet} from "src/common/axios/axios-api-wrapper.hook";
import mfnSearchRequest from "src/common/dao/mfn/mfn-request.dao";
import mfnSearchResponse from "src/common/dao/mfn/mfn-response.dao";
import {getEECSApiForRegion} from "src/utils/utils";
import { latencyMetricsPublisher } from "src/utils/metricUtils";
import { katalLoggerInfo } from "src/logger";

export const mfnVisibilityApi = async (orderId:string) => {
    const mfnSearchQueryParams = new mfnSearchRequest(orderId);
    const requestStartTime = Date.now()
    katalLoggerInfo(window.location.href, "MfnVisibilityApi => Request", mfnSearchQueryParams)
    return axiosGet(
        getEECSApiForRegion(),
        'mfn/' + mfnSearchQueryParams.orderId,
    ).then((response: any) => {
        const requestTime = Date.now() - requestStartTime
        katalLoggerInfo(window.location.href, "MfnVisibilityApi => Response", response)
        latencyMetricsPublisher.publishTimerMonitor( 'mfn', requestTime)
        return new mfnSearchResponse(response);
    });
};
// mfn.js -> express-app.js  ->  minDataLayer (f18) -> eecs.js