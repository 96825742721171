


import React, { useState, useEffect, memo } from "react"
import { useNavigate } from "react-router-dom"
import { NavTabComponentProps, tab } from "src/components/helpers/navtab-util/navTab.util.type";
import { VisibilityNavTabItems } from "src/common/constants/visiblity-navTab.const";
import {NavTabComponent} from "src/components/helpers/navtab-util/navTab.util"
import { IS_AFTX_REQUEST } from "src/utils/aftx-utils";

const VisibilityNavTabComponent = ({ tab }: tab) => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("");
  const filteredNavTabItems = IS_AFTX_REQUEST ? VisibilityNavTabItems.filter(item => item.label === 'Home') : VisibilityNavTabItems;
  useEffect(() => {
    setCurrentTab(tab);
  })

  return (
      <NavTabComponent tab={tab} NavTabItems={filteredNavTabItems}/>
  )
}
export default memo(VisibilityNavTabComponent);

