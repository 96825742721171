import { axiosGet, axiosPost } from "src/common/axios/axios-api-wrapper.hook";
import afnSearchRequest from "src/common/dao/afn/afn-request.dao";
import afnSearchResponse from "src/common/dao/afn/afn-response.dao";
import { ItemDetailsRequest } from "src/common/dao/afn/itemdetails-request.dao"
import { BulkItemDetailsResponse, ItemDetailsResponse } from "src/common/dao/afn/itemdetails-response.dao"
import { afnInputFieldsType, stackingFilterInputType } from "src/pages/visibility-page/components/afn/afn.component.type";
import { getEECSApiForRegion } from "src/utils/utils";
import { latencyMetricsPublisher } from "src/utils/metricUtils";
import { katalLoggerInfo } from "src/logger";
import { itemDetailsRequestType } from "../dao/afn/afn-dao.type";

export const afnVisibilityApi = async (afnForm: afnInputFieldsType) => {
    const afnSearchQueryParams = new afnSearchRequest(afnForm);
    const requestStartTime = Date.now()
    katalLoggerInfo(window.location.href, "AfnVisibilityApi => Request", afnSearchQueryParams)
    return axiosPost(
        getEECSApiForRegion(),
        afnForm.shipmentType,
        afnSearchQueryParams.request
    ).then((response: any) => {
        const requestTime = Date.now() - requestStartTime
        katalLoggerInfo(window.location.href, "AfnVisibilityApi => Response", response)
        latencyMetricsPublisher.publishTimerMonitor('afn', requestTime)
        return new afnSearchResponse(afnForm, response);
    });
};
export const doAfnBulkSearchRequestApiCall = async (bulkSearchRequest: any) => {
    return axiosPost(
        getEECSApiForRegion(),
        "handle-bulk-search-request",
        bulkSearchRequest
    ).then((response: any) => {
        return response;
    });
};

export const stackingFilterApi = async (sfForm: stackingFilterInputType) => {
    return axiosGet(
        getEECSApiForRegion(),
        `stackingFilter/${sfForm.nodeId}/${sfForm.stackingFilter}`,
    ).then((response: any) => {
        katalLoggerInfo(window.location.href, "stackingFilterApi => Response", response)
        return response;
    }).catch((error: any) => {
        return error
    });
};

export const manifestDetailsApi = async (shipmentId: String) => {
    return axiosGet(
        getEECSApiForRegion(),
        `manifestDetails/${shipmentId}`,
    ).then((response: any) => {
        katalLoggerInfo(window.location.href, "manifestDetailsApi => Response", response)
        return response;
    }).catch((error: any) => {
        return error
    });
};

//The main function which calls itemDetails lambda
export const callItemDetails = async (payload: itemDetailsRequestType) => {
    return axiosPost(
        getEECSApiForRegion(),
        `itemDetails`,
        payload
    ).then((apiResponse: any) => {
        return apiResponse
    }).catch((error: any) => {
        return error
    })

}

//Function to get item details of all packageIds. This will split the input array to size of 10 and calls lambda.
export const bulkItemDetailsApi = async (idsList: Record<string, string>) => {
    var bulkItemDetailsResult = {}
    var scannableIdsList = Object.keys(idsList)
    var failedRequestIds: Array<string> = []
    for ( let i=0; i<scannableIdsList.length ; i +=10 ) {
        const itemDetailsRequestParams = new ItemDetailsRequest(scannableIdsList.slice(i,i+10)).request
        try {
            const apiResponse = await callItemDetails(itemDetailsRequestParams)
            katalLoggerInfo(window.location.href, "bulkItemDetailsAPI => Response", apiResponse)
            bulkItemDetailsResult = { ...bulkItemDetailsResult, ...apiResponse }
        }
        catch(error) {
            katalLoggerInfo(window.location.href, "bulkItemDetailsAPI => error", error)
            failedRequestIds = [...failedRequestIds,...itemDetailsRequestParams.packageIds]
        }
    }
    var formattedItemDetailsResponse = new BulkItemDetailsResponse(bulkItemDetailsResult, idsList).response
    if(failedRequestIds.length)
    {
        formattedItemDetailsResponse = {...formattedItemDetailsResponse, failedIdsList: failedRequestIds}
    }
    return formattedItemDetailsResponse
    

}

//function to handle single getitem request
export const itemDetailsApi = async (scannableId: string) => {
    const itemDetailsRequestParams = new ItemDetailsRequest(scannableId).request
    try {
        const apiResponse = await callItemDetails(itemDetailsRequestParams)
        katalLoggerInfo(window.location.href, "itemDetailsAPI => Response", apiResponse)
        return new ItemDetailsResponse(apiResponse).response
    }
    catch (error) {
        katalLoggerInfo(window.location.href, "itemDetailsAPI => Error", error)
        return error
    }

}

export const userFeedbackSubmitApi = async (userFeedback: any) => {
    const requestStartTime = Date.now()
    katalLoggerInfo(window.location.href, "User Feedback submitted for going back to V1 => Response", userFeedback)
    return axiosPost(
        getEECSApiForRegion(),
        `create_sim`,
        userFeedback
    ).then((response: any) => {
        const requestTime = Date.now() - requestStartTime
        katalLoggerInfo(window.location.href, "User Feedback submitted for going back to V1 => Response", response)
        latencyMetricsPublisher.publishTimerMonitor('user-feedback', requestTime)
        return response;
    });
};
