// React Imports
import React, { useEffect, useState, useRef } from "react";
import { Tree } from 'react-d3-tree';
import Legend, { LegendProvider } from "@amzn/meridian/legend";
import Text from "@amzn/meridian/text";
import { stackingFilterApi } from "src/common/api/afn.api";
import { stackingFilterInputType } from "src/pages/visibility-page/components/afn/afn.component.type";
import "src/index.scss";
import Loader from "@amzn/meridian/loader";

const resourceMap =
  [{ key: "#37c2b4", label: "StackingFilter" },
  { key: "#eb64d7", label: "DOCK_DOOR" },
  { key: "#ff8f00", label: "STACKING_AREA" },
  { key: "#8169f8", label: "STAGING_AREA" },
  { key: "#fcc200", label: "Node" },
  { key: "#ff5a94", label: "Chute" },
  { key: "#70808f", label: "Others" }];

function createSfTree(node: string, sf: string, data: any[]): any[] {
  let tree: any[] = [];
  let colorMap = new Map(resourceMap.map(i => [i.label, i.key]));
  tree.push({
    name: node,
    attributes:
    {
      color: colorMap.get('Node'),
      type: 'Node',
      parent: ''
    },
    children: []
  });
  tree[0].children.push({
    name: sf,
    attributes:
    {
      color: colorMap.get('StackingFilter'),
      type: 'StackingFilter',
      parent: node,
    },
    children: [],
  });
  for (var i = 0; i < data.length; i++) {
    tree[0].children[0].children.push({
      name: data[i].label,
      attributes:
      {
        color: colorMap.get(data[i].type),
        type: data[i].type,
        parent: sf,
      },
      children: [],
    });
    if (data[i].chutes !== undefined)
      data[i].chutes.map(function (d: any) {
        tree[0].children[0].children[i].children.push({
          name: d.chuteName,
          attributes:
          {
            color: colorMap.get('Chute'),
            type: 'Chute',
            parent: data[i].label
          },
        });
      });
  }
  return tree;
}

const renderSvgNode = ({ nodeDatum, toggleNode }: any) => {
  return (
    <g>
      <circle stroke="none" fill={nodeDatum.attributes?.color} r="10" onClick={toggleNode} />
      <text strokeWidth="0" fontStyle="normal" fill="black" x="15" fontFamily='Amazon Ember' fontSize="12px">
        {nodeDatum.name}
      </text>
    </g>
  )
};

export const StackingFilterGraph = (props: any) => {
  const { stackingFilter, node } = props;
  const sfInput: stackingFilterInputType = {
    nodeId: node,
    stackingFilter: stackingFilter
  };

  useEffect(() => {
    sfApiCall();
  }, []);

  const [data, setData] = useState<any>(null);
  const sfApiCall = () => {
    return stackingFilterApi(sfInput).then((resp: any) => {
      const data = createSfTree(sfInput.nodeId, sfInput.stackingFilter, resp);
      setData(data);
    });
  };
  return (

    <div>
      {data == null && 
      <Text alignment="center">
        <Loader type="circular" size="large" />
        Loading... 
      </Text>}
      {data != null && (
        <div id="treeWrapper"
          style={{
            width: '100%',
            overflow: 'auto',
            position: 'relative',
            height: '400px'
          }}>
          <LegendProvider data={resourceMap}>
            <Legend direction="horizontal" />
          </LegendProvider>
          <Tree
            data={data}
            zoomable={true}
            translate={{ x: 100, y: 200 }}
            renderCustomNodeElement={renderSvgNode}
            separation={{ siblings: 0.3 }}
            enableLegacyTransitions={true}
            orientation='horizontal'
            collapsible={false}
            draggable={true}
            depthFactor={180}
          />
        </div>
      )}
    </div>
  );
};

